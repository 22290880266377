import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  selectPrefix,
  selectWidth,
  selectreportFromDate,
  selectreportToDate,
} from "../../utility/Redux/profile";
import { FromToDate } from "../../utility/component/fromToDate";
import { GetSaleSummary } from "./func";
import { selectToken } from "../../utility/Redux/security";
import { SaleSummaryColumns } from "./columns";
import Footer from "./footer";
import { useReactToPrint } from "react-to-print";
import GeniusTableReports from "../../utility/component/geniusComponents/geniusTableData";
import PrintWithFooter from "../../utility/component/datagrid/printReportWithFooter";
import { SaleSummaryPrintColumns } from "./printColumns";
import { SaleSummaryPrintTherminalColumns } from "./therminaColumns";
import IsLocalData from "../../utility/component/isLocalData";
import FromToDateMobile from "../../utility/component/fromToDateMobile";

const ExpiryReport = () => {
  const prefix = useSelector(selectPrefix);
  const [summaryList, setSummaryList] = useState([]);
  const fromDate = useSelector(selectreportFromDate);
  const ToDate = useSelector(selectreportToDate);
  const width = useSelector(selectWidth);
  const printRef = useRef(null);
  const therminalRef = useRef(null);
  const PrintColumns = SaleSummaryPrintColumns(width);
  const therminalColoums = SaleSummaryPrintTherminalColumns(width);
  const [getCustomerData, setCustomerData] = useState({
    table_prefix: prefix,
  });
  const columns = SaleSummaryColumns(width);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const okButton = () => {
    setSummaryList([]);
    GetSaleSummary(getCustomerData, token, dispatch, setSummaryList);
  };
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
  @page {
    size: landscape;
  }
`,
  });

  useEffect(() => {
    const callback = (details, data) => {
      setSummaryList(data);
    };
    const elseFunc = () => {
      console.log("nothing");
    };
    IsLocalData("nearExpiryReport", callback, elseFunc);
  }, []);

  return (
    <div className=" bg-white flex flex-col h-full  overflow-y-auto ">
      <div className=" flex flex-col h-full p-3 ">
        <div>
          {window.innerWidth <= 764 ? (
            <FromToDateMobile
              maxWidth={"500px"}
              hideDate={true}
              onPrintClick={handlePrint}
              submit={okButton}
            />
          ) : (
            <>
              <FromToDate
                onPrintClick={handlePrint}
                hideReset={true}
                hideDate={true}
                submit={okButton}
              />
            </>
          )}
        </div>

        {summaryList[0] ? (
          <div
            style={{
              height: `calc(100% - ${width <= 764 ? 220 : 170}px)`,
              width: "100%",
              marginTop: 10,
            }}
            className=" mb-2 "
          >
            <GeniusTableReports data={summaryList} columns={columns} />

            <div className="w-full mt-3 ">
              <div
                style={{
                  display: "none",
                }}
              >
                <div ref={printRef}>
                  <PrintWithFooter
                    customeHeader={
                      <div className="items-center flex justify-center w-full">
                        <div className="text-center ">Expiry Report</div>
                      </div>
                    }
                    data={summaryList}
                    columns={PrintColumns}
                    headerWidth={true}
                    marginTop={"120px"}
                  />
                </div>
              </div>
            </div>
            <div className="w-full mt-3 ">
              <div
                style={{
                  display: "none",
                }}
              >
                <div ref={therminalRef}>
                  <PrintWithFooter
                    customeHeader={
                      <div className="items-center ">
                        <div className="text-center ">Sale Summary</div>
                        <div className="flex items-center">
                          <div className="mr-1 text-sm font-semibold flex justify-center "></div>
                          <div className="text-sm font-semibold">From :</div>
                          <div className="ml-1 text-sm">
                            {moment(fromDate).format("DD-MM-YYYY")}{" "}
                          </div>
                          <div className="text-sm font-semibold ml-2">To :</div>
                          <div className="ml-1 text-sm">
                            {moment(ToDate).format("DD-MM-YYYY")}{" "}
                          </div>
                        </div>
                      </div>
                    }
                    data={summaryList}
                    columns={therminalColoums}
                    headerWidth={true}
                    marginTop={"120px"}
                    footer={
                      <Footer columns={therminalColoums} rows={summaryList} />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-full flex justify-center items-center text-sm text-gray-400 border mt-3 rounded-sm">
            No Records Found
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpiryReport;
