import { Button, Grow } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUserType } from "../utility/Redux/profile";

const Masters = () => {
  const navigation = useNavigate();
  const usertype = useSelector(selectUserType);
  const list = [
    {
      name: "Section Master",
      path: "",
      userType: "MANAGER,USER,ADMIN,STORE",
      onclick: () => {
        navigation("/section_master");
      },
    },
    {
      name: "Sub. Section Master",
      path: "",
      userType: "MANAGER,USER,ADMIN,STORE",
      onclick: () => {
        navigation("/sub_section_master");
      },
    },
    {
      name: "Department Master",
      path: "",
      userType: "MANAGER,USER,ADMIN,STORE",
      onclick: () => {
        navigation("/department_master");
      },
    },
    {
      name: "HSN Master",
      path: "",
      userType: "MANAGER,USER,ADMIN,STORE",
      onclick: () => {
        navigation("/hsn_master");
      },
    },
    {
      name: "Item Master",
      path: "",
      userType: "MANAGER,USER,ADMIN,STORE",
      onclick: () => {
        navigation("/item_master");
      },
    },
    {
      name: "Offer Master",
      path: "",
      userType: "MANAGER,USER,ADMIN,STORE",
      onclick: () => {
        navigation("/offer_master");
      },
    },
    {
      name: "Account Master",
      path: "",
      userType: "MANAGER,USER,ADMIN,STORE",
      onclick: () => {
        navigation("/account_master");
      },
    },

    {
      name: "Customer Master",
      path: "",
      userType: "MANAGER,USER,ADMIN,STORE",
      onclick: () => {
        navigation("/customer_master");
      },
    },

    {
      name: "Expense Master",
      path: "",
      userType: "MANAGER,ADMIN,STORE",
      onclick: () => {
        navigation("/expense_master");
      },
    },
    {
      name: "Employee Master",
      path: "",
      userType: "MANAGER,ADMIN,STORE",
      onclick: () => {
        navigation("/employee_master");
      },
    },
  ];

  const allowedList = list.filter((o) =>
    o.userType.toLowerCase().includes(usertype.toLowerCase())
  );

  return (
    <div className="h-full p-4 overflow-y-auto">
      <div className="grid grid-cols-1 sm:grid-cols-2 t:grid-cols-3 d:grid-cols-5 ll:grid-cols-6 gap-3  ">
        {allowedList[0] &&
          allowedList.map((e, i) => {
            return (
              <Grow
                key={i}
                in={true}
                style={{ transformOrigin: "0 0 0" }}
                {...(true ? { timeout: i === 0 ? 0 : i * 200 } : {})}
              >
                <Button
                  onClick={() => {
                    e.onclick();
                  }}
                  variant={"contained"}
                  style={{
                    textTransform: "none",
                    backgroundColor: "transparent",
                    padding: "0px",
                    borderRadius: "7px",
                    overflow: "hidden",
                  }}
                >
                  <div className="bg-[#e9e9e5] py-4 w-full text-brown">
                    {e.name}
                  </div>
                </Button>
              </Grow>
            );
          })}
      </div>
    </div>
  );
};

export default Masters;
