import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import GeniustextInput from "../../utility/component/mastertext";
import ModalHeaderStyle from "../../utility/component/styles/modalHeader";
import GeniusDatagrid from "../../utility/component/datagrid/geniusDatagrid";
import {
  ModifyButton,
  SaveButton,
  UndoButton,
} from "../../utility/component/buttons";
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import { useNavigate } from "react-router-dom";
import { selectPrefix, selectWidth } from "../../utility/Redux/profile";
import { selectToken } from "../../utility/Redux/security";
import { saveSUbSectionMasterApi } from "./func";
import { toProperCase } from "../../utility/component/format";
import { useFocus } from "../../utility/hooks/hooks";
import { SectionMasterHelp } from "../../help";
import { CallAPIPromise } from "../../billing/common";
const SubSecMaster = ({ onClose, afterCreate }) => {
  const dispatch = useDispatch();
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const [id, setId] = useState();
  const [sectionHelpStatus, setSectionHelpStatus] = useState(false);
  const [secName, setSecName] = useState();
  const [linkToSection, setLinkToSection] = useState();
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();
  const [subSecref, setSubSecFoc] = useFocus();
  const windowWidth = useSelector(selectWidth);

  const columns = [
    {
      field: "SUB_SEC_NAME",
      type: "string",
      renderHeader: (params) => <div>Sub. Section Name</div>,
      width: windowWidth > 768 ? 300 : 260,
      headerAlign: "center",
      align: "left",
      renderCell: (params) => {
        const runningCaseValue = params.value;
        return <div>{runningCaseValue}</div>;
      },
    },
  ];

  const listSectionMasterApi = () => {
    const data = {
      table_prefix: prefix,
      SUB_SEC_NAME: "",
      SECTION_NAME: "",
    };
    CallAPIPromise(`/api/master/listSubSectionMaster`, data, token, dispatch)
      .then(async function (response) {
        if (!response.data.error) {
          let list = [];
          const data = response.data.response;

          setRows(data);
        } else {
          dispatch(
            setAlertWithTitle({
              title: "Error 112",
              msg: response.data.error,
            })
          );
          console.log(!response.data.error);
        }
      })
      .catch(function (error) {
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        } else {
          dispatch(
            setAlertWithTitle({
              title: "ERROR",
              msg: "Something went wrong.",
              lottie: "reject",
            })
          );
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        setSecName("");
        setId("");
      });
  };

  useEffect(() => {
    listSectionMasterApi();
  }, []);
  useEffect(() => {
    function handleKeyPress(event) {
      // Check if Ctrl or Alt key is pressed along with 'S'
      if ((event.ctrlKey || event.altKey) && event.key === "s") {
        // Call your function here
        saveSUbSectionMasterApi(
          setSubSecFoc,
          secName,
          rows,
          dispatch,
          listSectionMasterApi,
          prefix,
          id,
          setId,
          token,
          setSecName,
          afterCreate,
          linkToSection
        );
      }
    }

    // Add event listener when component mounts
    window.addEventListener("keydown", handleKeyPress);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [secName]);
  const isPadding = window.location.pathname.includes("sub_section_master");

  return (
    <div
      style={{
        height: afterCreate ? window.innerHeight - 150 : "100%",
      }}
      className="w-full  flex justify-center bg-[#f5f5f7]  "
    >
      <div
        style={{
          padding: isPadding ? "16px" : "0px",
        }}
        className="text-sm  rounded-sm   flex flex-col  h-full "
      >
        <div>
          <ModalHeaderStyle
            header={"Sub Section Master"}
            onClose={() => {
              if (onClose) {
                onClose();
              } else {
                navigate("/masters");
              }
            }}
          />
        </div>
        <div
          style={{
            height: "calc(100% - 100px)",
          }}
          className=" bg-[#fdfffe] rounded-b-sm    border-brown p-3 max-sm:px-2"
        >
          <div id="input" className="grid grid-cols-1 ">
            <GeniustextInput
              title={"Sub. Section Name"}
              width={windowWidth > 764 ? "620px" : "320px"}
              type={"text"}
              value={secName}
              grid={true}
              max={100}
              autofocus={true}
              refrance={subSecref}
              onChange={(e) => {
                setSecName(toProperCase(e.target.value));
              }}
            />
            <GeniustextInput
              title={"Section Name"}
              width={windowWidth > 764 ? "620px" : "320px"}
              type={"text"}
              value={linkToSection}
              grid={true}
              max={100}
              search={() => {
                setSectionHelpStatus(true);
              }}
              autofocus={true}
              readOnly={true}
            />
          </div>

          <div
            style={{
              height: "calc(100% - 250px)",
              width:
                columns.reduce((acc, row) => acc + Number(row.width), 0) + 30,
            }}
            className="w-full  mt-1 flex"
          >
            <GeniusDatagrid
              rowID={(e) => e.CODE}
              onRowClick={(p) => {
                setId(p.row.CODE);
                setSecName(p.row.SUB_SEC_NAME);
                setLinkToSection(p.row.SECTION_NAME ? p.row.SECTION_NAME : "");
              }}
              columns={columns}
              list={rows}
            />
          </div>

          <div className="flex w-full justify-end items-center pt-3">
            {id ? (
              <ModifyButton
                onClick={() => {
                  saveSUbSectionMasterApi(
                    setSubSecFoc,
                    secName,
                    rows,
                    dispatch,
                    listSectionMasterApi,
                    prefix,
                    id,
                    setId,
                    setRows,
                    token,
                    setSecName,
                    afterCreate,
                    linkToSection
                  );
                }}
              />
            ) : (
              <SaveButton
                onClick={() => {
                  saveSUbSectionMasterApi(
                    setSubSecFoc,
                    secName,
                    rows,
                    dispatch,
                    listSectionMasterApi,
                    prefix,
                    id,
                    setId,
                    setRows,
                    token,
                    setSecName,
                    afterCreate,
                    linkToSection
                  );
                }}
              />
            )}

            <UndoButton
              onClick={() => {
                setSecName("");
                setId("");
              }}
            />
          </div>
        </div>
        <SectionMasterHelp
          onClose={() => {
            setSectionHelpStatus(false);
          }}
          status={sectionHelpStatus}
          onRowClick={(p) => {
            setLinkToSection(p.row.SECTION_NAME);
            setSectionHelpStatus(false);
          }}
        />
      </div>
    </div>
  );
};

export default SubSecMaster;
