import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import axios from "axios";
import DatePicker from "react-datepicker";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
} from "chart.js";
import { useState } from "react";
import { selectPrefix, selectWidth } from "../utility/Redux/profile";
import { selectToken } from "../utility/Redux/security";
import { useNavigate } from "react-router-dom";
import { setSessionExpired } from "../utility/Redux/modal";
import { FormatNumber2 } from "../utility/functions/formatNumber";
import AlertModalTwoButton from "../utility/component/modals/alertWithTwoButton";
import { parseISO } from "date-fns";
import { CallAPIPromise } from "../billing/common";
ChartJS.register(ArcElement, Tooltip, Legend, LinearScale);

const HomeDashboard = () => {
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const [todaySale, setTodaySale] = useState();
  const [sectionWiseSale, setsectionWiseSale] = useState([]);
  const [todayOffer, settodayOffer] = useState([]);
  const [paymodeSummary, setpaymodeSummary] = useState([]);
  const [datePickerFocus, setDatePickerFocus] = useState(false);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [bgList, setBgList] = useState([]);
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: "",
    msg: "",
    status: false,
    setStatus: "",
    button1: "",
    button1Click: "",
    button2: "",
    button2Click: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getTodayPaymentReciept = () => {
    CallAPIPromise(
      `/api/saleReports/payModeSummary`,
      {
        table_prefix: prefix,
        FDATE: moment(date).format("YYYY-MM-DD"),
        TDATE: moment(date).format("YYYY-MM-DD"),
      },
      token,
      dispatch
    )
      .then(function (response) {
        if (!response.data.error) {
          setpaymodeSummary(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        getSectionWiseSale();
      });
  };

  const getSectionWiseSale = () => {
    CallAPIPromise(
      `/api/saleReports/sectionWiseSale`,
      {
        table_prefix: prefix,
        FDATE: moment(date).format("YYYY-MM-DD"),
        TDATE: moment(date).format("YYYY-MM-DD"),
        SECTION_NAME: "",
        CUSTOMER_MOBILE: "",
      },
      token,
      dispatch
    )
      .then(function (response) {
        if (!response.data.error) {
          setsectionWiseSale(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        getTodayBooking();
      });
  };

  const getTodayBooking = (m) => {
    CallAPIPromise(
      `/api/offerMaster/listOffer`,
      {
        table_prefix: prefix,
        FDATE: moment(date).format("YYYY-MM-DD"),
        TDATE: moment(date).format("YYYY-MM-DD"),
        MOBILE: "",
      },
      token,
      dispatch
    )
      .then(function (response) {
        if (!response.data.error) {
          settodayOffer(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        settodayOffer([]);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      });
  };

  useEffect(() => {
    getTodayPaymentReciept();
  }, [date]);
  function generateRandomColors(count) {
    const colors = [];
    for (let i = 0; i < count; i++) {
      const color = `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
        Math.random() * 256
      )}, ${Math.floor(Math.random() * 256)}, 0.6)`;
      colors.push(color);
    }
    return colors;
  }

  useEffect(() => {
    if (sectionWiseSale[0]) {
      setBgList(generateRandomColors(sectionWiseSale.length));
    }
  }, [sectionWiseSale]);
  const data = {
    labels: sectionWiseSale.map((o) => o.SECTION_NAME),
    datasets: [
      {
        label: "Sale",
        data: sectionWiseSale.map((o) => o.GTOTAL),
        backgroundColor: bgList,
        hoverBackgroundColor: bgList,
      },
    ],
  };
  const width = useSelector(selectWidth);
  return (
    <div className="h-full w-full text-md  overflow-y-auto p-3  ">
      <div className="border p-1 w-fit rounded-sm flex items-center">
        <DatePicker
          onFocus={() => setDatePickerFocus(true)}
          onClickOutside={() => setDatePickerFocus(false)}
          onSelect={() => setDatePickerFocus(false)}
          open={datePickerFocus}
          readOnly={true}
          className=" bg-transparent text-sm w-36 outline-0  "
          dateFormat=" d MMMM , yyyy "
          selected={parseISO(date)}
          onChange={(date) => setDate(moment(date).format("YYYY-MM-DD"))}
        />
        <button
          onClick={() => {
            setDatePickerFocus(true);
          }}
        >
          <i className="bi bi-chevron-down flex items-center"></i>
        </button>
      </div>

      {todaySale ? (
        <div className="w-full">
          <div
            style={{
              border: "1px solid #b5b3b3",
              fontSize: "13px  ",
              fontWeight: "500",
            }}
            className="w-fit flex px-3 py-1  text-white   rounded-br-3xl bg-[#634258]"
          >
            Today Sale :{" "}
            <div
              style={{
                fontWeight: "400",
              }}
              className="pl-2"
            >
              {FormatNumber2(
                todaySale.CASH_AMOUNT +
                  todaySale.CARD_AMOUNT +
                  todaySale.EPAYMENT +
                  todaySale.ADVANCE_AMT
              )}
            </div>
          </div>
          <div className="grid max-sm:grid-cols-2 tab:grid-cols-3 t:grid-cols-4 d:grid-cols-6 gap-2 mt-2">
            {todaySale ? (
              <>
                {todaySale.CASH_AMOUNT !== 0 ? (
                  <Button
                    variant={"contained"}
                    style={{
                      textTransform: "none",

                      backgroundColor: "transparent",
                      padding: "0px",
                      marginBottom: "8px",
                      boxShadow: "none",
                    }}
                  >
                    <div
                      style={{
                        boxShadow: "1px 1px 1px gray",
                        backgroundColor: "#e8e2db",
                      }}
                      className="flex justify-between w-full p-[0px] h-full rounded-sm"
                    >
                      <div className="p-1 px-2 text-[#0d0c0c]">
                        <div className="text-start ">Cash</div>
                        <div className="text-[#615b5b]">
                          {todaySale.CASH_AMOUNT !== 0
                            ? FormatNumber2(todaySale.CASH_AMOUNT)
                            : " "}
                        </div>
                      </div>
                    </div>
                  </Button>
                ) : null}

                {todaySale.CARD_AMOUNT !== 0 ? (
                  <Button
                    variant={"contained"}
                    style={{
                      textTransform: "none",
                      backgroundColor: "transparent",
                      padding: "0px",
                      marginBottom: "8px",
                      boxShadow: "none",
                    }}
                  >
                    <div
                      style={{
                        boxShadow: "1px 1px 1px gray",
                        backgroundColor: "#e8e2db",
                      }}
                      className="flex justify-between h-full w-full p-[0px] rounded-sm"
                    >
                      <div className="p-1 px-2 text-[#0d0c0c]">
                        <div className="text-start ">Card</div>
                        <div className="text-[#615b5b]">
                          {" "}
                          {todaySale.CARD_AMOUNT !== 0
                            ? FormatNumber2(todaySale.CARD_AMOUNT)
                            : " "}
                        </div>
                      </div>
                    </div>
                  </Button>
                ) : null}

                {todaySale.EPAYMENT !== 0 ? (
                  <Button
                    variant={"contained"}
                    style={{
                      textTransform: "none",
                      backgroundColor: "transparent",
                      padding: "0px",
                      marginBottom: "8px",
                      boxShadow: "none",
                    }}
                  >
                    <div
                      style={{
                        boxShadow: "1px 1px 1px gray",
                        backgroundColor: "#e8e2db",
                      }}
                      className="flex justify-between w-full h-full p-[0px] rounded-sm"
                    >
                      <div className="p-1 px-2 text-[#0d0c0c]">
                        <div className="text-start ">ePayment</div>
                        <div className="text-[#615b5b] flex justify-start">
                          {todaySale.EPAYMENT !== 0
                            ? FormatNumber2(todaySale.EPAYMENT)
                            : " "}
                        </div>
                      </div>
                    </div>
                  </Button>
                ) : null}

                {todaySale.ADVANCE_AMT !== 0 ? (
                  <Button
                    variant={"contained"}
                    style={{
                      textTransform: "none",
                      backgroundColor: "transparent",
                      padding: "0px",
                      marginBottom: "8px",
                      boxShadow: "none",
                    }}
                  >
                    <div
                      style={{
                        boxShadow: "1px 1px 1px gray",
                        backgroundColor: "#e8e2db",
                      }}
                      className="flex justify-between w-full h-full p-[0px] rounded-sm"
                    >
                      <div className="p-1 px-2 text-[#0d0c0c]">
                        <div className="text-start ">Advance</div>
                        <div className="text-[#615b5b] flex justify-start">
                          {todaySale.ADVANCE_AMT !== 0
                            ? FormatNumber2(todaySale.ADVANCE_AMT)
                            : null}
                        </div>
                      </div>
                    </div>
                  </Button>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      ) : null}

      {/* Today Advance */}
      {paymodeSummary[0] ? (
        <div className="w-full mt-4">
          <div
            style={{
              border: "1px solid #b5b3b3",
              fontSize: "13px  ",
              fontWeight: "500",
            }}
            className="w-fit whitespace-nowrap flex  px-3 py-1 text-black   rounded-br-3xl bg-[#f5f5f5]"
          >
            Paymode Summary :{"  "}
            <div
              style={{
                fontWeight: "400",
              }}
              className="pl-2"
            >
              {/* {FormatNumber2(paymodeSummary[0].CREDIT)} */}
            </div>
          </div>
          <div className="grid max-sm:grid-cols-2 tab:grid-cols-3 t:grid-cols-4 d:grid-cols-6 gap-2 mt-2">
            {paymodeSummary[0] ? (
              <>
                {paymodeSummary[0].CASH_AMOUNT > 0 && (
                  <Button
                    variant={"contained"}
                    style={{
                      textTransform: "none",
                      backgroundColor: "transparent",
                      padding: "0px",
                      marginBottom: "8px",
                      boxShadow: "none",
                    }}
                  >
                    <div
                      style={{
                        boxShadow: "1px 1px 1px gray",
                        backgroundColor: "#e8e2db",
                      }}
                      className="flex justify-between w-full p-[0px] rounded-sm"
                    >
                      <div className="bg-[#e8e2db] pb-1  rounded-sm">
                        <div className="text-[16px] p-1 px-2 text-[#0d0c0c] text-start ">
                          Cash
                        </div>
                        <div className="px-2 text-[#615b5b]">
                          {FormatNumber2(paymodeSummary[0].CASH_AMOUNT)}
                        </div>
                      </div>
                    </div>
                  </Button>
                )}

                {paymodeSummary[0].CARD_AMOUNT > 0 && (
                  <Button
                    variant={"contained"}
                    style={{
                      textTransform: "none",
                      backgroundColor: "transparent",
                      padding: "0px",
                      marginBottom: "8px",
                      boxShadow: "none",
                    }}
                  >
                    <div
                      style={{
                        boxShadow: "1px 1px 1px gray",
                        backgroundColor: "#e8e2db",
                      }}
                      className="flex justify-between w-full p-[0px] rounded-sm"
                    >
                      <div className="bg-[#e8e2db] pb-1  rounded-sm">
                        <div className="text-[16px] p-1 px-2 text-[#0d0c0c] text-start ">
                          Card
                        </div>
                        <div className="px-2 text-[#615b5b]">
                          {FormatNumber2(paymodeSummary[0].CARD_AMOUNT)}
                        </div>
                      </div>
                    </div>
                  </Button>
                )}

                {paymodeSummary[0].BTC_AMOUNT > 0 && (
                  <Button
                    variant={"contained"}
                    style={{
                      textTransform: "none",
                      backgroundColor: "transparent",
                      padding: "0px",
                      marginBottom: "8px",
                      boxShadow: "none",
                    }}
                  >
                    <div
                      style={{
                        boxShadow: "1px 1px 1px gray",
                        backgroundColor: "#e8e2db",
                      }}
                      className="flex justify-between w-full p-[0px] rounded-sm"
                    >
                      <div className="bg-[#e8e2db] pb-1  rounded-sm">
                        <div className="text-[16px] p-1 px-2 text-[#0d0c0c] text-start ">
                          BTC
                        </div>
                        <div className="px-2 text-[#615b5b]">
                          {FormatNumber2(paymodeSummary[0].BTC_AMOUNT)}
                        </div>
                      </div>
                    </div>
                  </Button>
                )}

                {paymodeSummary[0].E_AMOUNT > 0 && (
                  <Button
                    variant={"contained"}
                    style={{
                      textTransform: "none",
                      backgroundColor: "transparent",
                      padding: "0px",
                      marginBottom: "8px",
                      boxShadow: "none",
                    }}
                  >
                    <div
                      style={{
                        boxShadow: "1px 1px 1px gray",
                        backgroundColor: "#e8e2db",
                      }}
                      className="flex justify-between w-full p-[0px] rounded-sm"
                    >
                      <div className="bg-[#e8e2db] pb-1  rounded-sm">
                        <div className="text-[16px] p-1 px-2 text-[#0d0c0c] text-start ">
                          E Amount
                        </div>
                        <div className="px-2 text-[#615b5b]">
                          {FormatNumber2(paymodeSummary[0].E_AMOUNT)}
                        </div>
                      </div>
                    </div>
                  </Button>
                )}
              </>
            ) : null}
          </div>
        </div>
      ) : null}

      {sectionWiseSale[0] ? (
        <div className="w-full mt-3">
          <div
            style={{
              border: "1px solid #b5b3b3",
              fontSize: "13px  ",
              fontWeight: "500",
            }}
            className="w-fit flex px-3 py-1  text-black   rounded-br-3xl bg-[#f5f5f5]"
          >
            Section Wise Sale{" "}
          </div>
          <div className="t:flex">
            <div className="grid h-fit max-sm:grid-cols-1 tab:grid-cols-1 t:grid-cols-1 d:grid-cols-2 gap-2 mt-2">
              {sectionWiseSale.map((w, index) => (
                <Button
                  key={index}
                  variant={"contained"}
                  style={{
                    textTransform: "none",

                    backgroundColor: "transparent",
                    padding: "0px",
                    marginBottom: "8px",
                    boxShadow: "none",
                  }}
                  onClick={() => {
                    navigate("/item_wise_sale_report", {
                      state: {
                        FDATE: date,
                        TDATE: date,
                        SECTION_NAME: w.SECTION_NAME,
                      },
                    });
                  }}
                >
                  <div
                    style={{
                      boxShadow: "1px 1px 1px gray",
                      backgroundColor: "#e8e2db",
                    }}
                    className=" justify-between w-full p-[0px] h-full rounded-sm"
                  >
                    <div className="p-1 px-2 text-[#0d0c0c]">
                      <div className="flex items-center">
                        <div className="text-start ">{w.SECTION_NAME}</div>

                        <div
                          style={{
                            backgroundColor: bgList[index],
                          }}
                          className="h-[10px] ml-2 w-[10px] rounded-lg"
                        ></div>
                      </div>

                      <div className="text-[#615b5b] flex">
                        Total :
                        <div className="flex ml-1 ">
                          {FormatNumber2(w.GTOTAL)}
                        </div>
                      </div>
                    </div>
                  </div>
                </Button>
              ))}
            </div>

            <div
              style={{
                height:
                  width < 500
                    ? "300px"
                    : sectionWiseSale.length < 5
                    ? `${100 * sectionWiseSale.length}PX`
                    : "400px",
                width:
                  width < 500
                    ? "300px"
                    : sectionWiseSale.length < 5
                    ? `${100 * sectionWiseSale.length}PX`
                    : "400px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Pie data={data} />
            </div>
          </div>
        </div>
      ) : null}

      {/* Today Booking */}
      {todayOffer[0] ? (
        <div className="w-full mt-3">
          <div
            style={{
              border: "1px solid #b5b3b3",
              fontSize: "13px  ",
              fontWeight: "500",
            }}
            className="w-fit px-3 py-1 text-black   rounded-br-3xl bg-[#f8c469]"
          >
            Today Offer
          </div>
          <div className="grid max-sm:grid-cols-2 tab:grid-cols-3 t:grid-cols-4 d:grid-cols-6 gap-2 mt-2">
            {todayOffer[0]
              ? todayOffer.map((e, index) => {
                  return (
                    <Button
                      key={index}
                      variant={"contained"}
                      style={{
                        textTransform: "none",
                        backgroundColor: "transparent",
                        padding: "0px",
                        marginBottom: "8px",
                        boxShadow: "none",
                      }}
                    >
                      <div
                        style={{
                          boxShadow: "1px 1px 1px gray",
                          backgroundColor: "#e8e2db",
                        }}
                        className=" justify-between w-full px-2 l text-sm pl-1 pb-2 pt-2 rounded-sm"
                      >
                        <div className=" w-full  flex  justify-between rounded-sm">
                          <div className="text-[#0d0c0c] text-start">
                            {e.ITEMNAME}
                          </div>
                        </div>
                        <div className="flex ">
                          <div className="text-red-500  text-start line-through ">
                            {FormatNumber2(e.MRP_RATE)}
                          </div>
                          <div className="text-[#0d0c0c] text-start ml-3 ">
                            {FormatNumber2(e.OFFER_SALE_PRICE)}
                          </div>
                        </div>

                        <div className="text-[14px] flex   text-black">
                          {e.FTIME} - {e.TTIME}
                        </div>
                      </div>
                    </Button>
                  );
                })
              : null}
          </div>
        </div>
      ) : null}

      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
    </div>
  );
};

export default HomeDashboard;
