import { Modal } from "@mui/material";
import React from "react";
import { ActionButtons, billSettelemntBilling } from "./common";
import { useDispatch, useSelector } from "react-redux";
import { selectPrefix, selectWidth } from "../utility/Redux/profile";
import { selectToken } from "../utility/Redux/security";
import { useNavigate } from "react-router-dom";
import PaymentForm from "./billingModalNew";
import "./PaymentForm.css";
const OnlyBillModal = ({
  status,
  roundOffAmt,
  setIsOriginalBill,
  Undo,
  emptyBillingDetails,
  emptyCustomer,
  onClose,
  billingDetails,
  latitude,
  longitude,
  SaleType,
  setViewBillStatus,
  setAlertTwoButtonModal,
  setBillNo,
  billCartList,
  setListAccountMasterHelp,
  remainingTotal,
  setCustomermasetrHelp,
  grandTotalWithTax,
  fromref,
  inputFields,
  setBillingDetails,
  setCustomerList,
  setCustomerDetails,
  customerList,
  TotalAmount,
  customerDetails,
  columns,
  setisTextActive,
  editBillFields,
  setBillCartList,
  profileSetting,
  setLastCustomerDiscountrate,
  totalDiscount,
  lastCustomerDiscountrate,
  isCrossOrder,
}) => {
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const width = useSelector(selectWidth);
  const ClearBilling = () => {
    setCustomerDetails(emptyCustomer);
    setBillingDetails(emptyBillingDetails);
  };
  const styleMobile = {
    width: "auto",
    maxWidth: "90%",
    boxShadow: 24,
  };
  const navigate = useNavigate();
  const hideHeader = true;
  const uiType = 1;
  return (
    <Modal open={status} className="flex justify-center items-center h-full">
      {uiType === 1 ? (
        <div style={styleMobile} className="flex items-center   h-full">
          <div className=" payment-container  rounded-sm overflow-auto ms:h-[90%]  md:h-auto ">
            <div className="w-full text-[21px] border-b px-3 flex justify-between bg-white items-center text-black font-semibold  py-[10px]   ">
              <div>Bill Settlement</div>
              <div>
                <button onClick={onClose}>
                  <i className="bi text-red-400 text-[20px] bi-x-square-fill"></i>
                </button>
              </div>
            </div>
            <div className="  bg-white">
              {billSettelemntBilling(
                fromref,
                columns,
                setisTextActive,
                customerDetails,
                TotalAmount,
                setCustomerDetails,
                customerList,
                prefix,
                token,
                setCustomerList,
                dispatch,
                setBillingDetails,
                inputFields,
                setCustomermasetrHelp,
                grandTotalWithTax,
                width,
                billingDetails,
                setListAccountMasterHelp,
                remainingTotal,
                hideHeader
              )}
            </div>
            <div className="flex justify-end p-2 bg-white">
              {ActionButtons(
                billCartList,
                billingDetails,
                grandTotalWithTax,
                dispatch,
                prefix,
                token,
                ClearBilling,
                setBillNo,
                setAlertTwoButtonModal,
                setViewBillStatus,
                inputFields,
                customerDetails,
                SaleType,
                onClose,
                longitude,
                latitude,
                Undo,
                setIsOriginalBill,
                roundOffAmt,
                editBillFields,
                setBillCartList,
                totalDiscount,
                lastCustomerDiscountrate,
                setBillingDetails,
                profileSetting,
                setLastCustomerDiscountrate,
                isCrossOrder,
                navigate,
                setCustomerDetails
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="payment-container">
          <div className="border-b px-[20px] py-[10px]">
            <div className="text-[21px] font-semibold">Bill Settlement</div>
          </div>
          <div className="p-[20px]">
            <PaymentForm />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default OnlyBillModal;
