import React from "react";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import ViewBill from "./viewBill";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "auto",
};

const styleMobile = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  border: "2px solid #000",
  boxShadow: 24,
};

export default function ViewBillModal({
  status,
  bill_performa,
  isOriginalBill,
  billNo,
  onBack,
  hideVoid,
  setSummaryList,
  hideOrderSlip,
}) {
  const dispatch = useDispatch();

  return (
    <div className="bg-white ">
      <Modal
        open={status}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={window.innerWidth < 770 ? styleMobile : style}
          className={"rounded-lg overflow-hidden "}
        >
          <div className="w-full flex flex-col bg-white h-full ">
            <div className=" flex flex-col grow  bg-white w-full">
              <div className="h-full w-full">
                <ViewBill
                  bill_performa={bill_performa}
                  isOriginalBill={isOriginalBill}
                  hideOrderSlip={hideOrderSlip}
                  billNo={billNo}
                  onBack={onBack}
                  hideVoid={hideVoid}
                  setSummaryList={setSummaryList}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
