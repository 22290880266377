import CallAPI from "../../utility/functions/getData";
import {
  setAlertWithTitle,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import deleteWholeDatabase from "../../utility/functions/deleteWholeDatabase";
import dataToLocal from "../../utility/component/dataToLocal";

export const GetItemWiseSaleReport = (
  data,
  token,
  dispatch,
  setSummaryList,
  setFullItemWiseReport
) => {
  dispatch(setSpinnerLoading("Loading..."));
  if (data.SECTION_NAME === "") {
    deleteWholeDatabase("itemWiseSale");
  }

  CallAPI(
    "/api/saleReports/itemWiseSale",
    data,
    token,
    (e) => {
      if (!e.data.error) {
        if (e.data.response[0]) {
          if (data.SECTION_NAME === "") {
            dataToLocal("itemWiseSale", e.data.response, [
              {
                ...data,
                ICODE: 1,
              },
            ]);
            setFullItemWiseReport(e.data.response);
          }
          setSummaryList(e.data.response);
        } else {
          setSummaryList([]);
          setFullItemWiseReport([]);

          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: "No Records Found",
            })
          );
        }
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Something Went Wrong.",
          })
        );
      }
    },
    dispatch,
    () => {
      dispatch(setSpinnerLoading(false));
    }
  );
};

export const GetSectionWiseSaleReport = (
  data,
  token,
  dispatch,
  setSummaryList
) => {
  dispatch(setSpinnerLoading("Loading..."));
  deleteWholeDatabase("sectionWiseSale");

  CallAPI(
    "/api/saleReports/sectionWiseSale",
    data,
    token,
    (e) => {
      if (!e.data.error) {
        if (e.data.response) {
          setSummaryList(e.data.response);
          if (e.data.response[0]) {
            dataToLocal("sectionWiseSale", e.data.response, [
              {
                ...data,
                ICODE: 1,
              },
            ]);
          }
        }
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Something Went Wrong.",
          })
        );
      }
    },
    dispatch,
    () => {
      dispatch(setSpinnerLoading(false));
    }
  );
};
