import { createSlice } from "@reduxjs/toolkit";

export const List = createSlice({
  name: "list",
  initialState: {
    itemList: [],
    sectionList: [],
  },
  reducers: {
    setItemList(state, actions) {
      state.itemList = actions.payload;
    },
    setSectionList(state, actions) {
      state.sectionList = actions.payload;
    },
  },
});

export default List.reducer;

export const { setItemList, setSectionList } = List.actions;

export const selectItemList = (state) => state.list.itemList;
export const selectSectionList = (state) => state.list.sectionList;
