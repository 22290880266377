import { QRCodeSVG } from "qrcode.react";
import "./qrCss.css";
import "../utility/component/datagrid/print.css";

export const QRCodeGenerator = ({
  qrValue,
  downloadQRCode,
  isModalOpen,
  closeModal,
  handlePrint,
  idRef,
}) => {
  return (
    <div>
      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className=" text-white  w-80 shadow-lg">
            <div
              ref={idRef}
              id="print-component-qr"
              className="flex flex-col text-white  bg-[#2c3e50]  w-80   items-center p-4"
            >
              {/* Description */}
              <div className=" px-4 ">
                <div className="text-[35px] font-semibold">Order Online</div>
                <div className="text-center text-[18px] pt-1 text-gray-200">
                  {"genius office"}
                </div>
              </div>
              {/* QR Code */}
              <div className="bg-white p-2 mt-4 rounded-md flex justify-center items-center">
                <div id="qr-code" className="flex items-center justify-center">
                  <div
                    style={{
                      width: 210,
                      height: 210,
                    }}
                    className="flex items-center justify-center"
                  >
                    <QRCodeSVG size={200} value={qrValue} />
                  </div>
                </div>
              </div>

              <div className="flex pt-4 text-[18px]  font-semibold ">
                <i className="bi bi-box-arrow-up-right mr-2 flex items-center text-[16px]"></i>
                RetailxCross.com
              </div>
            </div>
            {/* Close Button */}

            <div className="pt-4 bg-gray-100 p-4 rounded-b-lg">
              <button
                onClick={handlePrint}
                className="bg-blue-500   px-4 py-2 rounded-md  w-full"
              >
                Print
              </button>
              <button
                onClick={downloadQRCode}
                className=" bg-gray-800  mt-2 px-4 py-2 rounded-md  w-full"
              >
                Download
              </button>

              <button
                onClick={closeModal}
                className="bg-red-500 mt-2 px-4 py-2 rounded-md hover:bg-red-600 w-full"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
