import moment from "moment";
import React, { useState } from "react";
import GeniustextInput from "../../utility/component/mastertext";
import {
  ModifyButton,
  SaveButton,
  UndoButton,
} from "../../utility/component/buttons";
import { useSelector } from "react-redux";
import { selectWidth } from "../../utility/Redux/profile";
import { FormatNumber2 } from "../../utility/functions/formatNumber";

const ItemPrice = ({
  data,
  setdata,
  onSubmit,
  setHsnMasterHelpStatus,
  undo,
}) => {
  const [dateFocus, setDateFocus] = useState(false);
  const width = useSelector(selectWidth);
  const inputWidth = width < 500 ? "240px" : "308px";
  const doubleGrid = "grid  grid-cols-2 gap-2";

  return (
    <div className=" h-full flex flex-col max-w-[350px]">
      <div className="font-semibold text-[16px]">Item Price </div>
      <div
        style={{
          border: data?.ICODE ? "1px solid #ebaf81" : "1px solid #b5b3b3",
        }}
        className=" bg-[#f5f5f7]  rounded-sm p-2 grid grid-cols-1 gap-2"
      >
        <GeniustextInput
          id={"MRP_RATE"}
          grid={true}
          titleColor={"red"}
          width={`${Number(inputWidth.replace("px", "")) / 2 - 5}px`}
          title={"MRP"}
          type={"number"}
          value={data.MRP_RATE}
          onChange={(e) =>
            setdata((p) => {
              return {
                ...p,
                MRP_RATE: e.target.value,
              };
            })
          }
        />

        <div className={doubleGrid}>
          <GeniustextInput
            id={"PUR_PRICE"}
            width={inputWidth}
            title={"Purchase Price"}
            type={"number"}
            value={data.PUR_PRICE}
            grid={true}
            onChange={(e) =>
              setdata((p) => {
                return {
                  ...p,
                  PUR_PRICE: e.target.value,
                };
              })
            }
          />
          <GeniustextInput
            grid={true}
            id={"MARK_RATE"}
            width={`${Number(inputWidth.replace("px", "")) / 2 - 5}px`}
            title={"Mark %"}
            type={"number"}
            value={data.MARK_RATE}
            onChange={(e) => {
              const markupAmount =
                (Number(data.PUR_PRICE) * Number(e.target.value)) / 100;
              const price = Number(data.PUR_PRICE) + markupAmount;
              setdata((p) => {
                return {
                  ...p,
                  MARK_RATE: e.target.value,
                  SALE_PRICE: price,
                };
              });
            }}
          />
        </div>

        <div className={doubleGrid}>
          <GeniustextInput
            id={"SALE_PRICE"}
            grid={true}
            width={`${Number(inputWidth.replace("px", "")) / 2 - 5}px`}
            title={"Sale Price"}
            titleColor={"darkBlue"}
            type={"number"}
            onBlur={(e) => {
              if (!data.MARK_RATE || data.MARK_RATE < 1) {
                const MARK_RATE =
                  !data.MARK_RATE || data.MARK_RATE < 1
                    ? ((e.target.value - data.PUR_PRICE) / data.PUR_PRICE) * 100
                    : data.MARK_RATE;

                setdata((p) => {
                  return {
                    ...p,
                    SALE_PRICE: e.target.value,
                    MARK_RATE: FormatNumber2(MARK_RATE),
                  };
                });
              }
            }}
            value={data.SALE_PRICE}
            onChange={(e) => {
              setdata((p) => {
                return {
                  ...p,
                  SALE_PRICE: e.target.value,
                };
              });
            }}
          />

          <GeniustextInput
            grid={true}
            id={"DISQ_RATE"}
            width={`${Number(inputWidth.replace("px", "")) / 2 - 5}px`}
            title={"Discount %"}
            type={"number"}
            value={data.DISQ_RATE}
            onChange={(e) =>
              setdata((p) => {
                return {
                  ...p,
                  DISQ_RATE: e.target.value,
                };
              })
            }
          />
        </div>

        <GeniustextInput
          id={"WHOLE_SALE_PRICE"}
          width={inputWidth}
          title={"Whole Sale Price"}
          type={"number"}
          grid={true}
          value={data.WHOLE_SALE_PRICE}
          onChange={(e) =>
            setdata((p) => {
              return {
                ...p,
                WHOLE_SALE_PRICE: e.target.value,
              };
            })
          }
        />
      </div>
      <div className="">
        <div className="font-semibold text-[16px] mt-2">Tax Details</div>
        <div
          style={{
            border: data?.ICODE ? "1px solid #ebaf81" : "1px solid #b5b3b3",
          }}
          className=" bg-[#f5f5f7] rounded-sm p-2 grid grid-cols-1 gap-2"
        >
          <GeniustextInput
            id={"HSN_CODE"}
            grid={true}
            width={inputWidth}
            search={() => {
              setHsnMasterHelpStatus(true);
            }}
            title={"HSN Code"}
            type={"number"}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                if (!data.HSN_CODE) {
                  setHsnMasterHelpStatus(true);
                }
              }
            }}
            value={data.HSN_CODE}
            onChange={() => setHsnMasterHelpStatus(true)}
          />
          <GeniustextInput
            max={10}
            grid={true}
            id={"TAX1_RATE"}
            width={`${Number(inputWidth.replace("px", "")) / 2 - 5}px`}
            title={"CGST"}
            type={"number"}
            value={data.TAX1_RATE}
          />
          <GeniustextInput
            id={"TAX2_RATE"}
            max={10}
            grid={true}
            width={`${Number(inputWidth.replace("px", "")) / 2 - 5}px`}
            title={"SGST"}
            type={"number"}
            value={data.TAX2_RATE}
          />
          <div className={doubleGrid}>
            <GeniustextInput
              id={"TAX3_RATE"}
              max={10}
              grid={true}
              width={`${Number(inputWidth.replace("px", "")) / 2 - 5}px`}
              title={"Tax Head -3"}
              type={"number"}
              value={data.TAX3_RATE}
              onChange={(e) =>
                setdata((p) => {
                  return {
                    ...p,
                    TAX3_RATE: e.target.value,
                  };
                })
              }
            />
            <GeniustextInput
              id={"CESS_RATE"}
              width={`${Number(inputWidth.replace("px", "")) / 2 - 5}px`}
              title={"Cess %"}
              type={"number"}
              grid={true}
              value={data.CESS_RATE}
              onChange={(e) =>
                setdata((p) => {
                  return {
                    ...p,
                    CESS_RATE: e.target.value,
                  };
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="font-semibold text-[16px] mt-2">Stock Details</div>
      <div
        style={{
          border: data?.ICODE ? "1px solid #ebaf81" : "1px solid #b5b3b3",
        }}
        className=" rounded-sm bg-[#f5f5f7]  p-2 grid grid-cols-1"
      >
        <div className="grid grid-cols-1 p:grid-cols-2 gap-2">
          <GeniustextInput
            id={"OPBAL"}
            title={"Opening Bal."}
            type={"number"}
            value={data.OPBAL}
            grid={true}
            onChange={(e) =>
              setdata((p) => {
                return {
                  ...p,
                  OPBAL: e.target.value,
                };
              })
            }
          />
          <div className="">
            <GeniustextInput
              id={"OPBAL_DATE"}
              date={true}
              grid={true}
              fromDate={moment(data.OPBAL_DATE).format("YYYY-MM-DD")}
              setFromDate={(d) => {
                setdata((e) => {
                  return {
                    ...e,
                    OPBAL_DATE: moment(d).format("YYYY-MM-DD"),
                  };
                });
                setDateFocus(false);
              }}
              fromDateFocus={dateFocus}
              setFromDateFocus={setDateFocus}
              title={"Opening Bal. Date"}
              value={data.OPBAL_DATE}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 p:grid-cols-2 gap-2">
          <GeniustextInput
            id={"OPBAL_AMOUNT"}
            title={"Min. Stock"}
            type={"number"}
            grid={true}
            value={data.OPBAL_AMOUNT}
            onChange={(e) =>
              setdata((p) => {
                return {
                  ...p,
                  OPBAL_AMOUNT: e.target.value,
                };
              })
            }
          />
          <div className="">
            <GeniustextInput
              id={"PRINT_ORDER_SLIP"}
              title={"Max. Stock"}
              grid={true}
              type={"number"}
              value={data.PRINT_ORDER_SLIP}
              onChange={(e) =>
                setdata((p) => {
                  return {
                    ...p,
                    PRINT_ORDER_SLIP: e.target.value,
                  };
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col  justify-end">
        <div className="flex justify-end mt-2 ">
          {data.ICODE ? (
            <ModifyButton onClick={onSubmit} />
          ) : (
            <SaveButton onClick={onSubmit} />
          )}
          <UndoButton onClick={undo} />
        </div>
      </div>
    </div>
  );
};

export default ItemPrice;
