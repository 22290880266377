import { Box, Modal, Slide } from "@mui/material";
import { useSelector } from "react-redux";
import { selectWidth } from "../../utility/Redux/profile";

const FullWidthModal = ({
  status,
  handleClose,
  data,
  ItemDataUi,
  img,
  offerCart,
}) => {
  const width = useSelector(selectWidth);
  return (
    <div className="flex justify-center">
      <Modal
        open={status}
        onClose={handleClose}
        closeAfterTransition
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Slide direction="up" in={status} mountOnEnter unmountOnExit>
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              width: "100%",
              bgcolor: "background.paper",
              boxShadow: 24,
              height: "auto",
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              height: window.innerHeight - 80,
            }}
          >
            <div className="flex justify-between border-b border-gray-300 p-2">
              <div>Item Status</div>
              <button
                onClick={() => {
                  handleClose();
                }}
              >
                <i className="bi bi-x-square-fill text-red-400 text-[20px]"></i>
              </button>
            </div>
            {ItemDataUi && (
              <ItemDataUi
                img={img}
                summaryList={data}
                width={width}
                offerCart={offerCart}
              />
            )}
          </Box>
        </Slide>
      </Modal>
    </div>
  );
};

export default FullWidthModal;
