import { useEffect, useRef, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import CryptoJS from "crypto-js";
import AlertModal2 from "../../utility/component/modals/alertWithLottie";
import { useNavigate } from "react-router-dom";
import {
  selectEmailVerifyStatus,
  setALertStatus,
  setAlertMsg,
  setAlertWithTitle,
  setCheckEmailAlert,
  setEmailVerificationStatus,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import CallAPI from "../../utility/functions/getData";
import { useFocus } from "../../utility/hooks/hooks";
import {
  selectOtpModalStatus,
  selectRandomOtp,
  setOtpStatus,
} from "../../utility/Redux/security";
import SpinnerLoading from "../../utility/component/modals/spinnerLoading";
import moment from "moment";
import axios from "axios";
import styles from "../../utility/styles/LoginForm.module.css";
import GeniusFooterLogo from "../../utility/images/ico.png";
import { isTablet } from "react-device-detect";
import { selectLatitude, selectLongitude } from "../../utility/Redux/profile";
import ForgetModel from "./forget";
import CheckEmailModall from "../../utility/component/modals/resetPassword";
import CustomeModal from "../../utility/component/modals/customeModal";
import DraftsTwoToneIcon from "@mui/icons-material/DraftsTwoTone";
import clear from "../../utility/images/clear2.svg";
import HandleIndexedDB from "../../Test/handleIndexDb";
import deleteWholeDatabase from "../../utility/functions/deleteWholeDatabase";
import {
  isStateCheckEnabeld,
  licenceExpired_alert,
} from "../../utility/constant";
import { checkStateAllowedOrNot, runLoginFunc } from "./func";
import RetailxLogo from "../../utility/component/styles/homeLogo";
import OtpAlertModal from "../../utility/component/modals/otpAlert";
import { CallAPIPromise } from "../../billing/common";
const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [emailRef, setEmailFocus] = useFocus();
  const [passwordRef, setPasswordFocus] = useFocus();
  const [forget, setForget] = useState(false);
  const [location, setLocation] = useState(null);
  const otpStatus = useSelector(selectOtpModalStatus);
  const randomOTP = useSelector(selectRandomOtp);
  const [userInfo, setUserInfo] = useState("");
  const isEmailVerifyModelOpn = useSelector(selectEmailVerifyStatus);

  const move = useNavigate();

  const dispatch = useDispatch();
  const secretPass = process.env.REACT_APP_SECRET_KEY;

  const storeRememberData = async (email, passwrd) => {
    const e = CryptoJS.AES.encrypt(
      JSON.stringify(email),
      secretPass
    ).toString();
    const p = CryptoJS.AES.encrypt(
      JSON.stringify(passwrd),
      secretPass
    ).toString();

    localStorage.setItem(
      "rememberd",
      JSON.stringify({
        email: e,
        password: p,
      })
    );
  };

  const forgetPassword = (emailCheck) => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(username, secretPass);
    // Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRegex = /^[6-9]\d{9}$/;
    if (re.test(emailCheck) || phoneRegex.test(emailCheck)) {
      dispatch(setSpinnerLoading("Loading"));
      CallAPIPromise(
        "/api/users/findUserDetail",
        {
          user_email: !phoneRegex.test(emailCheck) ? emailCheck : "",
          user_mobile: phoneRegex.test(emailCheck) ? emailCheck : "",
        },
        hmacString,
        dispatch
      )
        .then((res) => {
          if (res.data[0]) {
            CallAPIPromise(
              `/api/users/resetUserPasswordEmail`,
              {
                user_email: !phoneRegex.test(emailCheck) ? emailCheck : "",
                user_name: res.data[0].user_name,
                user_mobile: phoneRegex.test(emailCheck) ? emailCheck : "",
              },
              hmacString,
              dispatch
            )
              .then((r) => {
                dispatch(setCheckEmailAlert(true));
                // move('/auth/checkEmail')
              })
              .catch((err) => {
                dispatch(setAlertMsg(err.code + " " + err.message));
                dispatch(setALertStatus(true));
              })
              .finally(() => {
                dispatch(setSpinnerLoading(false));
              });
          } else {
            dispatch(setAlertMsg("Not registered user"));
            dispatch(setALertStatus(true));
          }
        })
        .catch((e) => {
          dispatch(setAlertMsg("Something wrong"));
          dispatch(setALertStatus(true));
        });
    } else {
      dispatch(setAlertMsg("Invailed email address"));
      dispatch(setALertStatus(true));
    }
  };
  localStorage.removeItem("financial");
  localStorage.removeItem("financial_company");
  localStorage.removeItem("masterPrefix");
  localStorage.removeItem("user");
  useEffect(() => {
    const isRemmberd = JSON.parse(localStorage.getItem("rememberd"));

    if (isRemmberd) {
      const bytes = CryptoJS.AES.decrypt(isRemmberd.email, secretPass);
      const bytes2 = CryptoJS.AES.decrypt(isRemmberd.password, secretPass);

      if (bytes.sigBytes < 0 || bytes2.sigBytes < 0) {
        localStorage.removeItem("rememberd");
      } else {
        const decEmail = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        const decPass = JSON.parse(bytes2.toString(CryptoJS.enc.Utf8));

        setUsername(decEmail);
        setPassword(decPass);
        setRememberMe(true);
      }
    }
    deleteWholeDatabase("pathHistory");
    localStorage.removeItem("userDefaultSetting");
  }, []);

  const forgetModel = (e) => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRegex = /^[6-9]\d{9}$/;
    if (re.test(e)) {
      setForget(true);
    } else {
      dispatch(
        setAlertMsg("Enter valid user email address for change password")
      );
      dispatch(setALertStatus(true));
    }
  };

  const longitude = useSelector(selectLongitude);
  const latitude = useSelector(selectLatitude);
  const loginApi = (data, hmac) => {
    const d = {
      code: data.code,
      user_email: username,
      user_mobile: "",
      user_password: password,
      latidude: latitude ? latitude : "",
      longitude: longitude ? longitude : "",
      ip_address: "",
    };
    if (
      moment(data.end_date).format("YYYY-MM-DD") >=
      moment().format("YYYY-MM-DD")
    ) {
      dispatch(setSpinnerLoading("Authenticating user"));
      CallAPIPromise("/api/users/login/", d, hmac, dispatch)
        .then((e) => {
          if (e.data.token) {
            if (isStateCheckEnabeld) {
              checkStateAllowedOrNot(
                fetchAllowedState,
                data,
                dispatch,
                e,
                rememberMe,
                storeRememberData,
                username,
                password,
                move
              );
            } else {
              runLoginFunc(
                dispatch,
                e,
                data,
                rememberMe,
                storeRememberData,
                username,
                password,
                move
              );
            }
          } else {
            dispatch(
              setAlertWithTitle({
                title: "Alert",
                msg: "Something went wrong",
              })
            );
          }
        })
        .catch((e) => {
          console.log(e);
          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: e.response.data.error,
            })
          );
        });
    } else {
      dispatch(
        setAlertWithTitle({
          title: "Alert",
          msg: licenceExpired_alert,
        })
      );
    }
  };

  const handleLogin = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(username)) {
      if (username && password) {
        const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
        // const hmac = CryptoJS.HmacSHA256(message, secret);
        const hmac = CryptoJS.HmacSHA256(username, secretPass);
        // Convert the HMAC to a string and encode it using Base64
        const hmacString = hmac.toString(CryptoJS.enc.Base64);
        const data = {
          user_email: username,
          user_mobile: "",
        };
        dispatch(setSpinnerLoading("Getting User Detail"));
        CallAPI(
          "/api/users/userInfo",
          data,
          hmacString,
          (e) => {
            if (e.data.response[0]) {
              const data = e.data.response[0];
              setUserInfo(data);
              if (data.email_verified === "Y") {
                if (data.valid_user === 0) {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: "We have received your account activation request. Please wait for account activation.",
                    })
                  );
                } else {
                  loginApi(e.data.response[0], hmacString);
                }
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: "Alert",
                    msg: "We have sent a verification email to your company email. Please verify it to log in.",
                  })
                );
              }
            } else {
              dispatch(
                setAlertWithTitle({
                  title: "Alert",
                  msg: "User Not Found !",
                })
              );
            }
          },
          dispatch
        );
      } else {
        setPasswordFocus();
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Please Enter Password.",
          })
        );
      }
    } else {
      setEmailFocus();
      dispatch(
        setAlertWithTitle({
          title: "Alert",
          msg: "Please enter valid email",
        })
      );
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const formRef = useRef(null);
  // useEffect(() => {
  //   if (indexedDB.databases) {
  //     indexedDB
  //       .databases()
  //       .then((databases) => {
  //         databases.forEach((dbInfo) => {
  //           if (dbInfo.name !== 'SaveIssueCart') {
  //             if (dbInfo.name !== 'Calculations') {
  //               indexedDB.deleteDatabase(dbInfo.name);

  //             }
  //           }
  //         });
  //       })
  //       .catch((error) => {
  //         console.error('Error listing databases:', error);
  //       });
  //   } else {
  //     // Fallback method for browsers that do not support indexedDB.databases()
  //     console.log('indexedDB.databases() is not supported in this browser.');
  //   }
  // }, []);
  const fetchLocation = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(
          "https://ipinfo.io?token=1defe893bbea80"
        );

        const location = response.data;

        resolve(location); // Resolve if the region is Punjab
      } catch (error) {
        reject();
      }
    });
  };

  const fetchAllowedState = (email) => {
    return new Promise(async (resolve, reject) => {
      const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
      const hmac = CryptoJS.HmacSHA256(email, secretPass);
      // Convert the HMAC to a string and encode it using Base64
      const hmacString = hmac.toString(CryptoJS.enc.Base64);
      try {
        CallAPI(
          "/api/users/listAllowedStates",
          {
            user_email: email,
          },
          hmacString,
          (e) => {
            resolve(e.data.response);
          },
          dispatch
        );
      } catch (error) {
        resolve([]);
      }
    });
  };

  return (
    <div className=" h-screen px-2">
      <div
        className="flex justify-center max-w-[411px]   sm:py-1 px-1"
        style={{
          height: isTablet ? `calc(100% - 90px)` : "100%",
          alignItems: isTablet ? "start" : "center",
          paddingTop: isTablet ? "20px" : "0px",
        }}
        ref={formRef}
      >
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "20px",
            boxShadow: "2px 3px 6px #a8aaad",
          }}
        >
          <div className={styles.loginForm}>
            <RetailxLogo black={true} size={50} />

            <div className="text-[#554f4f] font-segoe flex justify-center mt-2 font-[500] text-[20px]">
              Login
            </div>

            <div className={`{styles.formGroup} mt-3`}>
              <label className={styles.label} htmlFor="username ">
                User Email / Mobile
              </label>
              <input
                ref={emailRef}
                className={styles.input}
                style={{
                  width: "100%",
                  outline: "none",
                  background: "transparent",
                  paddingRight: "40px",
                }}
                onKeyDown={(e) => {
                  if (e.code === "Enter" || e.code === "NumpadEnter") {
                    setPasswordFocus();
                  }
                }}
                type="email"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>

            <div className={styles.formGroup}>
              <div className="mt-3">
                <label className={styles.label} htmlFor="password">
                  Password
                </label>
                <div
                  className="flex"
                  style={{ position: "relative", width: "100%" }}
                >
                  <input
                    className={styles.input}
                    ref={passwordRef}
                    onKeyDown={(e) => {
                      if (e.code === "Enter" || e.code === "NumpadEnter") {
                        handleLogin();
                      }
                    }}
                    style={{
                      width: "100%",
                      outline: "none",
                      background: "transparent",
                      paddingRight: "40px", // Adjust this value to accommodate the button width
                    }}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                      border: "none",
                      background: "none",
                    }}
                    type="button"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <VisibilityOff
                        style={{
                          fontSize: "20px",
                          color: "#383535",
                        }}
                      />
                    ) : (
                      <Visibility
                        style={{
                          fontSize: "20px",
                          color: "#383535",
                        }}
                      />
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div className="flex w-full justify-between items-center mb-3">
              <label className="flex ml-2">
                <input
                  type="checkbox"
                  color="orange"
                  className="mr-1 "
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                <div style={{ color: "black" }} className="text-sm">
                  Remember Me
                </div>
              </label>
              <div className="mr-2">
                <button
                  onClick={() => {
                    if (username) {
                      forgetModel(username);
                    } else {
                      dispatch(
                        setAlertMsg(
                          "Please enter user email address for change password"
                        )
                      );
                      dispatch(setALertStatus(true));
                    }
                  }}
                >
                  <a
                    style={{
                      textDecoration: "none",
                    }}
                    className="text-orange text-[14px]"
                  >
                    Forgot Password?
                  </a>
                </button>
              </div>
            </div>

            <div className="mt-2">
              <button className={styles.button} onClick={handleLogin}>
                Login
              </button>
            </div>

            <div className="flex justify-center ml-2">
              <span className="flex mt-3 text-black text-[13px]">
                {`Don't have an account?`}
                <a
                  style={{
                    textDecoration: "none",
                  }}
                  href={"/auth/mobile_signup"}
                >
                  <div className="text-orange ml-1">Sign up</div>
                </a>
              </span>
            </div>
          </div>

          <div
            className="bg-[#F1F5F9] p-4"
            style={{
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <div className=" text-[11.5px] whitespace-nowrap sm:text-[15px] items-center w-full justify-center flex font-ubuntu">
              A product of
              <div className="flex justify-center items-center px-1 mt-1">
                <img alt={""} src={GeniusFooterLogo} width={18} />
              </div>
              <label className="font-semibold pr-1">genius office</label>
              2023. All rights reserved.
            </div>
          </div>
        </div>
      </div>
      <AlertModal2 />
      <ForgetModel
        forget={forget}
        setForget={setForget}
        forgetPassword={forgetPassword}
        email={username}
      />
      <CustomeModal
        status={isEmailVerifyModelOpn}
        data={
          <div className="bg-white t:max-w-[450px] max-w-[320px]">
            <div className="flex justify-end p-3 pb-0 items-center">
              <button
                onClick={() => dispatch(setEmailVerificationStatus(false))}
              ></button>
              <img
                alt=""
                src={clear}
                style={{
                  width: "15px",
                }}
              />
            </div>
            <div className="flex justify-center">
              <DraftsTwoToneIcon color={"warning"} fontSize={"large"} />
            </div>
            <div className="text-center font-semibold mt-3">
              Welcome To Retailx
            </div>
            <div className="text-center mt-3 px-4 ">
              let's get Started, We've just sent you a quick verification email
              to your inbox. verify if for continue.
            </div>
            <div className=" flex justify-center p-4">
              <div>
                <button
                  onClick={() => dispatch(setEmailVerificationStatus(false))}
                  className="bg-orange text-white p-2 text-sm px-5 rounded-sm "
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        }
      />
      <CheckEmailModall />
      <SpinnerLoading />
      <OtpAlertModal
        status={otpStatus}
        otp={randomOTP}
        onCancel={() => {
          dispatch(setOtpStatus(false));
        }}
        onSubmit={() => {
          if (userInfo) {
            const databaseName = "userDetails";
            const storeName = "data";
            deleteWholeDatabase(databaseName);

            const dataToSave = [
              {
                start_date: userInfo.start_date,
                end_date: userInfo.end_date,
                currency_name: userInfo.currency_name,
                currency_symbol: userInfo.currency_symbol,
                user_email: userInfo.user_email,
                ICODE: 1,
                lastLoginDate: moment().format("YYYY-MM-DD"),
              },
            ];

            HandleIndexedDB({
              databaseName,
              storeName,
              dataToSave,
            });
          }
          move("/auth/financial_year", {
            state: {
              CompanyEmail: userInfo?.company_email,
              UserEmail: username,
              companyName: userInfo?.company_name,
            },
          });
        }}
      />
    </div>
  );
};

export default LoginForm;
