import React from "react";
import Modal from "@mui/material/Modal";
import logo from "../../images/icon.svg";

export default function ActionRemarks({
  remarksStatus,
  setRemarksStatus,
  placeHolder,
  onSave,
  remarks,
  setRemarks,
  title,
  onFocus,
  onBlur,
  buttonName,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    height: "auto",
    // border: '1px solid white',
    boxShadow: 24,
    borderRadius: "7px",
    outline: "none",
  };

  return (
    <div>
      <Modal open={remarksStatus}>
        <div style={style} className=" w-full min-w-[280px] max-w-[320px]">
          <div className={"h-full w-full bg-white  rounded-sm  p-4"}>
            <div className=" flex justify-center w-full rounded-sm">
              <div
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: -30,

                  borderRadius: 100,
                }}
                className="bg-white p-[2px]"
              >
                <img
                  src={logo}
                  alt=""
                  style={{
                    width: "50px",
                    height: "auto",
                  }}
                />
              </div>
            </div>

            <div className="w-full justify-center text-center text-[#1c1a1a] flex mt-2 font-semibold items-top">
              {title}
            </div>
            <div
              style={{
                fontSize: "13px",
              }}
              className="w-full justify-center border border-[#b5b3b3] rounded-sm overflow-hidden text-center text-[#1c1a1a] flex mt-2 "
            >
              <textarea
                value={remarks}
                autoFocus
                rows={4}
                maxLength={100}
                onFocus={onFocus ? onFocus : () => {}}
                onBlur={onBlur ? onBlur : () => {}}
                placeholder={placeHolder}
                className="outline-none w-full p-2"
                onChange={(e) => setRemarks(e.target.value)}
              />
            </div>

            <div className="w-full flex justify-end mt-3">
              <div className="w-auto mr-1">
                {" "}
                <button
                  size={"small"}
                  onClick={() => {
                    setRemarksStatus(false);
                  }}
                  className="rounded-sm py-2 text-sm"
                  style={{
                    backgroundColor: "#737371",
                    color: "white",
                  }}
                >
                  <div className="px-6">{"Cancel"}</div>{" "}
                </button>
              </div>
              <button
                size={"small"}
                onClick={onSave}
                className="rounded-sm "
                style={{
                  backgroundColor: buttonName ? "green" : "#e97a7a",
                  color: "white",
                }}
              >
                <div className="px-6 py-2 text-sm">
                  {buttonName ? buttonName : "Void"}
                </div>{" "}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
