import React, { useEffect, useRef, useState } from "react";
import { Modal } from "@mui/material";
import { useDropzone } from "react-dropzone";
import uniqid from "uniqid";
import axios from "axios";
import { UploadFile } from "../../utility/functions/firebase";
import { useSelector } from "react-redux";
import { selectPrefix } from "../../utility/Redux/profile";

const modalOverlayClasses =
  "fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50";
const modalContentClasses = "bg-white rounded-lg p-8 w-full max-w-[500px]  ";
const imageUploadClasses =
  "border-2 border-dashed flex flex-col justify-center items-center border-zinc-400 p-8 mb-4 w-[400px] h-[200px]";
const uploadButtonClasses = "bg-blue-500 text-white px-4 py-2 rounded-lg mr-2";
const cancelButtonClasses = "bg-zinc-300 text-zinc-700 px-4 py-2 rounded-lg";

const UploadImageModal = ({
  setUploadImghStatus,
  setRefreshItems,
  setItemImgList,
  selectedItem,
  setFirebaseUrl,
  onCancel,
  uploadImgStatus,
  setSelectedFile,
  selectedFile,
  tabScreen,
}) => {
  const [imgList, setimgList] = useState([]);
  const canvasRef = useRef(null);
  const [imgType, setImgType] = useState("");
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      // Handle the dropped files
      const editedFiles = acceptedFiles.map((file) => {
        const editedName = ` ${uniqid()}-${file.name}`;
        return new File([file], editedName, { type: file.type });
      });
      setSelectedFile(URL.createObjectURL(editedFiles[0]));
      //   handleRemoveBg(URL.createObjectURL(editedFiles[0]),canvasRef);
    },
  });
  const fetchUnsplashImage = async () => {
    const apiKey = "hbzfRLjHN8Ox_DhjIJMDO1Rike3pZGqXFyWWgdn_8mA";
    const endpoint = "https://api.unsplash.com/search/photos";
    const params = {
      query: selectedItem.ITEMNAME,
      client_id: apiKey,
      per_page: 3,
    };

    try {
      const response = await axios.get(endpoint, { params });
      if (response.status === 200) {
        setimgList(response.data.results);
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      console.error(error.message);
      return null;
    }
  };

  useEffect(() => {
    handlePexelesSearch();
  }, [selectedItem]);
  useEffect(() => {
    setImgType(selectedItem.ITEMNAME);
  }, [uploadImgStatus]);
  const prefix = useSelector(selectPrefix);
  const handlePexelesSearch = async () => {
    const PEXELS_API_URL = "https://api.pexels.com/v1/search";
    const PEXELS_API_KEY =
      "sIQFj6AGEmTRP1yXJQqQo4dotB5WNAWaPBCnF5ApWgkCssU4wg4dh9jN"; // Replace with your Pexels API key

    try {
      const response = await axios.get(PEXELS_API_URL, {
        params: {
          query: imgType ? imgType : selectedItem.ITEMNAME,
        },
        headers: {
          Authorization: PEXELS_API_KEY,
        },
      });
      if (response.status === 200) {
        setimgList(response.data.photos);
      }
    } catch (err) {
      console.log("Error fetching images. Please try again.");
    }
  };

  const handleGoogleSearch = async () => {
    const apiKey = "AIzaSyB_UErDoGQDXK9kO6vcj6-SA4mM5u8laKA";
    const cx = "c54f2c27b7bcf4c36";
    const searchUrl = "https://www.googleapis.com/customsearch/v1";
    const params = {
      q: selectedItem.ITEMNAME + "png",
      cx: cx,
      key: apiKey,
      searchType: "image",
      num: 10,
    };

    try {
      const response = await axios.get(searchUrl, { params });
      const items = response.data.items || [];
      console.log(response.data.items);
      setimgList(items.map((item) => item.link));
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  return (
    <Modal open={true}>
      <div className={modalOverlayClasses}>
        <div className={modalContentClasses}>
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold mb-4 ">Upload Image</h2>
            <button
              fontSize={"medium"}
              onClick={() => {
                onCancel();
                setSelectedFile("");
              }}
            >
              {" "}
              <i className="bi bi-x-square-fill  text-[22px] text-red-500 font-bold p-1"></i>
            </button>
          </div>

          {tabScreen === 2 && (
            <div className="flex justify-center">
              <div className="">
                <div {...getRootProps({ className: imageUploadClasses })}>
                  <input {...getInputProps()} />
                  <div className="w-full flex justify-center">
                    {selectedFile && (
                      <img
                        src={selectedFile}
                        alt=""
                        className="w-[100px] h-[100px] object-cover my-4"
                      />
                    )}
                  </div>
                  <p className="text-zinc-500 text-center">
                    Drag and drop your image here, or click to select files
                  </p>
                </div>
                <div className="flex justify-end w-[400px]">
                  <button
                    onClick={async () => {
                      const imageResponse = await axios.get(selectedFile, {
                        responseType: "blob",
                      });
                      const imageBlob = imageResponse.data;
                      const name = prefix + selectedItem.ICODE;

                      const path = "retailx/items/";
                      UploadFile(imageBlob, path, name);
                      setItemImgList((o) => {
                        return [
                          ...o,
                          {
                            ICODE: selectedItem.ICODE,
                            img: selectedFile,
                          },
                        ];
                      });
                      setSelectedFile("");
                      setUploadImghStatus(false);
                      if (setRefreshItems) {
                        setRefreshItems((o) => !o);
                      }
                    }}
                    className={uploadButtonClasses}
                  >
                    Upload
                  </button>
                  <button
                    onClick={() => {
                      onCancel();
                      setSelectedFile("");
                    }}
                    className={cancelButtonClasses}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="bg-gray-200 h-[1px] mt-4"></div>
          <div className="flex mt-4 mb-3">
            <input
              type="text"
              placeholder="Search image type..."
              value={imgType}
              onChange={(e) => setImgType(e.target.value)}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "NumpadEnter") {
                  handlePexelesSearch();
                }
              }}
              className={
                "flex-grow border border-border outline-none rounded-l-md p-2"
              }
            />
            <button
              className={`rounded-r-md px-4 bg-brown text-primary-foreground`}
              onClick={handlePexelesSearch}
            >
              Search
            </button>
          </div>
          {/* <canvas ref={canvasRef} style={{ display: 'none' }}></canvas> */}
          <div className="ml-2 grid grid-cols-4 gap-1 h-[400px] overflow-y-auto">
            {imgList.map((i) => {
              return (
                <div
                  onClick={() => {
                    if (tabScreen === 2) {
                      setSelectedFile(i.src.tiny);
                    } else {
                      setUploadImghStatus(false);
                      setFirebaseUrl(i.src.tiny);
                    }
                  }}
                >
                  <img
                    src={i.src.tiny}
                    alt=""
                    className="w-[100px] h-[100px] object-cover mb-4"
                  />
                </div>
              );
            })}
          </div>
          <div className="flex justify-end mt-1">
            <a
              style={{
                textDecoration: "none",
                color: "gray",
              }}
              target="_blank"
              rel="noopener noreferrer" // Added for security
              href="https://www.pexels.com"
            >
              Photos provided by Pexels
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UploadImageModal;
