import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCustomeTwoButtomAlert,
  setCustomeTwoButtomAlert,
} from "../../Redux/modal";
import { setLogin, setToken } from "../../Redux/security";
import { useNavigate } from "react-router-dom";
import rootBillimgLogo from "../../images/GOROUND2.png";
import { handleSignout } from "./logout";

export default function CustomeTwoButtonAlert() {
  const dispatch = useDispatch();
  const status = useSelector(selectCustomeTwoButtomAlert);

  const navigate = useNavigate();
  const myFunction = () => {
    // your logic here
    if (status) {
      dispatch(setToken(""));
      dispatch(setLogin(false));
      navigate("/auth/login");
    }
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();

        // 👇️ your logic here
        if (status) {
          myFunction();
        }
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [status]);

  const handleClose = () => {
    dispatch(setCustomeTwoButtomAlert(false));
  };
  return (
    <div className=" rounded-lg">
      <Modal open={status} onClose={handleClose}>
        <div
          className=" bg-brown overflow-hidden rounded-lg  justify-center  "
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            divShadow: 24,
          }}
        >
          <div className=" text-white flex justify-start pl-3 font-normal py-2  bg-[#262424] drop-shadow-xl">
            genius office
          </div>
          <div className="justify-center min-w-[340px] bg-white p-4 items-center ">
            <div className="flex  w-full items-center ">
              <img src={rootBillimgLogo} className=" w-10  h-auto " alt=" " />
              <div className="text-brown text-md flex items-center pl-3 ">
                Are you sure you want to Log out from Retailx?
              </div>
            </div>
            <div className="flex justify-end  bg-white pt-3 ">
              <div className="px-2">
                <Button
                  size={"small"}
                  onClick={() => {
                    handleSignout(dispatch, navigate);
                  }}
                  color="secondary"
                  variant="contained"
                  sx={{
                    transition: "none",
                    textTransform: "capitalize",
                    backgroundColor: "red",
                    color: "white",
                  }}
                >
                  <div className="px-2 text-white">Logout </div>{" "}
                </Button>
              </div>

              <div>
                <Button
                  size={"small"}
                  onClick={handleClose}
                  variant="contained"
                  sx={{
                    transition: "none",
                    textTransform: "capitalize",
                    backgroundColor: "grey",
                  }}
                >
                  <div className="px-2">Cancel </div>{" "}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
