import React, { useEffect, useState } from "react";

const PlaceHolderTextInput = ({
  onKeyDown,
  align,
  fontWieght,
  onlyValue,
  onFocus,
  onBlur,
  inputTextRightIcon,
  capture,
  onClick,
  reffrence,
  id,
  type,
  placeholder,
  value,
  onchange,
  icon,
  onDoubleClick,
  rightIcon,
  borderColor,
  readOnly,
  autoFocus,
  onLostFocus,
}) => {
  const [border, setBorder] = useState("#A09E9E");
  const [bgcolor, setBgcolor] = useState("white");
  const [isFocus, setIsFocus] = useState(false);

  const [typingTimeout, setTypingTimeout] = useState(null);
  useEffect(() => {
    if (onLostFocus) {
      return () => {
        if (typingTimeout) clearTimeout(typingTimeout);
      };
    }
  }, [typingTimeout]);

  return (
    <div
      style={{
        border: borderColor
          ? `1px solid ${borderColor}`
          : `1px solid ${border}`,
        backgroundColor: bgcolor,
      }}
      className=" rounded-sm w-full flex items-center  "
    >
      {!icon && value && (
        <div className="mr-1 flex pl-2 w-fit text-[10px] t:text-[14px]">
          {`${
            placeholder.includes("Name")
              ? "Name"
              : placeholder.includes("Mobile")
              ? "Mobile"
              : placeholder
          }  : ${" "} `}
        </div>
      )}
      {icon && icon}
      <div className="flex flex-1 p-2">
        {onlyValue ? (
          <div
            style={{
              textAlign: isFocus ? (align ? align : "left") : "left",

              fontWeight: fontWieght ? fontWieght : "normal",
            }}
            className="outline-none  w-fit  text-[12px] t:text-[14px] flex items-center"
          >
            {value}
          </div>
        ) : (
          <input
            style={{
              textAlign: isFocus ? (align ? align : "left") : "left",
              width: `calc(100% - ${icon ? 20 : 0}px)`,
              fontWeight: fontWieght ? fontWieght : "normal",
            }}
            onClick={onClick ? onClick : () => console.log("")}
            autoFocus={autoFocus ? true : false}
            id={id ? id : ""}
            onDoubleClick={onDoubleClick}
            onBlur={(t) => {
              setBorder("#A09E9E");
              setBgcolor("white");
              setIsFocus(false);
              if (onBlur) {
                onBlur(t);
              }
            }}
            onFocus={(t) => {
              setIsFocus(true);
              setBorder("#43bff7");
              setBgcolor("white");
              if (onFocus) {
                onFocus(t);
              }
            }}
            capture={capture}
            ref={reffrence}
            className="outline-none   text-[12px] t:text-[14px] flex items-center"
            onKeyDown={
              onKeyDown
                ? (e) => {
                    onKeyDown(e);
                    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                      e.preventDefault();
                    }
                  }
                : (e) => {
                    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                      e.preventDefault();
                    }
                  }
            }
            readOnly={readOnly ? true : false}
            type={type}
            autoComplete="off"
            placeholder={placeholder}
            value={value}
            onChange={(t) => {
              if (onLostFocus) {
                if (typingTimeout) {
                  clearTimeout(typingTimeout);
                }

                setTypingTimeout(
                  setTimeout(() => {
                    onLostFocus(t);
                  }, 1000)
                );
              }

              onchange(t);
            }}
          />
        )}

        {inputTextRightIcon && inputTextRightIcon}
      </div>
      <div className="pr-2">{rightIcon && rightIcon}</div>
    </div>
  );
};

export default PlaceHolderTextInput;
