import React from "react";
import lcon from "../../../utility/Logo/icons/whiteIcon.svg";
import lconB from "../../../utility/Logo/icons/blackIcon.svg";
import Text from "../../../utility/Logo/TextLogo/whiteTextLogo.svg";
import TextB from "../../../utility/Logo/TextLogo/blackTextLogo.svg";
const retailxLogo = ({ black, size }) => {
  const icSize = size ? size : "35px";
  return (
    <div className=" flex  justify-center items-center">
      <div className=" flex items-center">
        <img
          src={black ? lconB : lcon}
          style={{
            width: icSize,
            height: "auto",
          }}
          alt=""
        />
      </div>
      <div className="flex items-center">
        <img
          src={black ? TextB : Text}
          alt=""
          style={{
            height: icSize,
            width: "auto",
          }}
        />
      </div>
    </div>
  );
};

export default retailxLogo;
