import { DataGrid } from "@mui/x-data-grid";
import React from "react";

const GeniusDatagrid = ({
  onDoubleClick,
  rowID,
  columns,
  list,
  editCell,
  onRowClick,
  dataGridHeader,
  showPagination,
}) => {
  return (
    <div className="h-full w-full  rounded-t-sm oveflow-hidden">
      {" "}
      <DataGrid
        hideFooterPagination={showPagination ? false : true}
        rowHeight={35}
        onRowClick={onRowClick}
        showCellRightBorder={true}
        showColumnRightBorder={true}
        disableColumnMenu={true}
        hideFooter={showPagination ? false : true}
        onCellDoubleClick={onDoubleClick && onDoubleClick}
        getRowId={rowID}
        headerHeight={!dataGridHeader ? 0 : 50}
        columnThreshold={2}
        components={
          !dataGridHeader
            ? {
                Header: () => {
                  const headerDetails = columns;
                  return (
                    <div className="bg-brown w-full  rounded-sm  rounded-b-none flex overflow-hidden border-l border-brown border-r    ">
                      {headerDetails?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="bg-brown text-white flex justify-center items-center whitespace-nowrap py-2  "
                            style={
                              item.flex
                                ? index < headerDetails.length - 1
                                  ? {
                                      flex: item.flex,
                                      borderRight: "1px solid #4a4948",
                                      borderBottom: "1px solid #4a4948",
                                    }
                                  : {
                                      flex: item.flex,
                                      borderBottom: "1px solid #4a4948",
                                    }
                                : index < headerDetails.length - 1
                                ? {
                                    width: item.width,

                                    borderRight: "1px solid white",
                                    borderBottom: "1px solid #4a4948",
                                  }
                                : {
                                    width: item.width,
                                    borderBottom: "1px solid #4a4948",
                                  }
                            }
                          >
                            {item.renderHeader()}
                          </div>
                        );
                      })}
                    </div>
                  );
                },
                NoRowsOverlay: () => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      No Records Found
                    </div>
                  );
                },
              }
            : {
                NoRowsOverlay: () => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      No Records Found
                    </div>
                  );
                },
              }
        }
        sx={
          window.innerWidth < 770
            ? {
                fontSize: 11,
                "& .MuiDataGrid-columnHeadersInner": {
                  borderRight: `1px solid #4a4948`,
                  backgroundColor: "#353232",
                  color: "#ffffff",
                },

                borderRadius: 2,
              }
            : window.innerWidth <= 1280
            ? {
                fontSize: 14,
                "& .MuiDataGrid-columnHeadersInner": {
                  borderRight: `1px solid #4a4948`,
                  backgroundColor: "#353232",
                  color: "#ffffff",
                },

                borderRadius: 2,
              }
            : {
                fontSize: 15,
                borderRadius: 2,
                "& .MuiDataGrid-columnHeadersInner": {
                  borderRight: `1px solid #4a4948`,
                  backgroundColor: "#353232",
                  color: "#ffffff",
                },
              }
        }
        columns={columns}
        rows={list}
        pageSize={100}
        rowsPerPageOptions={[100]}
        disableColumnSelector
        disableColumnReorder
        onCellEditCommit={editCell && editCell}
      />
    </div>
  );
};

export default GeniusDatagrid;
