import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Switch,
  Typography,
  Divider,
  Modal,
  TextField,
} from "@mui/material";
import { IslocalNotificationSoundAlllow } from "../utility/constant";
import { CallAPIPromise } from "../billing/common";
import { useDispatch, useSelector } from "react-redux";
import { selectPrefix } from "../utility/Redux/profile";
import { setAlertWithTitle } from "../utility/Redux/modal";

const SettingsModal = ({ status, onClose }) => {
  const [notifications, setNotifications] = useState(true);
  const [sound, setSound] = useState(false);
  const [activeTab, setActiveTab] = useState("Delivery");
  const prefix = useSelector(selectPrefix);
  const dispatch = useDispatch();
  const [deliveryActive, setDeliveryActive] = useState(true);
  const [pickupActive, setPickupActive] = useState(false);
  const [minimumOrder, setMinimumOrder] = useState(500);
  const [deliveryStartTime, setDeliveryStartTime] = useState("09:00");
  const [deliveryEndTime, setDeliveryEndTime] = useState("18:00");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);

  const localNotificationsAllow = () => {
    const data = localStorage.getItem("notificationAllow");
    return data !== "false";
  };

  useEffect(() => {
    setSound(IslocalNotificationSoundAlllow());
    setNotifications(localNotificationsAllow());
  }, [status]);

  const updateDelievryStatus = () => {
    setLoading(true);

    CallAPIPromise("/api/masterCross/deliverySetup", {
      table_prefix_master: prefix.replace(/_.+_/, "_"),
      delivery_yn: deliveryActive ? "YES" : "NO",
      pickup_yn: pickupActive ? "YES" : "NO",
      startTime: deliveryStartTime,
      endTime: deliveryEndTime,
      id: id || 0,
      minimum_amount: minimumOrder,
    })
      .then((e) => {
        if (!e.data.error) {
          dispatch(
            setAlertWithTitle({
              title: "Success",
              msg: "Delivery settings updated successfully",
            })
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (status) {
      setId(0);
      CallAPIPromise("/api/masterCross/loadDeliverySetup", {
        table_prefix_master: prefix.replace(/_.+_/, "_"),
      }).then((e) => {
        if (!e.data.error && e.data.response[0]) {
          setDeliveryActive(e.data.response[0].DELIVERY_YN === "YES");
          setPickupActive(e.data.response[0].PICKUP_YN === "YES");
          setDeliveryStartTime(e.data.response[0].START_TIME);
          setDeliveryEndTime(e.data.response[0].END_TIME);
          setId(e.data.response[0].ID);
          setMinimumOrder(e.data.response[0].MINIMUM_AMOUNT);
        }
      });
    }
  }, [status]);

  return (
    <Modal open={status} onClose={onClose} aria-labelledby="settings-modal">
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[450px] bg-white rounded-xl shadow-2xl">
        <div className="p-6">
          {/* Header */}
          <div className="flex justify-between items-center mb-6">
            <Typography variant="h5" className="font-semibold text-gray-800">
              Settings
            </Typography>
            <button
              onClick={onClose}
              className="p-1 hover:bg-gray-100 rounded-full transition-colors"
            >
              <i className="bi bi-x-lg p-2 flex items-center text-gray-600"></i>
            </button>
          </div>

          {/* Tabs */}
          <div className="flex gap-2 p-1 bg-gray-100 rounded-lg mb-6">
            {["Delivery", "Notifications"].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`flex-1 py-2 px-4 rounded-md transition-all ${
                  activeTab === tab
                    ? "bg-brown text-white shadow-sm"
                    : "text-gray-600 hover:bg-gray-200"
                }`}
              >
                {tab}
              </button>
            ))}
          </div>

          {/* Content */}
          {activeTab === "Delivery" ? (
            <div className="">
              <div className="flex items-center justify-between">
                <div>
                  <Typography variant="subtitle1" className="font-medium">
                    Enable Delivery
                  </Typography>
                  <Typography variant="body2" className="text-gray-500">
                    Toggle delivery service on/off
                  </Typography>
                </div>
                <Switch
                  checked={deliveryActive}
                  onChange={() => setDeliveryActive(!deliveryActive)}
                  color="primary"
                />
              </div>

              {deliveryActive && (
                <div className="mt-2 ">
                  <div className="flex ">
                    <div className="flex-1 ">
                      <div className="">Start Time</div>
                      <input
                        type="time"
                        label=""
                        value={deliveryStartTime}
                        onChange={(e) => setDeliveryStartTime(e.target.value)}
                        fullWidth
                        className="border p-2  mt-1 flex-1 w-full flex"
                      />
                    </div>
                    <div className="flex-1 ml-2">
                      <div className="">End Time</div>
                      <input
                        type="time"
                        label="End Time"
                        value={deliveryEndTime}
                        className="border p-2  mt-1 flex-1 w-full flex"
                        onChange={(e) => setDeliveryEndTime(e.target.value)}
                        fullWidth
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="flex items-center justify-between mt-2">
                <div>
                  <Typography variant="subtitle1" className="font-medium">
                    Enable Pickup
                  </Typography>
                  <Typography variant="body2" className="text-gray-500">
                    Toggle pickup service on/off
                  </Typography>
                </div>
                <Switch
                  checked={pickupActive}
                  onChange={() => setPickupActive(!pickupActive)}
                  color="primary"
                />
              </div>

              <div className="flex items-center flex-1  justify-between mt-2">
                <div className="flex-1">
                  <Typography variant="subtitle1" className="font-medium">
                    Minimum Order
                  </Typography>

                  <div className="border bg-red-100 w-1/2 mt-1">
                    <input
                      value={minimumOrder}
                      onChange={(e) => setMinimumOrder(e.target.value)}
                      type={"number"}
                      className="p-2  w-full  "
                    />
                  </div>
                </div>
              </div>
              <Button
                variant="contained"
                fullWidth
                disabled={loading}
                onClick={updateDelievryStatus}
                className="mt-4  py-2"
              >
                Save Changes
              </Button>
            </div>
          ) : (
            <div className="space-y-6">
              <div className="flex items-center justify-between">
                <div>
                  <Typography variant="subtitle1" className="font-medium">
                    Enable Notifications
                  </Typography>
                  <Typography variant="body2" className="text-gray-500">
                    Receive important updates
                  </Typography>
                </div>
                <Switch
                  checked={notifications}
                  onChange={(e) => {
                    setNotifications(!notifications);
                    localStorage.setItem("notificationAllow", !notifications);
                  }}
                  color="primary"
                />
              </div>

              <div className="flex items-center justify-between">
                <div>
                  <Typography variant="subtitle1" className="font-medium">
                    Notification Sound
                  </Typography>
                  <Typography variant="body2" className="text-gray-500">
                    Play sound for notifications
                  </Typography>
                </div>
                <Switch
                  checked={sound}
                  onChange={(e) => {
                    setSound(!sound);
                    localStorage.setItem("notificationSound", !sound);
                  }}
                  color="primary"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default SettingsModal;
