import axios from "axios";
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from "../Redux/modal";
import { apiClient } from "../../billing/common";
import Cookies from "js-cookie";
import { setToken } from "../Redux/security";
import Store from "../Redux/store";

const CallAPI = (path, data, token, then, dispatch, final, c) => {
  const state = Store.getState();
  const tokenLocal = state.security.token;
  apiClient.defaults.headers.common["Authorization"] = `Bearer ${
    token ? token : tokenLocal
  }`;

  apiClient
    .post(path, data)
    .then((t) => {
      then(t);
      if (t?.data?.error) {
        console.log("err");
        dispatch(setSpinnerLoading(false));
      }
    })
    .catch(async (error) => {
      // dispatch(setSpinnerLoading(false));
      console.log(error);

      if (error.response.data.name === "TokenExpiredError") {
        try {
          const refreshToken = Cookies.get("refreshToken");

          const refreshTokenResponse = await apiClient.post(
            "/api/users/refresh-token",
            {
              refreshToken: refreshToken,
            }
          );
          console.log(refreshTokenResponse);

          const newToken = refreshTokenResponse.data.accessToken;
          console.log(newToken);
          // Update Authorization Header with New Token
          apiClient.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${newToken}`;

          // Retry Original Request
          const retryResponse = await apiClient.post(path, data);
          Store.dispatch(setToken(newToken));
          if (!retryResponse.data?.error) {
            then(retryResponse);
          } else {
            Store.dispatch(setSpinnerLoading(false));
          }
        } catch (refreshError) {
          console.error("Refresh Token Error:", refreshError);
          //  Store.dispatch(setSessionExpired(true));
          c(refreshError);
        }
      } else {
        console.log(error.response.data?.message);

        if (error.response.data?.message) {
          Store.dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: error.response.data?.message,
            })
          );
          return;
        }
        if (c) {
          c(error);
        } else {
          Store.dispatch(
            setAlertWithTitle({
              title: "Error",
              msg: "Something went wrong",
            })
          );
        }
      }
    })
    .finally(final ? final : () => {});
};

export default CallAPI;
