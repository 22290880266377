import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import GeniustextInput from "../../utility/component/mastertext";
import ModalHeaderStyle from "../../utility/component/styles/modalHeader";
import GeniusDatagrid from "../../utility/component/datagrid/geniusDatagrid";
import {
  ModifyButton,
  SaveButton,
  UndoButton,
} from "../../utility/component/buttons";
import {
  setSessionExpired,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import { useNavigate } from "react-router-dom";
import { selectPrefix, selectWidth } from "../../utility/Redux/profile";
import { selectToken } from "../../utility/Redux/security";
import { toProperCase } from "../../utility/component/format";
import { useFocus } from "../../utility/hooks/hooks";
import { saveDepartmentMasr } from "./func";
import { CallAPIPromise } from "../../billing/common";
const DepartMentMaster = ({ onClose, afterCreate }) => {
  const dispatch = useDispatch();
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const [id, setId] = useState();
  const [secName, setSecName] = useState();
  const [departmentType, setDepartmentType] = useState("Trading");
  const [rows, setRows] = useState([]);
  const [deprtRef, setDepartFocus] = useFocus();
  const navigate = useNavigate();
  const windowWidth = useSelector(selectWidth);
  const columns = [
    {
      field: "DEP_NAME",
      type: "string",
      renderHeader: (params) => <div>Department</div>,
      width: windowWidth > 768 ? 304 : 140,
      headerAlign: "center",
      align: "left",
      renderCell: (params) => {
        const runningCaseValue = params.value;
        return <div>{runningCaseValue}</div>;
      },
    },
    {
      field: "DEP_TYPE",
      type: "string",
      renderHeader: (params) => <div> Type</div>,
      width: windowWidth > 768 ? 304 : 150,
      headerAlign: "center",
      align: "left",
      renderCell: (params) => {
        const runningCaseValue = params.value;
        return <div>{runningCaseValue}</div>;
      },
    },
  ];

  const getData = async () => {
    dispatch(setSpinnerLoading("Loading"));
    const data = {
      table_prefix: prefix,
      DEP_NAME: "",
    };
    CallAPIPromise("/api/master/listDepartmentMaster", data, token, dispatch)
      .then((p) => {
        if (!p.data.error) {
          const data = p.data.response;

          setRows(data);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    function handleKeyPress(event) {
      // Check if Ctrl or Alt key is pressed along with 'S'
      if ((event.ctrlKey || event.altKey) && event.key === "s") {
        // Call your function here
        saveDepartmentMasr(
          setDepartFocus,
          secName,
          departmentType,
          rows,
          dispatch,
          prefix,
          id,
          setId,
          token,
          setSecName,
          getData,
          afterCreate
        );
      }
    }

    // Add event listener when component mounts
    window.addEventListener("keydown", handleKeyPress);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [secName, departmentType]);
  const isPadding = window.location.pathname.includes("department_master");

  return (
    <div
      style={{
        height: afterCreate ? window.innerHeight - 150 : "100%",
      }}
      className="w-full  flex justify-center bg-[#f5f5f7] text-black  "
    >
      <div
        style={{
          padding: isPadding ? "16px" : "0px",
        }}
        className="text-sm  rounded-sm   flex flex-col   h-full "
      >
        <div>
          <ModalHeaderStyle
            header={"Department Master"}
            onClose={() => {
              if (onClose) {
                onClose();
              } else {
                navigate("/masters");
              }
            }}
          />
        </div>
        <div
          style={{
            height: "calc(100% - 150px)",
            width: columns[0].width + columns[1].width + 30,
          }}
          className=" bg-[#fdfffe] rounded-b-sm    border-brown p-3 max-sm:px-2"
        >
          <div className="grid grid-cols-[1fr,100px] gap-2">
            <GeniustextInput
              max={100}
              grid={true}
              title={"Department Name"}
              width={windowWidth > 768 ? "400px" : "200px"}
              type={"text"}
              value={secName}
              autofocus={true}
              refrance={deprtRef}
              onChange={(e) => {
                setSecName(toProperCase(e.target.value));
              }}
            />
            <GeniustextInput
              grid={true}
              width={windowWidth > 768 ? "220px" : "120px"}
              option={[
                {
                  name: "Trading",
                  value: "Trading",
                },
                {
                  name: "Manufacturing",
                  value: "Manufacturing",
                },
              ]}
              value={departmentType}
              onChange={(e) => {
                setDepartmentType(e.target.value);
              }}
            />
          </div>

          <div
            style={{
              height: "calc(100% - 135px)",
            }}
            className="w-full bg-white mt-1 f"
          >
            <GeniusDatagrid
              rowID={(e) => e.CODE}
              onRowClick={(p) => {
                setId(p.row.CODE);
                setSecName(p.row.DEP_NAME);
                setDepartmentType(p.row.DEP_TYPE);
              }}
              columns={columns}
              list={rows}
            />
          </div>

          <div className="flex w-full justify-end items-center pt-3">
            {id ? (
              <ModifyButton
                onClick={() => {
                  saveDepartmentMasr(
                    setDepartFocus,
                    secName,
                    departmentType,
                    rows,
                    dispatch,
                    prefix,
                    id,
                    setId,
                    token,
                    setSecName,
                    getData,
                    afterCreate
                  );
                }}
              />
            ) : (
              <SaveButton
                onClick={() => {
                  saveDepartmentMasr(
                    setDepartFocus,
                    secName,
                    departmentType,
                    rows,
                    dispatch,
                    prefix,
                    id,
                    setId,
                    token,
                    setSecName,
                    getData,
                    afterCreate
                  );
                }}
              />
            )}

            <UndoButton
              onClick={() => {
                setSecName("");
                setId("");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepartMentMaster;
