import React, { useEffect, useState } from "react";
import "../../../billing/PaymentForm.css";
import dial from "../../../utility/images/keypad-icon.svg";
const LabelTextInput = ({
  onKeyDown,
  fontWieght,
  onFocus,
  onBlur,
  capture,
  inputRightIcon,
  onClick,
  reffrence,
  id,
  type,
  placeholder,
  value,
  onchange,
  onDoubleClick,
  rightIcon,
  readOnly,
  autoFocus,
  onLostFocus,
  label,
}) => {
  const [isFocus, setIsFocus] = useState(false);

  const [typingTimeout, setTypingTimeout] = useState(null);
  useEffect(() => {
    if (onLostFocus) {
      return () => {
        if (typingTimeout) clearTimeout(typingTimeout);
      };
    }
    // Cleanup function to clear timeout if component unmounts
  }, [typingTimeout]);

  return (
    <div className="input-group2">
      <label
        style={{
          fontWeight: label === "Balance" ? "600" : "normal",
        }}
        className="flex items-center text-black"
      >
        {label} {rightIcon && rightIcon}
      </label>
      <div
        style={{
          border: `1px solid ${isFocus ? "#65b9fc" : "#f0eded"}`,
          borderRadius: "4px",
          display: "flex",
          alignItems: "center",
        }}
        className="overflow-hidden"
      >
        {type === "number" && label !== "Mobile" && (
          <button
            onClick={onClick}
            className="bg-gray-50 h-full py-[10px] px-[10px] flex items-center"
          >
            <img src={dial} className="h-[16px]  w-[16px] " />
          </button>
        )}
        <input
          style={{
            width: `calc(100% - 0px`,
            textAlign:
              type === "number" && label !== "Mobile" ? "right" : "left",
            fontWeight: fontWieght ? fontWieght : 600,
            padding: "10px",
            fontSize: "15px",
          }}
          autoFocus={autoFocus ? true : false}
          id={id ? id : ""}
          onDoubleClick={onDoubleClick}
          onBlur={(t) => {
            setIsFocus(false);
            if (onBlur) {
              onBlur(t);
            }
          }}
          onFocus={(t) => {
            setIsFocus(true);
            if (onFocus) {
              onFocus(t);
            }
          }}
          capture={capture}
          ref={reffrence}
          className="outline-none   placeholder:font-normal text-[12px] t:text-[14px] flex items-center"
          onKeyDown={
            onKeyDown
              ? (e) => {
                  onKeyDown(e);
                  if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                    e.preventDefault();
                  }
                }
              : (e) => {
                  if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                    e.preventDefault();
                  }
                }
          }
          readOnly={readOnly ? true : false}
          type={type}
          autoComplete="off"
          placeholder={placeholder}
          value={value}
          onChange={(t) => {
            if (onLostFocus) {
              if (typingTimeout) {
                clearTimeout(typingTimeout);
              }

              setTypingTimeout(
                setTimeout(() => {
                  onLostFocus(t);
                }, 1000)
              );
            }

            onchange(t);
          }}
        />
        {type === "number" && label === "Mobile" ? (
          inputRightIcon ? (
            inputRightIcon
          ) : (
            <button
              onClick={() => {
                onClick();
              }}
              className="bg-gray-50 h-full py-[10px] px-[10px] flex items-center"
            >
              <img src={dial} className="h-[16px]  w-[16px] " />
            </button>
          )
        ) : null}

        {inputRightIcon && label !== "Mobile" ? inputRightIcon : null}
      </div>
    </div>
  );
};

export default LabelTextInput;
