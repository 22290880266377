import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  selectCompanyAddress,
  selectEmail,
  selectUserType,
  selectWidth,
} from "../../Redux/profile";
import MasterHelp from "../modals/masterHelp";
import { setSessionExpired, setSpinnerLoading } from "../../Redux/modal";
import CryptoJS from "crypto-js";
import { CallAPIPromise } from "../../../billing/common";

export default function ModUserInfo({
  onRowClick,
  status,
  setStatus,
  company_email,
  user_email,
  user,
}) {
  const handleClose = () => setStatus(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    height: "85%",
    backgroundColor: "white",
    padding: "2px",
    borderRadius: "8px",
    // Change the shadow color
    overflow: "hidden",
    // boxShadow: 'none',
  };
  const width = useSelector(selectWidth);
  const columns = React.useMemo(
    () => [
      {
        field: "USER_NAME",
        type: "string",
        width: width < 500 ? 100 : 200,
        headerAlign: "center",
        align: "left",
        headerClassName: "super-app-theme--header",
        renderHeader: (params) => <div>{"User Name"}</div>,
      },
      {
        field: "USER_EMAIL",
        type: "number",
        width: width < 500 ? 150 : 250,
        headerAlign: "center",
        align: "left",
        headerClassName: "super-app-theme--header",

        renderHeader: (params) => <div>{"User Email"}</div>,
      },
      {
        field: "COMPANY_NAME",
        type: "number",
        width: width < 500 ? 100 : 250,
        headerAlign: "center",
        align: "left",
        headerClassName: "super-app-theme--header",
        renderCell: (params) => {
          return (
            <div className="flex justify-between w-full">
              <div>{params.value}</div>
            </div>
          );
        },
        renderHeader: (params) => <div>{"Company Name"}</div>,
      },
    ],
    []
  );
  const [searchText, SetSearchText] = useState("");
  const [fullList, setFullList] = useState([]);
  const [list, setList] = useState([]);
  const companyDetail = useSelector(selectCompanyAddress);
  const userEmail = useSelector(selectEmail);
  const userType = useSelector(selectUserType);
  const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
  const dispatch = useDispatch();
  const getUserInfoDetails = () => {
    dispatch(setSpinnerLoading("Loading"));
    const email = user_email ? user_email : userEmail;
    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(email, secretPass);
    // Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    const data = {
      company_email: company_email ? company_email : companyDetail.email,
      user_email: user_email ? user_email : userEmail,
      user_mobile: "",
      user_type: user ? user : userType,
    };
    CallAPIPromise(`/api/users/userHelp`, data, hmacString, dispatch)
      .then((res) => {
        if (!res.data.error) {
          const data = res.data.response;
          setFullList(data);
          setList(data);
        }
      })
      .catch((e) => {
        console.log(e);

        if (e.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    if (status) {
      getUserInfoDetails();
    }
  }, [status]);

  useEffect(() => {
    if (searchText) {
      const regex = /\d+/;
      const searchItems = fullList.filter((item) => {
        if (regex.test(searchText.substring(0, 1))) {
          return item.STATION_ID.toString().includes(searchText);
        } else {
          return item.USER_NAME.toLowerCase().includes(
            searchText.toLowerCase()
          );
        }
      });

      setList(searchItems);
    } else {
      setList(fullList);
    }
  }, [searchText]);

  return (
    <div className="bg-white">
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <MasterHelp
        title={"User Info - Help"}
        status={status}
        onClose={() => {
          handleClose();
          SetSearchText("");
          setList([]);
        }}
        style={style}
        columns={columns}
        list={list}
        rowId={(p) => p.CODE}
        searchQuery={searchText}
        setSearchQuery={SetSearchText}
        onRowClick={(p) => {
          onRowClick(p);
          setList([]);
          SetSearchText("");
        }}
      />
    </div>
  );
}
