import { useDispatch, useSelector } from "react-redux";
import {
  setItemMasterHelpStatus,
  setSessionExpired,
  setSpinnerLoading,
} from "../utility/Redux/modal";
import { useState } from "react";
import { selectToken } from "../utility/Redux/security";
import { useEffect } from "react";
import { selectPrefix, selectWidth } from "../utility/Redux/profile";
import MasterHelp from "../utility/component/modals/masterHelp";
import { FormatNumber2 } from "../utility/functions/formatNumber";
import { CallAPIPromise } from "../billing/common";

const ItemMasterHelp = ({ onRowClick, status, onClose, searchText }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "80%",
    width: "auto",
    padding: "2px",
    borderRadius: "8px",
    overflow: "hidden",
    backgroundColor: "white",
  };
  const [rows, setRows] = useState([]);
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);

  const width = useSelector(selectWidth);
  const columns = [
    {
      width:
        width <= 768 ? 50 : width <= 1024 ? 100 : width <= 1440 ? 100 : 100,
      headerAlign: "center",
      align: "left",
      field: "ICODE",
      headerName: "Code",
      renderHeader: (params) => <div>Code</div>,
    },
    {
      width:
        width <= 768 ? 100 : width <= 1024 ? 100 : width <= 1440 ? 150 : 280,
      headerAlign: "center",
      align: "left",
      field: "ITEMNAME",
      headerName: "Item Name",
      renderHeader: (params) => <div>Item Name</div>,
    },
    {
      width:
        width <= 768 ? 60 : width <= 1024 ? 100 : width <= 1440 ? 150 : 110,
      headerAlign: "center",
      align: "right",
      field: "SALE_PRICE",
      headerName: "Rate",
      renderCell: (param) => {
        if (param.value === 0) {
          return <div> {""}</div>;
        } else {
          return FormatNumber2(param.value);
        }
      },
      renderHeader: (params) => <div>Rate</div>,
    },
    {
      field: "DEP_NAME",
      renderHeader: (params) => <div>Department</div>,
      width:
        width <= 768 ? 100 : width <= 1024 ? 100 : width <= 1440 ? 100 : 200,
      headerAlign: "center",
      align: "left",
    },
  ];

  const mobileColumns = [
    {
      width:
        width <= 768 ? 50 : width <= 1024 ? 100 : width <= 1440 ? 100 : 100,
      headerAlign: "center",
      align: "left",
      field: "ICODE",
      headerName: "Code",
      renderHeader: (params) => <div>Code</div>,
    },
    {
      width: 180,
      headerAlign: "center",
      align: "left",
      field: "ITEMNAME",
      headerName: "Item Name",
      renderHeader: (params) => <div>Item Name</div>,
    },
    {
      width:
        width <= 768 ? 60 : width <= 1024 ? 100 : width <= 1440 ? 150 : 110,
      headerAlign: "center",
      align: "right",
      field: "SALE_PRICE",
      headerName: "Rate",
      renderCell: (param) => {
        if (param.value === 0) {
          return <div> {""}</div>;
        } else {
          return FormatNumber2(param.value);
        }
      },
      renderHeader: (params) => <div>Rate</div>,
    },
  ];

  const [searchQuery, setSearchQuery] = useState(searchText ? searchText : "");
  const [fullList, setFullList] = useState([]);
  useEffect(() => {
    const searchT = searchQuery;
    if (searchT) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchT.substring(0, 1))) {
          return item.ICODE.toString().includes(searchT);
        } else {
          return item.ITEMNAME.toLowerCase().includes(searchT.toLowerCase());
        }
      });
      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery, searchText]);

  const dispatch = useDispatch();

  const getData = async () => {
    dispatch(setSpinnerLoading("Loading"));
    const data = {
      table_prefix: prefix,
      SECTION_NAME: "",
      ICODE: "",
      ALIAS_CODE: "",
      ITEMNAME: "",
    };
    CallAPIPromise("/api/master/listItemMaster", data, token, dispatch)
      .then((p) => {
        let list = [];
        const data = p.data.response;

        for (let i = 0; i < data.length; i++) {
          list.push({
            ...data[i],
            id: i + 1,
          });
        }
        if (searchText) {
          setRows(
            list.filter((i) =>
              i.ITEMNAME.toLowerCase().includes(searchText.toLowerCase())
            )
          );
        } else {
          setRows(list);
        }
        setFullList(list);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    if (status) {
      getData();
    }
  }, [status]);

  const handleClose = () => {
    dispatch(setItemMasterHelpStatus(false));
    setSearchQuery("");
  };

  useEffect(() => {
    setSearchQuery(searchText ? searchText : "");
  }, [searchText]);

  return (
    <>
      <div className="bg-white">
        <MasterHelp
          status={status}
          style={style}
          onClose={() => {
            onClose();
            setRows([]);
            setSearchQuery("");
          }}
          title={"Item Master | Help"}
          columns={width < 500 ? mobileColumns : columns}
          list={rows}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onRowClick={(p) => {
            onRowClick(p);
            setRows([]);
            setSearchQuery("");
          }}
        />
      </div>
    </>
  );
};

export default ItemMasterHelp;
