import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './utility/Redux/store';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import HttpsRedirect from 'react-https-redirect';

const theme = createTheme({
  palette: {
    primary: {
      main: '#383535',
      light: '#757ce8',
    },

    secondary: {
      main: '#f5874f',
      light: '#a8a4a2',
    },
    success: {
      main: '#a8cf45',
    },
    orange: {
      light: '#f5874f',
      main: '#f5874f',
      dark: '#b36239',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    green: {
      light: '#c9f752',
      main: '#a8cf45',
      dark: '#67802a',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    indigo: {
      light: '#655ced',
      main: '#4f46e5',
      dark: '#18163d',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    gray: {
      light: '#babcbf',
      main: '#969799',
      dark: '#A9A9A9',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    gray2: {
      main: '#A9A9A9',
    },
    white: {
      main: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: [
      'Segoe UI',
      '-apple-system',
      'BlinkMacSystemFont',

      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',

      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Segoe UI"',
      '"Open Sans"',
    ].join(','),
  },
  components: {
    MuiDataGridCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Arial, sans-serif', // your font family
          textRendering: 'optimizeSpeed',
          fontSize: 14,
        },
      },
    },
    MuiDataGridRow: {
      styleOverrides: {
        root: {
          fontFamily: 'Arial, sans-serif', // your font family
          textRendering: 'optimizeSpeed',
          fontSize: 14,
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        root: {
          '&$sizeSmall': {
            fontSize: '4px', // Adjust the font size for the small size
          },
          '&$sizeMedium': {
            fontSize: '16px', // Adjust the font size for the medium size
          },
          '&$sizeLarge': {
            fontSize: '20px', // Adjust the font size for the large size
          },
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <HttpsRedirect>
      <BrowserRouter basename={'/'}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </HttpsRedirect>
  </Provider>
);
// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
