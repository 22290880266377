import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import {
  SelectAlertStatus,
  setALertStatus,
  selectAlertOkStatus,
  setAlertOkStatus,
  setloading,
  SelectIsExpired,
} from "../../Redux/modal";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../images/icon.svg";
import { useNavigate } from "react-router-dom";
import { handleSignout } from "./logout";

export default function SessionOutModal() {
  const dispatch = useDispatch();
  const alertStatus = useSelector(SelectAlertStatus);
  const isExpired = useSelector(SelectIsExpired);
  const navigate = useNavigate();
  const okStatus = useSelector(selectAlertOkStatus);
  const myFunction = () => {
    if (alertStatus) {
      if (Number(okStatus) === 0) {
        dispatch(setAlertOkStatus(Number(okStatus) + 1));
      } else {
        dispatch(setAlertOkStatus(0));
        dispatch(setALertStatus(false));
        dispatch(setloading(false));
      }
    }
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();

        if (alertStatus) {
          myFunction();
        }
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [alertStatus, okStatus]);
  return (
    <div className=" rounded-lg">
      <Modal open={isExpired}>
        <div
          className=" bg-brown overflow-hidden min-w-[340px] rounded-lg justify-center  "
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            divShadow: 24,
          }}
        >
          <div className=" text-white flex justify-start pl-3 font-normal py-2  bg-[#262424] drop-shadow-xl">
            genius office
          </div>
          <div className="justify-center bg-white min-h-32 pt-3 items-center  ">
            <div className="flex  w-full px-4 pb-3  items-center ">
              <img src={logo} className="mr-3 w-10 h-10 " alt=" " />
              <div>
                <div className="text-brown text-md ">
                  {"Your session expired! please login again"}
                </div>
              </div>
            </div>
            <div className="flex justify-end   bg-white px-4 ">
              <Button
                size={"small"}
                onClick={() => {
                  handleSignout(dispatch, navigate);
                }}
                color="secondary"
                variant="contained"
              >
                <div className="px-3">Ok </div>{" "}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
