import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import GeniustextInput from "../../utility/component/mastertext";
import ModalHeaderStyle from "../../utility/component/styles/modalHeader";
import {
  ModifyButton,
  SaveButton,
  UndoButton,
} from "../../utility/component/buttons";
import GeniusDatagrid from "../../utility/component/datagrid/geniusDatagrid";
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import { useNavigate } from "react-router-dom";
import { selectPrefix, selectWidth } from "../../utility/Redux/profile";
import { selectToken } from "../../utility/Redux/security";
import { setSectionList } from "../../utility/Redux/list";
import { saveSectionMasterApi } from "./func";
import { toProperCase } from "../../utility/component/format";
import { useFocus } from "../../utility/hooks/hooks";
import { CallAPIPromise } from "../../billing/common";
const SectionMaster = ({ onClose, afterCreate }) => {
  const dispatch = useDispatch();
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const [secRf, setSecFocus] = useFocus();
  const [id, setId] = useState();
  const [secName, setSecName] = useState();
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();
  const windowWidth = useSelector(selectWidth);
  const columns = [
    {
      field: "SECTION_NAME",
      type: "string",
      renderHeader: (params) => <div>Section Name</div>,
      width: windowWidth > 768 ? 300 : 260,
      headerAlign: "center",
      align: "left",
      renderCell: (params) => {
        const runningCaseValue = params.value;
        return (
          <div className="hover:cursor-text w-full">
            {toProperCase(runningCaseValue)}
          </div>
        );
      },
    },
  ];

  const listSectionMasterApi = () => {
    const data = {
      table_prefix: prefix,
      SECTION_NAME: "",
    };

    CallAPIPromise(`/api/master/listSectionMaster`, data, token, dispatch)
      .then(async function (response) {
        if (!response.data.error) {
          const data = response.data.response;

          setRows(data);
        } else {
          dispatch(
            setAlertWithTitle({
              title: "Error 112",
              msg: response.data.error,
            })
          );
          console.log(!response.data.error);
        }
      })
      .catch(function (error) {
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        } else {
          dispatch(
            setAlertWithTitle({
              title: "ERROR",
              msg: "Something went wrong.",
              lottie: "reject",
            })
          );
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        setSecName("");
        setId("");
      });
  };

  useEffect(() => {
    listSectionMasterApi();
  }, []);
  useEffect(() => {
    function handleKeyPress(event) {
      // Check if Ctrl or Alt key is pressed along with 'S'
      if ((event.ctrlKey || event.altKey) && event.key === "s") {
        // Call your function here
        saveSectionMasterApi(
          setSecFocus,
          secName,
          rows,
          dispatch,
          listSectionMasterApi,
          prefix,
          id,
          setId,
          setSectionList,
          token,
          setSecName,
          afterCreate
        );
      }
    }

    // Add event listener when component mounts
    window.addEventListener("keydown", handleKeyPress);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [secName]);
  const isPadding = window.location.pathname.includes("section_master");
  return (
    <div
      style={{
        height: afterCreate ? window.innerHeight - 150 : "100%",
      }}
      className="w-full  flex justify-center bg-[#f5f5f7] "
    >
      <div
        style={{
          padding: isPadding ? "16px" : "0px",
        }}
        className="text-sm  rounded-sm   flex flex-col  h-full "
      >
        <div>
          <ModalHeaderStyle
            header={"Section Master"}
            onClose={() => {
              if (onClose) {
                onClose();
              } else {
                navigate("/masters");
              }
            }}
          />
        </div>
        <div
          style={{
            height: "calc(100% - 160px)",
          }}
          className=" bg-[#fdfffe] rounded-b-sm   border-brown p-3 max-sm:px-2"
        >
          <div id="input" className="grid grid-cols-1 ">
            <div>
              <GeniustextInput
                title={"Section Name"}
                grid={true}
                max={100}
                type={"text"}
                value={secName}
                refrance={secRf}
                autofocus={true}
                onChange={(e) => {
                  setSecName(toProperCase(e.target.value));
                }}
              />
            </div>
          </div>

          <div
            style={{
              height: "calc(100% - 135px)",
              width: columns[0].width + 20,
            }}
            className="w-full  mt-1 bg-white flex"
          >
            <GeniusDatagrid
              rowID={(e) => e.CODE}
              onRowClick={(p) => {
                setId(p.row.CODE);
                setSecName(p.row.SECTION_NAME);
              }}
              columns={columns}
              list={rows}
            />
          </div>

          <div className="flex w-full justify-end items-center pt-3">
            {id ? (
              <ModifyButton
                onClick={() => {
                  saveSectionMasterApi(
                    setSecFocus,
                    secName,
                    rows,
                    dispatch,
                    listSectionMasterApi,
                    prefix,
                    id,
                    setId,
                    setSectionList,
                    token,
                    setSecName,
                    afterCreate
                  );
                }}
              />
            ) : (
              <SaveButton
                onClick={() => {
                  saveSectionMasterApi(
                    setSecFocus,
                    secName,
                    rows,
                    dispatch,
                    listSectionMasterApi,
                    prefix,
                    id,
                    setId,
                    setSectionList,
                    token,
                    setSecName,
                    afterCreate
                  );
                }}
              />
            )}

            <UndoButton
              onClick={() => {
                setSecName("");
                setId("");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionMaster;
