import { Buffer } from "buffer";

export async function isTokenExpired(token) {
  const decodedToken = decodeToken(token);
  if (!decodedToken || !decodedToken.exp) {
    // Token is invalid or doesn't have an expiration time
    return true;
  }

  // Get the current time in seconds
  const currentTime = Math.floor(Date.now() / 1000);

  // Check if the token expiration time is in the past
  return decodedToken.exp < currentTime;
}

function decodeToken(token) {
  try {
    const base64Url = token.split(".")[1]; // Extract payload
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const decodedPayload = Buffer.from(base64, "base64").toString("utf-8"); // Decode base64 payload
    return JSON.parse(decodedPayload);
  } catch (error) {
    // Handle decoding errors
    console.error("Error decoding token:", error);
    return null;
  }
}
