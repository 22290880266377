import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { selectPrefix, selectWidth } from "../../utility/Redux/profile";
import { setAlertWithTitle } from "../../utility/Redux/modal";
import { GetItemWiseSaleReport } from "./func";
import { selectToken } from "../../utility/Redux/security";
import FromToDateMobile from "../../utility/component/fromToDateMobile";
import { isItemHaveOffer } from "../../billing/function";
import BarcodeScanner from "../../utility/component/barcodeScanner";
import { ItemMasterHelp } from "../../help";
import FullWidthModal from "./fullWidthModal";
import { DownloadFile } from "../../utility/functions/firebase";
import { FormatNumber2 } from "../../utility/functions/formatNumber";
import ItemWisePurchaseHistoryHelp from "../../help/itemWisePurchaseHistory";
import { getItemByAlios } from "../../utility/constant";

const InputValues = ({ title, value, style, onClick }) => {
  return (
    <div style={style ? style : {}} className="flex py-1 pl-2">
      <div className="w-[150px] font-semibold">{title}</div>:
      <div onClick={onClick} className="ml-3">
        {value}
      </div>
    </div>
  );
};
const SaleTaxReport = () => {
  const prefix = useSelector(selectPrefix);
  const [summaryList, setSummaryList] = useState([]);
  const [fullWidthModalStatus, setFullWidthModalStatus] = useState(false);
  const [
    itemWisePurchaseHistoryHelpStatus,
    setItemWisePurchaseHistoryHelpStatus,
  ] = useState(false);
  const width = useSelector(selectWidth);
  const [barCode, setBarCode] = useState("");
  const [img, setImg] = useState("");
  const [offerCart, setOfferCart] = useState("");
  useEffect(() => {
    if (fullWidthModalStatus && summaryList[0]) {
      const path = `retailx/items/${prefix + summaryList[0].ICODE}`;
      DownloadFile(path).then((e) => {
        if (!e.includes("FirebaseError")) {
          setImg(e);
        }
      });
    }
  }, [fullWidthModalStatus, summaryList]);
  const [barCodeStatus, setBarCodeStatus] = useState(false);
  const [itemHelpStatus, setItemHelpStatus] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const [itemData, setItemData] = useState("");

  const onOkClick = () => {
    if (barCode || itemData) {
      const alios = barCode ? barCode : itemData?.ALIAS_CODE;
      getItemByAlios(prefix, alios, token, dispatch).then((icodeList) => {
        if (barCode) {
          setItemData(icodeList[0]);
          setBarCode("");
        }

        if (icodeList[0]) {
          GetItemWiseSaleReport(
            {
              table_prefix: prefix,
              ICODE: itemData ? itemData?.ICODE : icodeList[0].ICODE,
            },
            token,
            dispatch,
            setSummaryList,
            setFullWidthModalStatus
          );
          isItemHaveOffer(
            prefix,
            itemData ? itemData?.ICODE : icodeList[0].ICODE,
            token,
            dispatch
          ).then((re) => {
            if (re[0]) {
              setOfferCart(re[0]);
            } else {
              setOfferCart("");
            }
          });
        }
      });
    } else {
      dispatch(
        setAlertWithTitle({
          title: "Alert",
          msg: "Please select item first or scan",
        })
      );
    }
  };

  return (
    <div className=" bg-white flex flex-col h-full overflow-y-auto   ">
      <div className=" flex flex-col h-full p-3  ">
        <div>
          {
            <>
              <FromToDateMobile
                maxWidth={400}
                inputClear={() => {
                  setBarCode("");
                  setSummaryList([]);
                  setItemData("");
                  setImg("");
                }}
                onItemClick={() => {
                  setItemHelpStatus(true);
                }}
                itemData={itemData}
                hideDate={true}
                otherButton={
                  <div className="flex items-center w-full">
                    <button
                      onClick={() => {
                        setBarCodeStatus(true);
                      }}
                      className="mx-2 bg-orange px-6 w-full  h-fit py-1 shadow-md  rounded-sm"
                    >
                      Scan Item
                    </button>
                  </div>
                }
                submit={onOkClick}
                inputTextPlaceHolder={"Bar Code"}
                inputTextValue={
                  barCode
                    ? barCode
                    : itemData?.ALIAS_CODE
                    ? itemData.ALIAS_CODE
                    : ""
                }
                onChangeInputText={(e) => {
                  setBarCode(e.target.value);
                }}
              />
            </>
          }
        </div>
        {summaryList[0] && width > 500 && (
          <ItemStatusData
            img={img}
            summaryList={summaryList}
            width={width}
            setPurchasehelpStatus={setItemWisePurchaseHistoryHelpStatus}
            offerCart={offerCart}
          />
        )}
      </div>

      <BarcodeScanner
        onChange={(bar) => {
          getItemByAlios(prefix, bar, token, dispatch).then((icodeList) => {
            setBarCode(bar);
            setItemData(icodeList[0]);
            if (icodeList[0]) {
              GetItemWiseSaleReport(
                {
                  table_prefix: prefix,
                  ICODE: icodeList[0].ICODE,
                },
                token,
                dispatch,
                setSummaryList,
                setFullWidthModalStatus
              );
            }
          });
        }}
        setStatus={setBarCodeStatus}
        status={barCodeStatus}
      />
      <ItemMasterHelp
        status={itemHelpStatus}
        onClose={() => setItemHelpStatus(false)}
        onRowClick={(p) => {
          setItemData(p.row);
          setBarCode("");
          setSummaryList([]);
          setItemHelpStatus(false);
        }}
      />

      {width <= 500 && (
        <FullWidthModal
          data={summaryList}
          status={fullWidthModalStatus}
          handleClose={() => {
            setFullWidthModalStatus(false);
            setItemData("");
            setBarCode("");
            setSummaryList("");
          }}
          offerCart={offerCart}
          ItemDataUi={ItemStatusData}
          img={img}
        />
      )}

      <ItemWisePurchaseHistoryHelp
        status={itemWisePurchaseHistoryHelpStatus}
        onClose={() => {
          setItemWisePurchaseHistoryHelpStatus(false);
        }}
        ICODE={summaryList[0] ? summaryList[0].ICODE : ""}
      />
    </div>
  );
};

export default SaleTaxReport;
function ItemStatusData({
  img,
  summaryList,
  width,
  setPurchasehelpStatus,
  offerCart,
}) {
  const [selectedFile, setSelectedFile] = useState("");

  return (
    <div
      style={
        width > 500
          ? {
              maxWidth: 400,
              border: "1px solid gray",
            }
          : {
              width: "100%",
            }
      }
      className=" h-full  rounded-sm mt-2 py-3  "
    >
      <div className="flex justify-center mb-4">
        <div className="flex">
          <div className="flex justify-center border shadow-sm rounded-[10px] h-[100px] w-[100px] bg-gray-200">
            {img && (
              <img
                src={img}
                alt="Selected"
                className="h-full w-full rounded-[10px]"
              />
            )}
          </div>
        </div>
      </div>

      <InputValues title={"Item Code"} value={summaryList[0]?.ICODE} />
      <InputValues title={"Bar Code"} value={summaryList[0]?.ALIAS_CODE} />
      <InputValues title={"Bal. Stock"} value={summaryList[0]?.BAL_STOCK} />
      <InputValues
        title={"Expiry Date"}
        value={
          summaryList[0]?.EXP_DATE
            ? moment(summaryList[0]?.EXP_DATE).format("YYYY-MM-DD")
            : ""
        }
      />
      <InputValues
        title={"Last Pur. Price"}
        value={
          summaryList[0]?.PUR_PRICE
            ? FormatNumber2(summaryList[0]?.PUR_PRICE)
            : 0
        }
      />
      <InputValues
        title={"Current Sale Price"}
        value={
          summaryList[0]?.SALE_PRICE
            ? FormatNumber2(summaryList[0]?.SALE_PRICE)
            : 0
        }
      />
      <InputValues
        title={"CGST%"}
        value={
          summaryList[0]?.TAX1_RATE ? summaryList[0]?.TAX1_RATE + " %" : ""
        }
      />
      <InputValues
        title={"SGST%"}
        value={
          summaryList[0]?.TAX2_RATE ? summaryList[0]?.TAX2_RATE + " %" : ""
        }
      />
      <InputValues title={"Party Name"} value={summaryList[0]?.NAME} />
      {offerCart && (
        <InputValues
          style={{
            color: "red",
            cursor: "pointer",
            borderTop: "1px solid #e3e1e0",
            marginTop: "12px",
          }}
          title={"Offer Price"}
          value={FormatNumber2(offerCart.OFFER_SALE_PRICE)}
        />
      )}
      {offerCart && (
        <InputValues
          style={{
            color: "red",
            cursor: "pointer",
          }}
          title={"From"}
          value={moment(offerCart.FDATE).format("DD-MM-YYYY")}
        />
      )}
      {offerCart && (
        <InputValues
          style={{
            color: "red",
            cursor: "pointer",
          }}
          title={"To"}
          value={moment(offerCart.TDATE).format("DD-MM-YYYY")}
        />
      )}

      {/* {width >500 &&   <InputValues title={'Purchase History'} value={"Open"} style={{color:'green', cursor: 'pointer'}} onClick={()=>setPurchasehelpStatus(true)} />} */}
    </div>
  );
}
