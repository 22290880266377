import CallAPI from "../../utility/functions/getData";
import {
  setAlertWithTitle,
  setSpinnerLoading,
} from "../../utility/Redux/modal";

export const GetSaleSummary = (data, token, dispatch, setSummaryList) => {
  dispatch(setSpinnerLoading("loading"));
  CallAPI(
    "/api/saleReports/saleSummary",
    {
      ...data,
      ITEMNAME: "",
    },
    token,
    (e) => {
      if (!e.data.error) {
        if (e.data.response[0]) {
          const orderList = e.data.response.filter(
            (o) =>
              (o.ORDER_TYPE?.toLowerCase() === "pickup" ||
                o.ORDER_TYPE?.toLowerCase() === "delivery") &&
              o.BILL_STATUS.toLowerCase() !== "rejected"
          );
          setSummaryList(orderList);
        }
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Something Went Wrong.",
          })
        );
      }
    },
    dispatch,
    () => {
      dispatch(setSpinnerLoading(false));
    }
  );
};
