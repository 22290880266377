import React from "react";
import "./PaymentForm.css";
export const headerLabel = "text-[18px] font-[00] pb-3";
const PaymentForm = () => {
  return (
    <div className=" overflow-y-auto">
      {/* Payment Information Section */}
      <div className={headerLabel}> Customer info</div>
      <div className="grid grid-cols-3 gap-3 mb-[15px]">
        <div className="input-group2">
          <label>Mobile</label>
          <input type="text" placeholder="Jordan" />
        </div>
        <div className="input-group2">
          <label>Name</label>
          <input type="text" placeholder="Smith" />
        </div>
      </div>
      <div className="h-[1px] border-b mt-2"></div>
      <div className={headerLabel + " mt-3"}>Charges</div>
      <div className="grid grid-cols-3 gap-3 mb-[15px]">
        <div className="input-group2">
          <label>Handling Charges</label>
          <input type="number" placeholder="123" />
        </div>
        <div className="input-group2">
          <label>Delivery Charges</label>
          <input type="number" placeholder="123" />
        </div>
      </div>
      <div className="h-[1px] border-b mt-2"></div>
      <div className={headerLabel + " mt-3"}>Payment Info</div>
      <div className="grid grid-cols-3 gap-3 mb-[15px]">
        <div className="input-group2">
          <label>Cash Amount</label>
          <input type="text" placeholder="123" />
        </div>
        <div className="input-group2">
          <label>eAmount</label>
          <input type="text" placeholder="123" />
        </div>
        <div className="input-group2">
          <label>Card Amout</label>
          <input type="number" placeholder="123" />
        </div>
        <div className="input-group2">
          <div className="input-group2">
            <label>Card number</label>
            <input type="text" placeholder="1234 **** **** ****" />
          </div>
        </div>
        <div className="input-group2">
          <label>BTC Amount</label>
          <input type="text" placeholder="123" />
        </div>
        <div className="input-group2">
          <label>BTC Name</label>
          <input type="text" placeholder="123" />
        </div>

        <div className="input-group2">
          <label>Coupan Amount</label>
          <input type="text" placeholder="123" />
        </div>
        <div className="input-group2">
          <label>Coupan Number</label>
          <input type="text" placeholder="123" />
        </div>
      </div>

      {/* Actions Section */}
      <div className="form-actions">
        <button type="button" className="cancel-btn">
          Cancel
        </button>
        <button type="submit" className="continue-btn">
          Continue
        </button>
      </div>
    </div>
  );
};

export default PaymentForm;
