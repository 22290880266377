import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import { CallAPIPromise } from "../../billing/common";

export const saveSUbSectionMasterApi = (
  setSubSecFoc,
  secName,
  rows,
  dispatch,
  listSectionMasterApi,
  prefix,
  id,
  setId,
  setRows,
  token,
  setSecName,
  afterCreate,
  linkTo
) => {
  if (secName && linkTo) {
    const isDuplicateMobile = rows.some((row) => row.SUB_SEC_NAME === secName);

    if (isDuplicateMobile && !id) {
      dispatch(
        setAlertWithTitle({
          title: "Information Error!",
          msg: "This Sub. Section is already exists. Please choose a different.",
        })
      );
      setSecName("");
      setSubSecFoc();
      return;
    }

    const data = {
      table_prefix: prefix,
      SUB_SEC_NAME: secName,
      CODE: id ? id : "",
      SECTION_NAME: linkTo ? linkTo : "",
    };

    id
      ? dispatch(setSpinnerLoading("Updating"))
      : dispatch(setSpinnerLoading("Saving"));
    CallAPIPromise(`/api/master/saveSubSectionMaster`, data, token, dispatch)
      .then(async function (response) {
        if (!response.data.error) {
          id
            ? dispatch(
                setAlertWithTitle({
                  title: "Success.",
                  msg: "Data Updated successfully.",
                  lottie: "success",
                })
              )
            : dispatch(
                setAlertWithTitle({
                  title: "Success.",
                  msg: "Data Saved successfully.",
                  lottie: "success",
                })
              );
          listSectionMasterApi();
          if (afterCreate) {
            afterCreate();
          }
        } else {
          dispatch(
            setAlertWithTitle({
              title: "Error 112",
              msg: response.data.error,
            })
          );
          console.log(!response.data.error);
        }
      })
      .catch(function (error) {
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        } else {
          dispatch(
            setAlertWithTitle({
              title: "ERROR",
              msg: "Something went wrong.",
              lottie: "reject",
            })
          );
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        setSecName("");
        if (setId) {
          setId("");
        }
      });
  } else {
    if (!secName) {
      dispatch(
        setAlertWithTitle({
          title: "Alert",
          msg: "Please Enter Sub. Section Name.",
        })
      );
      setSubSecFoc();
    } else {
      if (!linkTo) {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Please select Section Name.",
          })
        );
      }
    }
  }
};
