import React from "react";
import Barcode from "react-barcode";

const BarcodeGenerator = ({ value }) => {
  return (
    <div className="barcode-container">
      {/* Render Barcode */}
      <Barcode
        value={value}
        format="CODE128"
        width={2} // Width of each bar
        height={60} // Height of the barcode
        displayValue={false} // Hide text below barcode
        margin={10} // Margin around barcode
        renderer="svg" // Use SVG renderer for high-quality output
      />
      {/* Render Text Below the Barcode */}
      <div className="text-center">
        <span style={{ fontSize: "16px", fontWeight: "500" }}>{value}</span>
      </div>
    </div>
  );
};

BarcodeGenerator.defaultProps = {
  value: "", // Example default barcode value
};

export default BarcodeGenerator;
