import React from "react";
import { useTable } from "react-table";
import "../styles/purchasePrint.css";
const PurchasePrint = ({ columns, data, footer, customeHeader, marginTop }) => {
  return (
    <div
      id="main"
      style={{
        width: columns.reduce((acc, row) => acc + Number(row.width), 0) + 50,
        border: "1px solid #888c89",
        height: "100%",
        backgroundColor: "white",
      }}
      className="flex flex-col items-center"
    >
      <div
        id="print-header"
        style={{
          width: columns.reduce((acc, row) => acc + Number(row.width), 0),
        }}
        className="  items-center  my-3"
      >
        <div
          style={{ width: "100%" }}
          className="flex justify-center items-center   "
        >
          {customeHeader && customeHeader}
        </div>
      </div>
    </div>
  );
};

export default PurchasePrint;
