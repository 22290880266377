import { useDispatch, useSelector } from "react-redux";
import { setSessionExpired, setSpinnerLoading } from "../utility/Redux/modal";
import { useState } from "react";
import { selectToken } from "../utility/Redux/security";
import { useEffect } from "react";
import { selectPrefix, selectWidth } from "../utility/Redux/profile";
import MasterHelp from "../utility/component/modals/masterHelp";
import { FormatNumber2 } from "../utility/functions/formatNumber";
import moment from "moment";
import { CallAPIPromise } from "../billing/common";

const ItemWisePurchaseHistoryHelp = ({
  onRowClick,
  status,
  onClose,
  searchText,
  ICODE,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "80%",
    width: "auto",
    padding: "2px",
    borderRadius: "8px",
    overflow: "hidden",
    backgroundColor: "white",
  };
  const [rows, setRows] = useState([]);
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);

  const width = useSelector(selectWidth);
  const columns = [
    {
      field: "DATE",
      accessor: "DATE",
      type: "date",
      headerAlign: "center",

      width: 90,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {params.value ? moment(params.value).format("DD-MM-YYYY") : ""}
          </div>
        );
      },
      renderHeader: (params) => <div style={{ fontSize: 13 }}>Date</div>,
    },

    {
      field: "NAME",
      accessor: "NAME",
      type: "string",
      headerAlign: "center",

      width: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {params.value ? params.value : ""}
          </div>
        );
      },
      renderHeader: (params) => <div style={{ fontSize: 13 }}>Party</div>,
    },

    {
      field: "ITEMNAME",
      accessor: "ITEMNAME",
      type: "number",
      renderCell: (param) => {
        return <div>{param.value}</div>;
      },
      headerClassName: "super-app-theme--header",
      width: 120,
      headerAlign: "center",
      align: "left",

      renderHeader: (params) => (
        <div className="  whitespace-normal" style={{ fontSize: 13 }}>
          {" "}
          Item Name
        </div>
      ),
    },

    {
      field: "QNTY",
      accessor: "QNTY",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 50,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  `}>
            {param.value}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="  pr-4 text-center" style={{ fontSize: 13 }}>
          Qnty
        </div>
      ),
    },
    {
      field: "PUR_PRICE",
      accessor: "PUR_PRICE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 80,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value !== "0" ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="  text-center" style={{ fontSize: 13 }}>
          Purchase Price
        </div>
      ),
    },

    {
      field: "DISQ_AMT",
      accessor: "DISQ_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 60,
      align: "right",
      renderCell: (param) => {
        return <div>{param.value !== 0 ? FormatNumber2(param.value) : ""}</div>;
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="  text-center" style={{ fontSize: 13 }}>
          Dicount
        </div>
      ),
    },

    {
      field: "TAX1_RATE",
      accessor: "TAX1_RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 50,
      align: "right",
      renderCell: (param) => {
        return (
          <div>{param.value !== "0" ? FormatNumber2(param.value) : "0"}</div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="  text-center" style={{ fontSize: 13 }}>
          CGST%
        </div>
      ),
    },

    {
      field: "TAX2_RATE",
      accessor: "TAX2_RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 50,
      align: "right",
      renderCell: (param) => {
        return <div>{param.value}</div>;
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="  text-center" style={{ fontSize: 13 }}>
          SGST %
        </div>
      ),
    },

    {
      field: "NET_AMOUNT",
      accessor: "NET_AMOUNT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 80,
      align: "right",
      renderCell: (param) => {
        return <div>{param.value ? FormatNumber2(param.value) : "0"}</div>;
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="  pr-4 text-center" style={{ fontSize: 13 }}>
          Net Amount
        </div>
      ),
    },
  ];

  const [searchQuery, setSearchQuery] = useState(searchText ? searchText : "");
  const [fullList, setFullList] = useState([]);
  useEffect(() => {
    const searchT = searchQuery;
    if (searchT) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchT.substring(0, 1))) {
          return item.MOBILE.toString().includes(searchT);
        } else {
          return item.NAME.toLowerCase().includes(searchT.toLowerCase());
        }
      });
      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery, searchText]);

  const dispatch = useDispatch();

  const getData = async () => {
    dispatch(setSpinnerLoading("Loading"));
    const data = {
      table_prefix: prefix,
      ICODE: ICODE ? ICODE : "",
    };
    CallAPIPromise(
      "/api/purchaseReports/itemPurchaseHistory",
      data,
      token,
      dispatch
    )
      .then((p) => {
        let list = [];
        const data = p.data.response;

        for (let i = 0; i < data.length; i++) {
          list.push({
            ...data[i],
            id: i + 1,
          });
        }

        setRows(list);

        setFullList(list);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    if (status && ICODE) {
      getData();
    }
  }, [status, ICODE]);

  useEffect(() => {
    setSearchQuery(searchText ? searchText : "");
  }, [searchText]);

  return (
    <>
      <div className="bg-white">
        <MasterHelp
          status={status}
          style={style}
          onClose={() => {
            onClose();
            setRows([]);
            setSearchQuery("");
          }}
          title={"Purchase History | Help"}
          columns={columns}
          list={rows}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onRowClick={(p) => {
            onRowClick(p);
            setRows([]);
            setSearchQuery("");
          }}
        />
      </div>
    </>
  );
};

export default ItemWisePurchaseHistoryHelp;
