import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import { CallAPIPromise } from "../../billing/common";

export const saveDepartmentMasr = (
  setDepartFocus,
  secName,
  departmentType,
  rows,
  dispatch,
  prefix,
  id,
  setId,
  token,
  setSecName,
  getData,
  afterCreate
) => {
  if (secName) {
    const isDuplicateMobile = rows.some((row) => row.DEP_NAME === secName);

    if (isDuplicateMobile) {
      setSecName("");
      setDepartFocus();
      dispatch(
        setAlertWithTitle({
          title: "Information Error!",
          msg: "This Department is already exists. Please choose a different.",
        })
      );
      return;
    }

    const data = {
      table_prefix: prefix,
      CODE: id ? id : "",
      DEP_NAME: secName,
      DEP_TYPE: departmentType,
    };

    id
      ? dispatch(setSpinnerLoading("Updating"))
      : dispatch(setSpinnerLoading("Saving"));
    CallAPIPromise(`/api/master/saveDepartmentMaster`, data, token, dispatch)
      .then(async function (response) {
        if (!response.data.error) {
          if (afterCreate) {
            afterCreate();
          }
          id
            ? dispatch(
                setAlertWithTitle({
                  title: "Success.",
                  msg: "Data Updated successfully.",
                  lottie: "success",
                })
              )
            : dispatch(
                setAlertWithTitle({
                  title: "Success.",
                  msg: "Data Saved successfully.",
                  lottie: "success",
                })
              );
          getData();
        } else {
          dispatch(
            setAlertWithTitle({
              title: "Error 112",
              msg: response.data.error,
            })
          );
          console.log(!response.data.error);
        }
      })
      .catch(function (error) {
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
        console.log(error);
        dispatch(
          setAlertWithTitle({
            title: "ERROR",
            msg: "Something went wrong.",
            lottie: "reject",
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        setSecName("");
        setId("");
      });
  } else {
    if (!secName) {
      dispatch(
        setAlertWithTitle({
          title: "Alert",
          msg: "Please Enter Department Name",
        })
      );
      setDepartFocus();
    }
  }
};
