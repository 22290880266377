import moment from "moment";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCompanyAddress,
  selectDefultProfileSetting,
  selectPrefix,
} from "../utility/Redux/profile";
import { selectToken } from "../utility/Redux/security";
import { FormatNumber2 } from "../utility/functions/formatNumber";
import { toProperCase } from "../utility/component/format";
import NumberToWords3 from "../utility/component/numberToFormat";
import CryptoJS from "crypto-js";
import SaveAmt from "./saveAmount";
import "../styles/print.css";
import BarcodeGenerator from "./barCodeGenerator";
import { QRCodeSVG } from "qrcode.react";
import { CallAPIPromise } from "./common";
const ViewBillImage = React.forwardRef((props, ref) => {
  const {
    setBillStatus,
    billNo,
    setOrderList,
    byHmac,
    companyData,
    tPrefix,
    isOriginalBill,
    data,
  } = props;
  const p = useSelector(selectPrefix);

  const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

  const prefix = tPrefix ? tPrefix : p;
  const billInvoice = billNo;
  const token = useSelector(selectToken);
  const [date, setDate] = useState();
  const [comanyPayMentLink, setCompanyPaymentLink] = useState("");
  const [list, setList] = useState([]);
  const comapnyAddress = useSelector(selectCompanyAddress);
  const userDefaultSetting = useSelector(selectDefultProfileSetting);
  let address = "";
  let city = "";
  let companyName = "";
  let companyWebsite = "";
  let email = "";
  let mobile = "";
  let state = "";
  if (comapnyAddress) {
    address = comapnyAddress.address;
    city = comapnyAddress.city;

    companyName = comapnyAddress.companyName;
    companyWebsite = comapnyAddress.companyWebsite;
    email = comapnyAddress.email;
    mobile = comapnyAddress.mobile;
    state = comapnyAddress.state;
  } else {
    address = companyData.address;
    city = companyData.city;

    companyName = companyData.companyName;
    companyWebsite = companyData.companyWebsite;
    email = companyData.email;
    mobile = companyData.mobile;
    state = companyData.state;
  }

  const hmac = CryptoJS.HmacSHA256(billInvoice, secretPass);
  // Convert the HMAC to a string and encode it using Base64
  const hmacString = hmac.toString(CryptoJS.enc.Base64);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${byHmac ? hmacString : token}`,
  };

  useEffect(() => {
    if (data[0]) {
      const dataList = data;
      setList(dataList);
      setDate(dataList[0].DATE);
      setBillStatus(dataList[0].BILL_STATUS);
      setOrderList(
        dataList.filter(
          (data) => data.QNTY > 0 && data.PRINT_ORDER_SLIP === "Yes"
        )
      );
    }
  }, [data]);

  const ReturnItemList = list[0] ? list.filter((e) => e.RQNTY > 0) : [];

  const totalTax = list.reduce(
    (acc, row) =>
      acc +
      Number(
        Number(row.TAX1_AMT) + Number(row.TAX2_AMT) + Number(row.TAX3_AMT)
      ),
    0
  );
  const totalDiscount = list.reduce(
    (acc, row) => acc + Number(row.DISQ_AMT),
    0
  );
  const dispatch = useDispatch();
  const getCompanyDetails = async () => {
    return new Promise((resolve, reject) => {
      const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

      const email = comapnyAddress.email;
      const hmac = CryptoJS.HmacSHA256(email, secretPass);
      const hmacString = hmac.toString(CryptoJS.enc.Base64);
      const data = {
        company_email: comapnyAddress.email,
        user_email: comapnyAddress.email,
        user_mobile: "",
        company_mobile: "",
      };

      CallAPIPromise(`/api/users/userCompanyInfo`, data, hmacString, dispatch)
        .then((res) => {
          if (res?.data[0]) {
            resolve(res.data[0]);
          } else {
            reject("");
          }
        })
        .catch(() => {
          reject();
        });
    });
  };

  useEffect(() => {
    if (userDefaultSetting?.PRINT_PAYMENT_QR_IN_BILL === "Yes") {
      getCompanyDetails().then((company) => {
        setCompanyPaymentLink(company.payment_qr_code);
      });
    }
  }, [userDefaultSetting]);
  return (
    <div
      id={"print-component"}
      className="h-auto w-[340px]  bg-white print-component    print:overflow-visible  "
      ref={ref}
    >
      <div className="w-full h-full ">
        <div className="w-full flex  text-[20px] justify-center font-semibold">
          {companyName}
        </div>
        <div className="flex justify-center">
          <div
            style={{ fontSize: 12 }}
            className="w-full flex justify-center max-w-[260px]  text-center "
          >
            {address}, {state},{city}, {mobile}
          </div>
        </div>

        <div
          style={{ fontSize: 12 }}
          className="w-full flex justify-center "
        ></div>
        <div style={{ fontSize: 12 }} className="flex">
          {" "}
          <div
            style={{ fontSize: 12 }}
            className="w-full flex justify-center pl-2"
          >
            {email}
          </div>{" "}
        </div>

        <div className="flex">
          {" "}
          <div
            style={{ fontSize: 12 }}
            className="w-full flex justify-center pl-2"
          >
            {companyWebsite}
          </div>{" "}
        </div>

        <div
          style={{ borderTopWidth: "1px" }}
          className="w-full    border-r-0 bordere-l-0 border-b-0 border-l-0  mt-2 border-dotted border-[#b5b3b3] "
        ></div>
        <div
          style={{ fontSize: 16 }}
          className="w-full text-sm mt-2  font-semibold flex items-center justify-center"
        >
          {" "}
          <div>RETAIL INVOICE</div>
        </div>
        <div className="flex justify-center mb-2 text-center text-[10px]">
          {isOriginalBill ? "" : "Duplicate Bill"}
        </div>
        <div className="w-full flex justify-between pb-1">
          <div className="w-full">
            <div
              style={{ fontSize: 12 }}
              className="w-full px-1 flex justify-between"
            >
              <div className="">Bill No. : {billInvoice}</div>
              <div style={{ fontSize: 12 }}>
                {" "}
                {date ? `Date: ${moment.utc(date).format("DD/MM/yyyy")}` : null}
              </div>
            </div>
            <div
              style={{ borderTopWidth: "1px" }}
              className="w-full    border-r-0 bordere-l-0 border-b-0 border-l-0  mt-2 border-dotted border-[#b5b3b3] "
            ></div>

            <div style={{ fontSize: 12 }} className={"mt-1 px-1"}>
              Bill Status : {list[0]?.BILL_STATUS}
            </div>
            {list[0]?.CUSTOMER_NAME && (
              <div style={{ fontSize: 12 }} className={"mt-1 px-1"}>
                Customer Name : {list[0]?.CUSTOMER_NAME}
              </div>
            )}

            {list[0]?.CUSTOMER_MOBILE && (
              <>
                <div style={{ fontSize: 12 }} className={"mt-1 px-1"}>
                  Mobile : {list[0]?.CUSTOMER_MOBILE}
                </div>
              </>
            )}
            {list[0]?.CUSTOMER_ADDRESS && (
              <div style={{ fontSize: 12 }} className={"mt-1 px-1"}>
                Address : {list[0]?.CUSTOMER_ADDRESS}
              </div>
            )}
            {list[0]?.CUSTOMER_EMAIL && (
              <div style={{ fontSize: 12 }} className={"mb-2 px-1"}>
                Email : {list[0]?.CUSTOMER_EMAIL}
              </div>
            )}
          </div>
        </div>

        <table className="w-full">
          <thead
            style={{ borderTopWidth: "1px", borderBottomWidth: "1px" }}
            className="w-full  border-black h-6"
          >
            <tr className="w-full   ">
              <th
                style={{
                  fontSize: 12,
                  borderRightWidth: "1px",
                  width: "30%",
                }}
                className=" whitespace-nowrap  border-black "
              >
                <div className="w-full flex justify-center">Item Name</div>
              </th>
              <th
                style={{
                  fontSize: 12,
                  borderRightWidth: "1px",
                  width: "18%",
                }}
                className="   border-black"
              >
                <div className="w-full flex justify-center"> Rate</div>
              </th>
              <th
                style={{
                  fontSize: 12,
                  borderRightWidth: "1px",
                  width: "13%",
                }}
                className="  border-black "
              >
                <div className="w-full flex justify-center"> Qnty</div>
              </th>
              <th
                style={{
                  fontSize: 12,
                  borderRightWidth: "1px",
                  width: "17%",
                }}
                className="  border-black "
              >
                <div className="w-full flex justify-center"> Return</div>
              </th>
              <th style={{ fontSize: 12, width: "22%" }}>
                <div className="w-full flex justify-center"> Amount</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.map((item, index) => {
              return (
                <tr key={index} style={{}}>
                  <td
                    className=" border-black border-dotted text-start "
                    style={{ fontSize: 10, borderRightWidth: "1px" }}
                  >
                    <div className="pl-1">{toProperCase(item.ITEMNAME)}</div>
                    {item.SALE_PRICE !== item.MRP_RATE && item.MRP_RATE > 0 && (
                      <div className="ml-1 italic">
                        {` [MRP : ${item.MRP_RATE}]`}
                      </div>
                    )}
                    {item.ITEM_INSTRUCTIONS && (
                      <div className="ml-1 italic text-[10px]">
                        {item.ITEM_INSTRUCTIONS}
                      </div>
                    )}
                    <div className="text-white h-[2px]"> </div>
                  </td>
                  <td
                    className=" mr-1  border-dotted border-black text-right "
                    style={{ fontSize: 10, borderRightWidth: "1px" }}
                  >
                    {FormatNumber2(item.SALE_PRICE)}
                  </td>
                  <td
                    className="  border-dotted text-right  border-black "
                    style={{ fontSize: 10, borderRightWidth: "1px" }}
                  >
                    {item.QNTY > 0 && item.QNTY}{" "}
                  </td>
                  <td
                    className=" pr-2 border-dotted text-right  border-black "
                    style={{ fontSize: 10, borderRightWidth: "1px" }}
                  >
                    {item.RQNTY > 0 && item.RQNTY}
                  </td>
                  <td className="text-right pr-2 " style={{ fontSize: 10 }}>
                    {FormatNumber2(item.AMOUNT)}
                  </td>
                </tr>
              );
            })}
            <tr style={{ borderTopWidth: "1px" }} className=" border-black ">
              <td
                colSpan="3"
                className=" border-black font-semibold border-dotted pr-2"
                style={{
                  textAlign: "right",
                  fontSize: 11,
                  borderRightWidth: "1px",
                }}
              >
                {list[0] &&
                  list.reduce(
                    (total, row) => Number(total) + Number(row.QNTY),
                    0
                  )}
              </td>
              <td
                className="pr-2 font-semibold "
                style={{
                  fontSize: 12,
                  textAlign: "right",
                  borderRight: "1px dotted black",
                }}
              >
                {list[0] &&
                  list.reduce(
                    (total, row) => Number(total) + Number(row.RQNTY),
                    0
                  )}
              </td>
              <td
                className="pr-2 font-semibold "
                style={{ fontSize: 12, textAlign: "right" }}
              >
                {list[0] &&
                  FormatNumber2(
                    list.reduce(
                      (total, row) => Number(total) + Number(row.AMOUNT),
                      0
                    )
                  )}
              </td>
            </tr>

            {/* <tr style={{ borderTopWidth: '1px' }} className=" border-black ">
              <td
                colSpan="4"
                className=" border-black font-semibold border-dotted pr-2"
                style={{
                  textAlign: 'right',
                  fontSize: 11,
                  borderRightWidth: '1px',
                }}
              >
                Total :
              </td>
              <td
                className="pr-2 font-semibold border-black"
                style={{ fontSize: 12, textAlign: 'right' }}
              >
                { list[0]&& FormatNumber2( list.reduce(
(total, row) =>
 Number(total) +
 Number(Number(row.AMOUNT) + Number(row.DISQ_AMT)),
0
) ) }
              </td>
            </tr> */}

            {ReturnItemList[0] && (
              <tr className=" border-black ">
                <td
                  colSpan="4"
                  className=" border-black font-semibold border-dotted pr-2"
                  style={{
                    textAlign: "right",
                    fontSize: 11,
                    borderRightWidth: "1px",
                  }}
                >
                  Return Amount :
                </td>
                <td
                  className="pr-2 font-semibold border-black"
                  style={{ fontSize: 12, textAlign: "right" }}
                >
                  {list[0]
                    ? FormatNumber2(
                        ReturnItemList.reduce(
                          (total, row) =>
                            Number(total) + Number(Number(row.AMOUNT)),
                          0
                        )
                      )
                    : ""}
                </td>
              </tr>
            )}

            {totalDiscount > 0 && (
              <tr style={{ borderTopWidth: "1px" }} className=" border-black ">
                <td
                  colSpan="4"
                  className=" border-black font-semibold border-dotted pr-2"
                  style={{
                    textAlign: "right",
                    fontSize: 11,
                    borderRightWidth: "1px",
                  }}
                >
                  Discount :
                </td>
                <td
                  className="pr-2 font-semibold border-black"
                  style={{ fontSize: 12, textAlign: "right" }}
                >
                  {FormatNumber2(totalDiscount)}
                </td>
              </tr>
            )}

            <tr className=" border-black ">
              <td
                colSpan="4"
                className=" border-black font-semibold border-dotted pr-2"
                style={{
                  textAlign: "right",
                  fontSize: 11,
                  borderRightWidth: "1px",
                }}
              >
                {list[0]?.SALE_TYPE.toLowerCase() === "inclusive"
                  ? "Inclusive of Tax :"
                  : "Tax :"}
              </td>
              <td
                className="pr-2 font-semibold border-black"
                style={{ fontSize: 12, textAlign: "right" }}
              >
                {FormatNumber2(totalTax)}
              </td>
            </tr>

            <tr className=" border-black ">
              <td
                colSpan="4"
                className=" border-black font-semibold border-dotted pr-2"
                style={{
                  textAlign: "right",
                  fontSize: 11,
                  borderRightWidth: "1px",
                }}
              >
                {"Round Off"}
              </td>
              <td
                className="pr-2 font-semibold border-black"
                style={{ fontSize: 12, textAlign: "right" }}
              >
                {list[0]?.ROUND_OFF}
              </td>
            </tr>
            {list[0]?.HANDLING_CHARGES && list[0]?.HANDLING_CHARGES > 0 ? (
              <tr className=" border-black ">
                <td
                  colSpan="4"
                  className=" border-black font-semibold border-dotted pr-2"
                  style={{
                    textAlign: "right",
                    fontSize: 11,
                    borderRightWidth: "1px",
                  }}
                >
                  {"Handling Charges"}
                </td>
                <td
                  className="pr-2 font-semibold border-black"
                  style={{ fontSize: 12, textAlign: "right" }}
                >
                  {FormatNumber2(list[0]?.HANDLING_CHARGES)}
                </td>
              </tr>
            ) : null}

            {list[0]?.DELIVERY_CHARGES && list[0]?.DELIVERY_CHARGES > 0 ? (
              <tr className=" border-black ">
                <td
                  colSpan="4"
                  className=" border-black font-semibold border-dotted pr-2"
                  style={{
                    textAlign: "right",
                    fontSize: 11,
                    borderRightWidth: "1px",
                  }}
                >
                  {"Delivery Charges"}
                </td>
                <td
                  className="pr-2 font-semibold border-black"
                  style={{ fontSize: 12, textAlign: "right" }}
                >
                  {FormatNumber2(list[0]?.DELIVERY_CHARGES)}
                </td>
              </tr>
            ) : null}

            <tr
              style={{ borderTopWidth: "1px", borderBottomWidth: "1px" }}
              className=" border-black "
            >
              <td
                colSpan="4"
                className=" border-black font-semibold border-dotted pr-2 "
                style={{
                  textAlign: "left",
                  fontSize: 11,
                  borderRightWidth: "1px",
                }}
              >
                <div className="flex justify-between items-center pl-1">
                  {date ? `${moment.utc(date).format("hh:mm A")}` : null}
                  <div className="text-[15px]">Grand Total</div>
                </div>
              </td>
              <td
                className="pr-2 font-semibold border-black text-[15px]"
                style={{ textAlign: "right" }}
              >
                {FormatNumber2(list[0]?.GTOTAL)}
              </td>
            </tr>
          </tbody>
        </table>

        <div
          style={{
            fontSize: 12,
          }}
          className={"w-full flex justify-center py-1 items-center text-center"}
        >
          {toProperCase(NumberToWords3(list[0]?.GTOTAL))}
        </div>
        <div
          style={{ borderTopWidth: "1px" }}
          className="w-full   border-dotted border-r-0 bordere-l-0 border-b-0 border-l-0  mt-1 border-black "
        ></div>
        <div
          style={{
            fontSize: 12,
          }}
          className={"w-full flex font-semibold justify-center items-center "}
        >
          Amount Received
        </div>

        <div className="flex w-full justify-center items-center ">
          {list[0]?.CASH_AMOUNT ? (
            <div
              style={{
                fontSize: 12,
              }}
              className={" flex justify-center"}
            >
              {" "}
              Cash Amount : {list[0]?.CASH_AMOUNT}
            </div>
          ) : null}

          {list[0]?.CARD_AMOUNT ? (
            <div
              style={{
                fontSize: 12,
              }}
              className={" flex justify-center"}
            >
              {list[0]?.CASH_AMOUNT ? <div className="px-2"> | </div> : null}
              Card Amount :
              {list[0]?.CARD_AMOUNT + " " + `[${list[0]?.CARD_NO}]`}
            </div>
          ) : null}
        </div>

        <div className="w-full flex justify-center items-center">
          {list[0]?.BTC_AMOUNT ? (
            <div
              style={{
                fontSize: 12,
              }}
              className={" flex justify-center"}
            >
              {" "}
              BTC Amount : {list[0]?.BTC_AMOUNT}
            </div>
          ) : null}
          {list[0]?.BTC_AMOUNT && list[0]?.E_AMOUNT ? (
            <div className="px-2">|</div>
          ) : null}

          {list[0]?.E_AMOUNT ? (
            <div
              style={{
                fontSize: 12,
              }}
              className={" flex justify-center"}
            >
              {" "}
              ePayment Amount : {list[0]?.E_AMOUNT}{" "}
            </div>
          ) : null}
        </div>

        <div className="w-full flex justify-center items-center ">
          {list[0]?.COUPAN_AMOUNT ? (
            <div
              style={{
                fontSize: 12,
              }}
              className={" flex justify-center"}
            >
              {" "}
              Coupon Amount : {list[0]?.COUPAN_AMOUNT}{" "}
            </div>
          ) : null}
        </div>

        <div className="flex text-[12px] font-semibold justify-center">
          {userDefaultSetting.PRINT_YOU_SAVED === "Yes" && (
            <SaveAmt list={list} />
          )}
        </div>

        <div
          style={{ borderTopWidth: "1px" }}
          className="w-full  border-dotted border-r-0 bordere-l-0 border-b-0 border-l-0  mt-2 border-black "
        ></div>

        <div
          style={{
            fontSize: 11,
          }}
          className={" flex text-center  justify-center mt-1 text-[#4e4a4a]"}
        >
          All Disputes Subjected to Jalandhar Jurisdiction.
        </div>

        <div
          style={{
            fontSize: 11,
          }}
          className={" flex justify-center text-[#4e4a4a]"}
        >
          Powered by geniusoffice .
        </div>

        <div className="flex justify-center my-2">
          {list[0] && userDefaultSetting.PRINT_BAR_CODE_IN_BILL === "Yes" && (
            <BarcodeGenerator
              value={`${prefix.replace(/_.+_/, " ") + list[0]?.INVNO}`}
            />
          )}
        </div>
        {list[0] &&
          comanyPayMentLink.includes("upi://") &&
          userDefaultSetting.PRINT_PAYMENT_QR_IN_BILL === "Yes" && (
            <div className="flex justify-center">
              <QRCodeSVG
                size={200}
                value={`${comanyPayMentLink}&am=${list[0]?.GTOTAL}`}
              />
            </div>
          )}
      </div>
    </div>
  );
});

export default ViewBillImage;
