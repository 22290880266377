import { FormatNumber2 } from "../../utility/functions/formatNumber";

export const SaleSummaryColumns = (width) => {
  const columns = [
    {
      field: "ICODE",
      accessor: "ICODE",

      type: "string",
      headerAlign: "center",
      width: width < 500 ? 40 : 80,
      headerClassName: "super-app-theme--header",

      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          {"Code"}{" "}
        </div>
      ),
    },

    {
      field: "ITEMNAME",
      accessor: "ITEMNAME",
      type: "string",
      headerAlign: "center",

      width: width < 500 ? 130 : 190,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className={`h-full w-full flex items-center  `}>
            {params.value}{" "}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          {"Item Name"}{" "}
        </div>
      ),
    },
    {
      field: "QNTY",
      accessor: "QNTY",
      type: "string",
      headerAlign: "center",

      width: width < 500 ? 40 : 80,
      headerClassName: "super-app-theme--header",

      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          {"Qnty"}{" "}
        </div>
      ),
    },

    {
      field: "BATCH",
      accessor: "BATCH",
      type: "string",
      width: width < 500 ? 50 : 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Batch
        </div>
      ),
    },
    {
      field: "BAL_STOCK",
      accessor: "BAL_STOCK",
      type: "number",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      headerClassName: "super-app-theme--header",
      width: width < 500 ? 55 : 70,
      headerAlign: "center",
      align: "left",

      renderHeader: (params) => (
        <div className="text-white  whitespace-normal" style={{ fontSize: 13 }}>
          {" "}
          Bal. Stock
        </div>
      ),
    },
    {
      field: "NAME",
      accessor: "NAME",
      type: "string",
      width: width < 768 ? 80 : width < 1400 ? 180 : 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Party Name
        </div>
      ),
    },
    {
      field: "MOBILE",
      accessor: "MOBILE",
      type: "string",
      width: width < 500 ? 70 : 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Mobile
        </div>
      ),
    },
    {
      field: "INVNO",
      accessor: "INVNO",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 500 ? 50 : 90,
      align: "left",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value ? FormatNumber2(param.value) : ""}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Invoice
        </div>
      ),
    },
    {
      field: "EXP_DATE",
      accessor: "EXP_DATE",
      type: "date",
      headerClassName: "super-app-theme--header",
      width: width < 500 ? 70 : 100,
      align: "right",

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          Expiry
        </div>
      ),
    },
  ];
  const mobileColumns = [
    {
      field: "ICODE",
      accessor: "ICODE",

      type: "string",
      headerAlign: "center",
      width: width < 500 ? 40 : 80,
      headerClassName: "super-app-theme--header",

      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          {"Code"}{" "}
        </div>
      ),
    },

    {
      field: "ITEMNAME",
      accessor: "ITEMNAME",
      type: "string",
      headerAlign: "center",

      width: width < 500 ? 130 : 190,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className={`h-full w-full flex items-center  `}>
            {params.value}{" "}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          {"Item Name"}{" "}
        </div>
      ),
    },

    {
      field: "BATCH",
      accessor: "BATCH",
      type: "string",
      width: width < 500 ? 50 : 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Batch
        </div>
      ),
    },

    {
      field: "EXP_DATE",
      accessor: "EXP_DATE",
      type: "date",
      headerClassName: "super-app-theme--header",
      width: width < 500 ? 70 : 100,
      align: "right",

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          Expiry
        </div>
      ),
    },
  ];
  return width < 500 ? mobileColumns : columns;
};
