import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";

import moment from "moment";
import { selectWidth } from "../utility/Redux/profile";
import { useFocus } from "../utility/hooks/hooks";
import { selectToken } from "../utility/Redux/security";
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from "../utility/Redux/modal";
import GeniustextInput from "../utility/component/mastertext";
import {
  DeleteButton,
  ModifyButton,
  SaveButton,
  UndoButton,
} from "../utility/component/buttons";
import AlertModalTwoButton from "../utility/component/modals/alertWithTwoButton";
import ModalHeaderStyle from "../utility/component/styles/modalHeader";
import CompanyHelp from "../help/companyHelp";
import OtpAlert from "../utility/component/modals/otpAlert.js";
import { CallAPIPromise } from "../billing/common.js";

const MessageSetupMaster = () => {
  const headerRef = useRef();
  const navigate = useNavigate();
  const [otpAlertStatus, setOtpAlertStatus] = useState(false);
  const [CompanyHelpStatus, setCompanyHelpStatus] = useState(false);
  const windowWidth = useSelector(selectWidth);
  const dispatch = useDispatch();
  const [messagerType, setmessagetype] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [tempId, setTempId] = useState("");
  const [whatsappNo, setwhatsappNo] = useState("");
  const [msgId, setMsgId] = useState("");
  const [WhatsAppCredit, setwhatsappCredit] = useState("");
  const [smsCredit, setSmsCredit] = useState("");
  const [accSid, setAccSid] = useState("");
  const [whatsappCreditUsed, setwhatsappCreditUsed] = useState("");
  const [smsCreditUsed, setsmsCreditUsed] = useState("");
  const [authToken, setAuthToken] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [validTill, setValidTill] = useState("");
  const [otp, setOtp] = useState("1234");
  const [emailRef, setEmailError] = useFocus();
  const [whatsappRef, setWhatsappError] = useFocus();
  const [tempRef, setTempError] = useFocus();
  const [msgIdRef, setMsgIdError] = useFocus();
  const [accSidRef, setAccSidError] = useFocus();
  const [authTokenRef, setAuthTokenError] = useFocus();
  const [issueDateRef, setIssueDateError] = useFocus();
  const [validTillRef, setValidTillError] = useFocus();

  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: "",
    msg: "",
    status: false,
    setStatus: "",
    button1: "",
    button1Click: "",
    button2: "",
    button2Click: "",
  });

  const [searchQuery, setSearchQuery] = useState("");
  const [fullList, setFulllist] = useState([]);

  const [rows, setRows] = useState([]);
  const columns = [
    {
      width: windowWidth <= 940 ? 80 : windowWidth <= 1156 ? 150 : 160,
      headerAlign: "center",
      align: "left",
      field: "company_email",
      renderHeader: (params) => (
        <div className="text-[12px]">Company Email</div>
      ),
    },

    {
      width: windowWidth <= 940 ? 50 : windowWidth <= 1156 ? 120 : 125,
      headerAlign: "center",
      align: "left",
      field: "whatsapp_number",
      renderHeader: (params) => <div className="text-[12px]">WhatsApp No.</div>,
    },
    {
      width: windowWidth <= 940 ? 30 : windowWidth <= 1156 ? 140 : 140,
      headerAlign: "center",
      align: "left",
      field: "whatsapp_credits",
      renderHeader: (params) => (
        <div className="text-[12px]">WhatsApp Credit</div>
      ),
    },
    {
      width: windowWidth <= 940 ? 30 : windowWidth <= 1156 ? 140 : 150,
      headerAlign: "center",
      align: "left",
      field: "whatsapp_cr_used",
      renderHeader: (params) => (
        <div className="text-[12px]">WhatsApp Cr. Used</div>
      ),
    },
    {
      width: windowWidth <= 940 ? 30 : windowWidth <= 1156 ? 100 : 120,
      headerAlign: "center",
      align: "left",
      field: "sms_credits",
      renderHeader: (params) => <div className="text-[12px]">SMS Credit</div>,
    },
    {
      width: windowWidth <= 940 ? 30 : windowWidth <= 1156 ? 100 : 120,
      headerAlign: "center",
      align: "left",
      field: "sms_cr_used",
      renderHeader: (params) => <div className="text-[12px]">SMS Cr. Used</div>,
    },
  ];

  const columnsMobile = [
    {
      width: 150,
      headerAlign: "center",
      align: "left",
      field: "company_email",
      renderHeader: (params) => (
        <div className="text-[12px]">Company Email</div>
      ),
    },

    {
      width: 90,
      headerAlign: "center",
      align: "left",
      field: "whatsapp_credits",
      renderHeader: (params) => <div className="text-[12px]">Watsapp Cr.</div>,
    },
    {
      width: 60,
      headerAlign: "center",
      align: "left",
      field: "sms_credits",
      renderHeader: (params) => <div className="text-[12px]">SMS Cr.</div>,
    },
  ];

  const clearState = () => {
    setCompanyEmail("");
    setTempId("");
    setwhatsappNo("");
    setMsgId("");
    setwhatsappCredit("");
    setSmsCredit("");
    setAccSid("");
    setwhatsappCreditUsed("");
    setsmsCreditUsed("");
    setAuthToken("");
    setIssueDate("");
    setValidTill("");
    setId("");
    setmessagetype("");
  };

  const token = useSelector(selectToken);

  const getMessageSetup = async () => {
    dispatch(setSpinnerLoading("Loading"));
    CallAPIPromise(
      "/api/userSettings/loadMessageSetup",
      {
        company_email: "",
      },
      token,
      dispatch
    )
      .then((p) => {
        if (!p.data.error) {
          const data = p.data.response;
          let rowList = [];

          for (let index = 0; index < data.length; index++) {
            rowList.push({
              ...data[index],
              id: index + 1,
            });
          }

          setRows(rowList);
          setFulllist(rowList);
        }
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    getMessageSetup();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.whatsapp_number.toString().includes(searchQuery);
        } else {
          return item.company_email
            .toLowerCase()
            .includes(searchQuery.toLowerCase());
        }
      });
      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);

  const [id, setId] = useState("");
  const saveAPI = () => {
    const data = {
      company_email: id ? companyEmail : companyEmail,
      whatsapp_number: whatsappNo,
      whatsapp_credits: WhatsAppCredit ? WhatsAppCredit : 0,
      sms_credits: smsCredit ? smsCredit : 0,
      sms_cr_used: smsCreditUsed ? smsCreditUsed : 0,
      whatsapp_cr_used: whatsappCreditUsed ? whatsappCreditUsed : 0,
      template_id: tempId,
      messanger_id: msgId,
      account_sid: accSid,
      auth_token: authToken,
      issue_date: issueDate,
      valid_till: validTill,
      message_type: messagerType,
    };

    CallAPIPromise(`/api/userSettings/messageSetup`, data, {
      token,
      dispatch,
    })
      .then(async function (response) {
        if (!response.data.error) {
          id
            ? dispatch(
                setAlertWithTitle({
                  title: "Success.",
                  msg: "Data Updated successfully.",
                  lottie: "success",
                })
              )
            : dispatch(
                setAlertWithTitle({
                  title: "Success.",
                  msg: "Data Saved successfully.",
                  lottie: "success",
                })
              );

          getMessageSetup();
        } else {
          dispatch(
            setAlertWithTitle({
              title: "Error 112",
              msg: response.data.error,
            })
          );
          console.log(!response.data.error);
        }
      })
      .catch(function (error) {
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        } else {
          dispatch(
            setAlertWithTitle({
              title: "ERROR",
              msg: "Something went wrong.",
              lottie: "reject",
            })
          );
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        clearState();
      });
  };

  const saveMessageSetupApi = () => {
    if (
      companyEmail &&
      whatsappNo &&
      tempId &&
      msgId &&
      accSid &&
      authToken &&
      issueDate &&
      validTill &&
      messagerType
    ) {
      id
        ? dispatch(setSpinnerLoading("Updating"))
        : dispatch(setSpinnerLoading("Saving"));
      saveAPI();
      // let code = '';
      // for (let i = 0; i < 4; i++) {
      //   code += Math.floor(Math.random() * 10); // generates a random digit from 0 to 9
      // }
      // setOtp(code);
      // setOtpAlertStatus(true);
      // SendOtp(7973974616, code, dispatch);
    } else {
      if (!validTill) {
        dispatch(
          setAlertWithTitle({
            title: "ERROR",
            msg: "Please Select Date Till Valid.",
            lottie: "reject",
          })
        );
        setValidTillError();
      }
      if (!messagerType) {
        dispatch(
          setAlertWithTitle({
            title: "ERROR",
            msg: "Please Select Message Type.",
            lottie: "reject",
          })
        );
        setValidTillError();
      }
      if (!issueDate) {
        dispatch(
          setAlertWithTitle({
            title: "ERROR",
            msg: "Please Select Issue Date.",
            lottie: "reject",
          })
        );
        setIssueDateError();
      }
      if (!authToken) {
        dispatch(
          setAlertWithTitle({
            title: "ERROR",
            msg: "Please Enter Auth Token.",
            lottie: "reject",
          })
        );
        setAuthTokenError();
      }
      if (!accSid) {
        dispatch(
          setAlertWithTitle({
            title: "ERROR",
            msg: "Please Enter Account SID.",
            lottie: "reject",
          })
        );
        setAccSidError();
      }
      if (!msgId) {
        dispatch(
          setAlertWithTitle({
            title: "ERROR",
            msg: "Please Enter Messanger ID.",
            lottie: "reject",
          })
        );
        setMsgIdError();
      }

      if (!whatsappNo) {
        dispatch(
          setAlertWithTitle({
            title: "ERROR",
            msg: "Please Enter WhatsApp Number.",
            lottie: "reject",
          })
        );
        setWhatsappError();
      }
      if (!tempId) {
        dispatch(
          setAlertWithTitle({
            title: "ERROR",
            msg: "Please Enter Template ID.",
            lottie: "reject",
          })
        );
        setTempError();
      }
      if (!companyEmail) {
        dispatch(
          setAlertWithTitle({
            title: "ERROR",
            msg: "Please Enter Company Email.",
            lottie: "reject",
          })
        );
        setEmailError();
      }
    }
  };

  const deleteMessageSetupApi = () => {
    const data = {
      company_email: companyEmail,
      message_type: messagerType,
    };

    dispatch(setSpinnerLoading("Deleting"));

    CallAPIPromise(`/api/userSettings/deleteMessageSetup`, data, {
      token,
      dispatch,
    })
      .then(async function (response) {
        if (!response.data.error) {
          dispatch(
            setAlertWithTitle({
              title: "Success.",
              msg: "Data Deleted successfully.",
              lottie: "success",
            })
          );
          getMessageSetup();
        } else {
          dispatch(
            setAlertWithTitle({
              title: "Error 112",
              msg: response.data.error,
            })
          );
          console.log(!response.data.error);
        }
      })
      .catch((error) => {
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        } else {
          dispatch(
            setAlertWithTitle({
              title: "ERROR",
              msg: "Something went wrong.",
              lottie: "reject",
            })
          );
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        clearState();
      });
  };

  const formRef = useRef(null);

  useEffect(() => {
    const inputFields = formRef.current.querySelectorAll("input, select");

    inputFields.forEach((field, index) => {
      field.addEventListener("keydown", (event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          const nextIndex = (index + 1) % inputFields.length;
          if (index === 2) {
            inputFields[4].focus();
          }
          if (index === 3) {
            inputFields[5].focus();
          }
          if (index === 11) {
            inputFields[0].focus();
          } else {
            inputFields[nextIndex].focus();
          }
        }
      });
    });

    return () => {
      inputFields.forEach((field) => {
        field.removeEventListener("keydown", () => {});
      });
    };
  }, []);

  return (
    <div className="w-full h-full flex justify-center bg-[#f5f5f7] px-4 py-4 overflow-y-scroll">
      <div className="text-sm  rounded-sm  h-full">
        <div ref={headerRef} className="bg-white   ">
          <ModalHeaderStyle
            header={"Message Setup Master"}
            onClose={() => {
              navigate("/settings");
            }}
          />
        </div>

        <div
          style={{
            height: headerRef.current
              ? `calc(100% - ${headerRef.current.clientHeight}px)`
              : "90%",
          }}
          className="flex flex-col  "
        >
          <div className="px-6 py-3 bg-white" ref={formRef}>
            <div className="t:flex ">
              <div className="grid grid-cols-1">
                <GeniustextInput
                  title={"Company Email"}
                  width={windowWidth <= 500 ? "155px" : "400px"}
                  autofocus={true}
                  type={"text"}
                  grid={true}
                  error={"email"}
                  value={companyEmail}
                  onChange={(e) => {
                    setCompanyEmail(e.target.value);
                  }}
                  search={() => {
                    setCompanyHelpStatus(true);
                  }}
                  refrance={emailRef}
                />

                <GeniustextInput
                  type={"mobile"}
                  grid={true}
                  title={"WhatsApp Number"}
                  width={windowWidth <= 500 ? "155px" : "400px"}
                  value={whatsappNo}
                  onChange={(e) => {
                    setwhatsappNo(e.target.value);
                  }}
                  refrance={whatsappRef}
                  error={"mobile"}
                />

                <div className="grid grid-cols-2 gap-2">
                  <GeniustextInput
                    type={"number"}
                    grid={true}
                    title={
                      windowWidth <= 500 ? "whatsapp Cr." : "WhatsApp Credit"
                    }
                    width={windowWidth <= 500 ? "100px" : "195px"}
                    value={WhatsAppCredit}
                    onChange={(e) => {
                      setwhatsappCredit(e.target.value);
                    }}
                  />
                  <GeniustextInput
                    type={"number"}
                    title={"SMS Credit"}
                    grid={true}
                    width={windowWidth <= 500 ? "100px" : "195px"}
                    value={smsCredit}
                    onChange={(e) => {
                      setSmsCredit(e.target.value);
                    }}
                  />
                </div>
                <div className="grid grid-cols-2 gap-2">
                  <GeniustextInput
                    type={"number"}
                    title={
                      windowWidth <= 500 ? "wApp Used" : "WhatsApp Credit Used"
                    }
                    grid={true}
                    width={windowWidth <= 500 ? "100px" : "195px"}
                    value={whatsappCreditUsed}
                    onChange={(e) => {
                      setwhatsappCreditUsed(e.target.value);
                    }}
                  />
                  <GeniustextInput
                    type={"number"}
                    title={
                      windowWidth <= 500 ? "SMS Cr. Used" : "SMS Credit Used"
                    }
                    grid={true}
                    width={windowWidth <= 500 ? "100px" : "195px"}
                    value={smsCreditUsed}
                    onChange={(e) => {
                      setsmsCreditUsed(e.target.value);
                    }}
                  />
                </div>

                <div className="grid grid-cols-2 gap-2">
                  <GeniustextInput
                    type={"date"}
                    grid={true}
                    title={"Issue Date"}
                    width={windowWidth <= 500 ? "155px" : "195px"}
                    value={issueDate}
                    onChange={(e) => {
                      setIssueDate(e.target.value);
                    }}
                    refrance={issueDateRef}
                  />
                  <GeniustextInput
                    type={"date"}
                    title={"Valid Till"}
                    grid={true}
                    width={windowWidth <= 500 ? "155px" : "195px"}
                    value={validTill}
                    onChange={(e) => {
                      setValidTill(e.target.value);
                    }}
                    refrance={validTillRef}
                  />
                </div>
              </div>
              <div className="t:ml-2">
                <div className="grid grid-cols-2 gap-2">
                  <GeniustextInput
                    width={windowWidth <= 500 ? "155px" : "200px"}
                    title={"Message Type"}
                    value={messagerType}
                    option={[
                      {
                        name: "Select Type",
                        value: "",
                      },
                      {
                        name: "Transactional",
                        value: "Transactional",
                      },
                      {
                        name: "Promotional",
                        value: "Promotional",
                      },
                      {
                        name: "Service Implicit",
                        value: "Service Implicit",
                      },
                      {
                        name: "Service Explicit",
                        value: "Service Explicit",
                      },
                    ]}
                    onChange={(e) => {
                      setmessagetype(e.target.value);
                    }}
                  />
                  <GeniustextInput
                    width={windowWidth <= 500 ? "155px" : "186px"}
                    type={"text"}
                    title={"Template ID"}
                    value={tempId}
                    onChange={(t) => {
                      setTempId(t.target.value);
                    }}
                    refrance={tempRef}
                  />
                </div>

                <GeniustextInput
                  width={windowWidth <= 500 ? "155px" : "400px"}
                  type={"text"}
                  grid={true}
                  title={"Messanger ID"}
                  placeholder={"MG33"}
                  value={msgId}
                  onChange={(e) => {
                    setMsgId(e.target.value);
                  }}
                  refrance={msgIdRef}
                />
                <GeniustextInput
                  width={windowWidth <= 500 ? "100px" : "400px"}
                  type={"text"}
                  title={"Account SID"}
                  grid={true}
                  value={accSid}
                  onChange={(e) => {
                    setAccSid(e.target.value);
                  }}
                  refrance={accSidRef}
                />
                <GeniustextInput
                  width={windowWidth <= 500 ? "100px" : "400px"}
                  type={"text"}
                  grid={true}
                  title={"Auth. Token"}
                  value={authToken}
                  onChange={(e) => {
                    setAuthToken(e.target.value);
                  }}
                  refrance={authTokenRef}
                />
                <div className="flex w-full justify-end h-[64px] items-end   max-sm:hidden">
                  {id ? (
                    <ModifyButton
                      onClick={() => {
                        saveMessageSetupApi();
                      }}
                    />
                  ) : (
                    <SaveButton
                      onClick={() => {
                        saveMessageSetupApi();
                      }}
                    />
                  )}
                  <UndoButton onClick={clearState} />
                  <DeleteButton onClick={deleteMessageSetupApi} />
                </div>
              </div>
            </div>

            <div className="flex"></div>

            <div className="flex w-full justify-between  items-center t:hidden">
              {id ? (
                <ModifyButton
                  onClick={() => {
                    saveMessageSetupApi();
                  }}
                />
              ) : (
                <SaveButton
                  onClick={() => {
                    saveMessageSetupApi();
                  }}
                />
              )}
              <UndoButton onClick={clearState} />
              <DeleteButton onClick={deleteMessageSetupApi} />
            </div>

            <div className="bg-white h-[250px] max-sm:mt-3">
              <DataGrid
                hideFooterPagination
                headerHeight={0}
                rowHeight={30}
                showCellRightBorder={true}
                showColumnRightBorder={true}
                hideFooter
                getRowId={(p) => p.id}
                onRowClick={(p) => {
                  setmessagetype(p.row.message_type);
                  setId(p.id);
                  setCompanyEmail(p.row.company_email);
                  setTempId(p.row.template_id);
                  setwhatsappNo(p.row.whatsapp_number);
                  setMsgId(p.row.messanger_id);
                  setwhatsappCredit(p.row.whatsapp_credits);
                  setSmsCredit(p.row.sms_credits);
                  setAccSid(p.row.account_sid);
                  setwhatsappCreditUsed(p.row.whatsapp_cr_used);
                  setsmsCreditUsed(p.row.sms_cr_used);
                  setAuthToken(p.row.auth_token);
                  setIssueDate(moment(p.row.issue_date).format("YYYY-MM-DD"));
                  setValidTill(moment(p.row.valid_till).format("YYYY-MM-DD"));
                  setSearchQuery("");
                }}
                components={{
                  Header: () => {
                    const headerDetails =
                      windowWidth <= 500 ? columnsMobile : columns;
                    return (
                      <div
                        className="  bg-[#383535] flex text-white text-lg "
                        style={{
                          borderTopLeftRadius: "3px",
                          borderTopRightRadius: "3px",
                          letterSpacing: "1px",
                        }}
                      >
                        {headerDetails?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="  text-white flex justify-center items-center py-2  "
                              style={
                                item.flex
                                  ? index < headerDetails.length - 1
                                    ? {
                                        flex: item.flex,
                                        borderRight: "1px solid #b5b3b3",
                                        borderBottom: "1px solid #b5b3b3",
                                      }
                                    : {
                                        flex: item.flex,
                                        borderBottom: "1px solid #b5b3b3",
                                      }
                                  : index < headerDetails.length - 1
                                  ? {
                                      width: item.width,

                                      borderRight: "1px solid #b5b3b3",
                                      borderBottom: "1px solid #b5b3b3",
                                    }
                                  : {
                                      width: item.width,
                                      borderBottom: "1px solid #b5b3b3",
                                    }
                              }
                            >
                              {item.renderHeader()}
                            </div>
                          );
                        })}
                      </div>
                    );
                  },
                  NoRowsOverlay: () => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        No Records Found
                      </div>
                    );
                  },
                }}
                sx={
                  window.innerWidth < 770
                    ? {
                        backgroundColor: "white",

                        fontSize: 12,
                        "& .super-app-theme--header": {
                          backgroundColor: "#f5f0e8",
                          display: "none",
                        },
                        borderTop: "1px solid #b5b3b3",
                        borderRadius: 0,
                      }
                    : {
                        backgroundColor: "white",
                        fontSize: 13,
                        borderTop: "1px solid #b5b3b3",
                        borderRadius: "3.5px",
                        "& .super-app-theme--header": {
                          backgroundColor: "#f5f0e8",
                          borderRight: `1px solid #b5b3b3`,

                          display: "none",
                        },
                      }
                }
                columns={windowWidth <= 500 ? columnsMobile : columns}
                rows={rows}
                pageSize={100}
              />
            </div>
            <div className="bg-white mt-3">
              <input
                type="search"
                className="bg-[#E6E6E3] focus:outline-none px-2"
                style={{
                  border: "1px solid #b5b3b3",
                  height: "38px",
                  borderRadius: "6px",
                  width: windowWidth <= 500 ? "320px" : "400px",
                }}
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
      <CompanyHelp
        status={CompanyHelpStatus}
        onClose={() => {
          setCompanyHelpStatus(false);
        }}
        onRowClick={(p) => {
          setCompanyEmail(p.row.company_email);
          setmessagetype("");
          setTempId("");
          setwhatsappNo("");
          setSmsCredit(0);
          setsmsCreditUsed(0);
          setwhatsappCreditUsed(0);
          setwhatsappCredit("");
          setMsgId("");
          setAccSid("");
          setId("");
          setAuthToken("");
          setIssueDate("");
          setValidTill("");
          setCompanyHelpStatus(false);
        }}
      />

      <OtpAlert
        status={otpAlertStatus}
        otp={otp}
        setOtpStatus={setOtpAlertStatus}
        onSubmit={saveAPI}
      />
    </div>
  );
};

export default MessageSetupMaster;
