import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectItemVarientsList,
  selectItemVarientsStatus,
  setItemVarientsStatus,
} from "../utility/Redux/modal";
import { Fade, Modal } from "@mui/material";
import { addItem } from "../billing/function";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "auto",
  borderRadius: "8px",
  overflow: "hidden",
  backgroundColor: "white",
};

const ItemVarientsHelp = ({
  setBillCartList,
  isStockCheck,
  setAlertTwoButtonModal,
  billingDetails,
  customerDetails,
}) => {
  const status = useSelector(selectItemVarientsStatus);
  const varientsList = useSelector(selectItemVarientsList);
  const dispatch = useDispatch();

  const [selectedIndex, setSelectedIndex] = useState(0); // To track the selected variant

  // Handle key events
  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setSelectedIndex((prevIndex) => (prevIndex + 1) % varientsList.length);
    } else if (e.key === "ArrowUp") {
      setSelectedIndex(
        (prevIndex) =>
          (prevIndex - 1 + varientsList.length) % varientsList.length
      );
    } else if (e.key === "Enter") {
      onSelectVarient(selectedIndex);
    }
  };

  useEffect(() => {
    if (status) {
      // Attach keydown event listener when modal is open
      window.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      // Cleanup the event listener when the modal is closed
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [status, selectedIndex, varientsList.length]);

  const onSelectVarient = (index) => {
    setSelectedIndex(index);
    addItem(
      setBillCartList,
      varientsList[index],
      false,
      1,
      isStockCheck,
      setAlertTwoButtonModal,
      customerDetails,
      varientsList[index],
      billingDetails
    );
    dispatch(setItemVarientsStatus(false));
  };

  return (
    <Modal
      open={status}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade
        in={status}
        style={{
          transitionDelay: status ? "100ms" : "0ms",
        }}
      >
        <div style={style}>
          <div className="flex py-2  border-b px-2 bg-[#f8f8f8] justify-between items-center">
            <div className="font-semibold">Multiple Rates - Items</div>
            <div>
              <button
                fontSize={"medium"}
                onClick={() => dispatch(setItemVarientsStatus(false))}
              >
                <i className="bi bi-x-square-fill text-[22px] text-red-500 font-bold p-1"></i>
              </button>
            </div>
          </div>

          <div className="text-sm font-semibold text-[#645c5c] py-2 px-4">
            {varientsList[0]?.ITEMNAME}
          </div>

          <div className="overflow-y-auto  pb-4 px-4">
            <div className="flex border  bg-[#f8f8f8] text-black rounded-t-sm">
              <div className="w-[100px]  border-r text-center   p-1">
                Sale Price
              </div>
              <div className="w-[100px] border-r text-center  p-1">Batch</div>
              <div className="w-[100px]  text-center  p-1">Bal. Qnty</div>
            </div>
            {varientsList.map((v, index) => (
              <div
                key={index}
                style={{
                  // border: '1px solid black',
                  backgroundColor:
                    selectedIndex === index ? "#f3e6b5" : "transparent",
                }}
                className={`  flex items-center hover:cursor-pointer bg-yellow-400  w-full  ${
                  selectedIndex === index ? "bg-yellow-500" : ""
                }`}
                onClick={() => {
                  onSelectVarient(index);
                }}
              >
                <div className="  flex border-l border-r">
                  <div className="w-[100px] border-b border-r flex justify-between  p-2 ">
                    <input
                      type="radio"
                      className="mr-2"
                      checked={selectedIndex === index}
                      readOnly
                    />
                    {v.SALE_PRICE}
                  </div>
                  <div className="w-[100px]  border-b  border-r text-center   p-2 ">
                    {v.BATCH}
                  </div>
                  <div className="w-[100px]  border-b   text-center   p-2 ">
                    {v.BAL_STOCK}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ItemVarientsHelp;
