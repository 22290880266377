import { Button } from "@mui/material";
import React, { useState } from "react";

const DashboadBox = ({
  i,
  e,
  setCompanyEmail,
  setCompanyInfoHelpStatus,
  setUserEmail,
}) => {
  const [liveUserCount, setLiveUserCount] = useState(0);

  return (
    <Button
      key={i}
      onClick={() => {
        setCompanyEmail(e.company_email);
        setUserEmail(e.user_email);
        setCompanyInfoHelpStatus(true);
      }}
      variant={"contained"}
      style={{
        textTransform: "none",
        backgroundColor: "transparent",
        padding: "0px",
        borderRadius: "7px",
        overflow: "hidden",
      }}
    >
      <div className="bg-[#e9e9e5] py-3 w-full whitespace-nowrap text-[#302d2d] px-3 ">
        <div className="w-full flex justify-start font-semibold text-brown">
          <i className="bi bi-briefcase-fill flex items-center mr-2 text-brown"></i>{" "}
          {e.company_name}
        </div>
        <div className="w-full flex justify-between">
          {" "}
          <div className="w-1/2  flex justify-start">
            <div>
              <i className="bi bi-people-fill mr-2 text-[#06521c80] "></i>
              {liveUserCount}
            </div>
          </div>
          <div>
            <i className="bi bi-people-fill mr-2 text-[#b4b4b1] "></i>
            {e.tusers}
          </div>
        </div>
      </div>
    </Button>
  );
};

export default DashboadBox;
