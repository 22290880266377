import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GeniustextInput from "../utility/component/mastertext";
import AlertModalTwoButton from "../utility/component/modals/alertWithTwoButton";
import ModalHeaderStyle from "../utility/component/styles/modalHeader";
import { useNavigate } from "react-router-dom";
import { CallAPIPromise } from "../billing/common";
import { selectToken } from "../utility/Redux/security";
import { setAlertWithTitle, setSpinnerLoading } from "../utility/Redux/modal";
import Lottie from "lottie-react";
import spinner from "../utility/json/Spinner.json";
const UserScopesMaster = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [newScope, setnewScope] = useState("");
  const [scopeList, setScopeList] = useState([]);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: "",
    msg: "",
    status: false,
    setStatus: "",
    button1: "",
    button1Click: "",
    button2: "",
    button2Click: "",
  });

  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getScopeList = () => {
    CallAPIPromise("/api/userSettings/listScope", {}, token, dispatch).then(
      (res) => {
        if (!res.data.error) {
          setScopeList(res.data.response);
        }
      }
    );
  };
  const saveNewScope = () => {
    dispatch(setSpinnerLoading("Saving"));
    CallAPIPromise(
      "/api/userSettings/saveSopeMaster",
      {
        USER_SCOPE: newScope,
      },
      token,
      dispatch
    )
      .then((res) => {
        dispatch(
          setAlertWithTitle({
            title: "Sucess",
            msg: "New Scope Added",
            lottie: "success",
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        getScopeList();
        setnewScope("");
      });
  };
  const formRef = useRef(null);

  useEffect(() => {
    getScopeList();
  }, []);

  return (
    <div
      ref={formRef}
      className="w-full  flex justify-center bg-[#f5f5f7] h-full py-3 "
    >
      <div className="text-sm min-w-[340px]  rounded-sm   flex flex-col   h-full px-3 ">
        <div>
          <ModalHeaderStyle
            header={"User Scopes Master"}
            onClose={() => {
              navigate("/settings");
            }}
          />
        </div>

        <div className="flex"></div>

        <div className="grid grid-cols-1 t:grid-cols-1 gap-2 rounded-b-sm  bg-white border-brown px-4 max-sm:px-2">
          <div className="flex-1 mt-4">
            <GeniustextInput
              title={"New Scope"}
              grid={true}
              width={windowWidth > 764 ? "250px" : "150px"}
              type={"text"}
              placeholder={"eg, read:orders"}
              value={newScope}
              onChange={(e) => setnewScope(e.target.value)}
            />

            <div className="flex justify-end">
              <button
                onClick={saveNewScope}
                className="flex px-3 py-1 rounded-md justify-start items-center text-white text-sm 
  bg-[#FF7F50] shadow-[0_4px_6px_rgba(0,0,0,0.1),0_1px_3px_rgba(0,0,0,0.06)] 
  hover:bg-[#FF4500] hover:shadow-[0_6px_8px_rgba(0,0,0,0.15),0_3px_5px_rgba(0,0,0,0.1)] 
  transition-all duration-200 ease-in-out"
              >
                <i className="bi  bi-plus text-[20px] mr-1"></i>
                Add
              </button>
            </div>
          </div>
          Scops List
          <div className=" w-full border p-2  ">
            {scopeList[0] ? (
              <div className="grid grid-cols-1 t:grid-cols-2 ll:grid-cols-3 gap-2 w-full  ">
                {scopeList.map((s, key) => {
                  return (
                    <div
                      key={key}
                      className="bg-gray-100 rounded-sm shadow-sm p-2 text-center"
                    >
                      {s.USER_SCOPE}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="h-full w-full flex items-center justify-center ">
                <Lottie
                  animationData={spinner}
                  loop={true}
                  style={{ fontSize: 15 }}
                  className="h-[50px]"
                />
              </div>
            )}
          </div>
          <div className="flex justify-end">
            {" "}
            <button
              className="flex px-7 py-[8px] rounded-md justify-start items-center text-black text-sm 
  bg-green shadow-[0_4px_6px_rgba(0,0,0,0.1),0_1px_3px_rgba(0,0,0,0.06)] 
  hover:bg-green hover:shadow-[0_6px_8px_rgba(0,0,0,0.15),0_3px_5px_rgba(0,0,0,0.1)] 
  transition-all duration-200 ease-in-out w-fit font-semibold  "
            >
              Save
            </button>
          </div>
          <AlertModalTwoButton
            details={alertTwoButtonModal}
            setAlertTwoButtonModal={setAlertTwoButtonModal}
          />
        </div>
      </div>
    </div>
  );
};

export default UserScopesMaster;
