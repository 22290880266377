import React, { useEffect } from "react";
import Modal from "@mui/material/Modal";
import Lottie from "lottie-react";
import sucess from "../../json/success2.json";
import reject from "../../json/rejected.json";
import {
  selectAlertWithTitleModalStatus,
  setAlertwithTitleModalStatus,
  selectAlertWithTitleDetails,
} from "../../Redux/modal";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../images/go_logo.svg";
import { AlertOkButton } from "../buttons";
import { Button } from "@mui/material";

export default function AlertModal2() {
  const dispatch = useDispatch();
  const alertStatus = useSelector(selectAlertWithTitleModalStatus);
  const alertDetails = useSelector(selectAlertWithTitleDetails);

  const handleClose = () => dispatch(setAlertwithTitleModalStatus(false));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    height: "auto",
    // border: '1px solid white',
    boxShadow: 24,
    borderRadius: "7px",
    outline: "none",
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();

        // 👇️ your logic here
        dispatch(setAlertwithTitleModalStatus(false));
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [dispatch]);
  return (
    <div>
      <Modal open={alertStatus} onClose={handleClose}>
        <div style={style}>
          {alertDetails.horizontal ? (
            <div className="min-w-[250px] max-w-[410px]">
              <div className="bg-white flex p-3 rounded-t-sm">
                <div className="h-8 rounded-[64px] w-8  flex items-center justify-center">
                  {/* <ReportProblemOutlined  /> */}
                  <i className="bi  text-[#f87f7f] text-[28px] justify-center itlgems-center flex bi-exclamation-circle-fill"></i>
                </div>

                <div className="ml-3">
                  <div className="font-semibold text-[16px]">
                    {alertDetails.title}
                  </div>
                  <div className=" text-[14px]  text-[#645f5f] mt-1 ">
                    {alertDetails.msg}
                  </div>
                </div>
              </div>
              <div className="flex justify-end p-2 bg-[#f2f4f7] rounded-b-sm">
                <div>
                  <Button
                    // ref={updateRef}
                    onClick={handleClose}
                    variant={"contained"}
                    style={{
                      textTransform: "none",
                      letterSpacing: "1px",
                      backgroundColor: "#f87f7f",
                      width: "100%",
                      marginLeft: 4,
                    }}
                  >
                    <div className="text-white   px-3 ">Ok </div>
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div
              className={
                "h-full min-w-[250px] max-w-[300px] bg-white  rounded-sm  p-3"
              }
            >
              <div className=" flex justify-center w-full rounded-sm">
                <div
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: -30,

                    borderRadius: 100,
                  }}
                  className="bg-white p-[2px]"
                >
                  {alertDetails.lottie ||
                  alertDetails?.msg.includes("wrong") ||
                  alertDetails?.msg.includes("success") ? (
                    <div className="w-full flex justify-center">
                      <Lottie
                        animationData={
                          alertDetails.lottie === "success" ||
                          alertDetails?.msg.includes("success")
                            ? sucess
                            : reject
                        }
                        loop={false}
                        size={100}
                        style={{ fontSize: 15, width: "45px" }}
                        color="white"
                      />
                    </div>
                  ) : (
                    <img
                      src={logo}
                      alt=""
                      style={{
                        width: "50px",
                        height: "auto",
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="w-full justify-center text-center text-[#1c1a1a] flex  font-semibold items-top">
                {alertDetails?.title}
              </div>
              <div
                style={{
                  fontSize: "13px",
                }}
                className="w-full justify-center text-center text-[#1c1a1a] flex mt-1 "
              >
                {alertDetails?.msg}
              </div>

              <div className="w-full flex justify-center mt-4">
                <AlertOkButton onClick={handleClose} />
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
