import { createSlice } from "@reduxjs/toolkit";
import CryptoJS from "crypto-js";
import moment from "moment";

const prefixEncrypted = localStorage.getItem("prefix");
const userTypeEncrypted = localStorage.getItem("userType");

const userName = localStorage.getItem("userName");
const companyAddress = JSON.parse(localStorage.getItem("companyDetails"));
const secretPass = process.env.REACT_APP_SECRET_KEY;

const getPrefix = () => {
  if (prefixEncrypted) {
    try {
      // Decrypt the token
      const bytes = CryptoJS.AES.decrypt(prefixEncrypted, secretPass);

      if (bytes.sigBytes < 0) {
        localStorage.removeItem("prefix");
        localStorage.removeItem("expiresAt");
      } else {
        // Convert decrypted bytes to a string (in UTF-8)
        const tk = bytes.toString(CryptoJS.enc.Utf8);

        // Check if the token string is valid
        if (!tk || tk === "") {
          throw new Error("Decrypted token is empty or invalid");
        }

        // Attempt to parse the token as JSON
        const tkn = JSON.parse(tk);
        return tkn;
      }
    } catch (error) {
      localStorage.clear();
      console.error("Error in getToken:", error);
      // Handle error: you may want to clear token data or redirect the user
      return null;
    }
  }

  return null;
};

const emailEncrpted = localStorage.getItem("email");

const devEncrpted = localStorage.getItem("dev");

const getDevMode = () => {
  if (devEncrpted) {
    const bytes = CryptoJS.AES.decrypt(devEncrpted, secretPass);
    if (bytes.sigBytes < 0) {
      localStorage.removeItem("prefix");
      localStorage.removeItem("expiresAt");
      localStorage.removeItem("dev");
    } else {
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      var devmode = data;

      return devmode;
    }
  }

  return null;
};

const getUserType = () => {
  if (userTypeEncrypted) {
    const bytes = CryptoJS.AES.decrypt(userTypeEncrypted, secretPass);
    if (bytes.sigBytes < 0) {
      localStorage.removeItem("prefix");
      localStorage.removeItem("expiresAt");
    } else {
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      var userType = data;

      return userType;
    }
  }

  return null;
};

const GuestDetails = () => {
  const Guest = localStorage.getItem("BillingGuestDetails");
  if (Guest) {
    return JSON.parse(Guest);
  } else {
    return {
      guestName: "",
      guestMobile: "",
      guestEmail: "",
    };
  }
};

const GetuserDefaultSetting = () => {
  const Guest = localStorage.getItem("userDefaultSetting");
  if (Guest) {
    return JSON.parse(Guest);
  } else {
    return {};
  }
};

const getReportsFromDate = () => {
  const fromDate = localStorage.getItem("fromDate");
  if (fromDate !== "undefined" && fromDate) {
    return moment(fromDate).format("YYYY-MM-DD");
  } else {
    return moment().format("YYYY-MM-DD");
  }
};

const getReportsToDate = () => {
  const toDate = localStorage.getItem("toDate");
  if (toDate !== "undefined" && toDate) {
    return moment(toDate).format("YYYY-MM-DD");
  } else {
    return moment().format("YYYY-MM-DD");
  }
};
const getemail = () => {
  if (emailEncrpted) {
    const bytes = CryptoJS.AES.decrypt(emailEncrpted, secretPass);
    if (bytes.sigBytes < 0) {
      localStorage.removeItem("prefix");
      localStorage.removeItem("expiresAt");
    } else {
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      var email = data;

      return email;
    }
  }

  return null;
};

const FinancialDetails = () => {
  const Financial = localStorage.getItem("financial");
  if (Financial) {
    return JSON.parse(Financial);
  } else {
    return {
      id: "",
      year: "",
      start_date: "",
      end_date: "",
      company_code: "",
      company_name: "",
      year_closed: "",
      name: "",
    };
  }
};
const currencyValue = localStorage.getItem("currency");
const isDev = localStorage.getItem("devloper");
const profileIntialState = {
  userName: userName,
  // prefix: "ssvs_20242025_",
  prefix: getPrefix(),
  email: getemail(),
  todayIssueStatus: false,
  companyAddress: companyAddress,
  userType: getUserType(),
  companyCity: "",
  billingGuest: GuestDetails(),
  billIngAccount: {
    accNumber: "",
    accMobile: "",
    accName: "",
  },
  pathHistory: [],
  firebaseStatusField: localStorage.getItem("statusField"),
  isFirebaseSocketOn: true,
  userCode: localStorage.getItem("user"),
  fromDate: getReportsFromDate(),
  toDate: getReportsToDate(),
  profileDefaultSetting: GetuserDefaultSetting(),
  latitude: "",
  longitude: "",
  shopUserType: "USER",
  shopUserName: "",
  pathList: [],
  tooltip: false,
  devloper: getDevMode(),
  shopUserMobile: "",
  currency: currencyValue,
  firesbaseSaveToken: false,
  isDevloper: isDev,
  isFullScreen: false,
  width: window.innerWidth,
  height: window.innerHeight,
  financialYearDetails: FinancialDetails(),
};

export const Profile = createSlice({
  name: "profile",
  initialState: profileIntialState,
  reducers: {
    setUserName(state, action) {
      state.userName = action.payload;
    },
    setToolTip(state, action) {
      state.tooltip = action.payload;
    },
    setPathHistory(state, action) {
      state.pathHistory = action.payload;
    },
    setFinancialYearDetails(state, action) {
      state.financialYearDetails = action.payload;
    },
    setFirebaseToken(state, action) {
      state.isFirebaseSocketOn = action.payload;
    },

    setfirebaseStatusField(state, action) {
      state.firebaseStatusField = action.payload;
      localStorage.setItem("statusField", action.payload);
    },
    setWidth(state, action) {
      state.width = action.payload;
    },
    setUserCode(state, action) {
      state.userCode = action.payload;
      localStorage.setItem("user", action.payload);
    },
    setreportsFromDate(state, action) {
      const v =
        action.payload && action.payload !== "undefined"
          ? action.payload
          : moment().format("YYYY-MM-DD");
      state.fromDate = v;
      localStorage.setItem("fromDate", v);
    },
    setAllowedPathList(state, action) {
      state.pathList = action.payload;
    },
    setreportsTodate(state, action) {
      const v =
        action.payload && action.payload !== "undefined"
          ? action.payload
          : moment().format("YYYY-MM-DD");
      state.toDate = v;

      localStorage.setItem("toDate", v);
    },

    setHeight(state, action) {
      state.height = action.payload;
    },
    setFullScreen(state, action) {
      state.isFullScreen = action.payload;
    },
    setDevloperMode(state, action) {
      state.isDevloper = action.payload;
    },
    setfirebaseSaveTokan(state, action) {
      state.firesbaseSaveToken = action.payload;
    },
    setdevloper(state, action) {
      state.devloper = action.payload;
    },
    setCurrency(state, action) {
      state.currency = action.payload;
      localStorage.setItem("currency", action.payload);
    },
    setshopUserMobile(state, action) {
      state.shopUserMobile = action.payload;
    },
    setShopUserName(state, action) {
      state.shopUserName = action.payload;
    },
    setShopUserType(state, action) {
      state.shopUserType = action.payload;
    },
    setemail(state, action) {
      state.email = action.payload;
    },
    setdefaultProfileSetting(state, action) {
      state.profileDefaultSetting = action.payload;
      localStorage.setItem(
        "userDefaultSetting",
        JSON.stringify({
          ...action.payload,
        })
      );
    },
    setTodayIssueStatus(state, action) {
      state.todayIssueStatus = action.payload;
    },
    setlatitudeRedux(state, action) {
      state.latitude = action.payload;
    },
    setlongitudeRedux(state, action) {
      state.longitude = action.payload;
    },
    setUserType(state, action) {
      state.userType = action.payload;
    },
    setCompanyAddress(state, action) {
      state.companyAddress = action.payload;
    },
    setBillingAccountDetails(state, action) {
      state.billIngAccount = action.payload;
    },

    setPrefix(state, action) {
      state.prefix = action.payload;
    },
    setBillingGuestDetails(state, action) {
      state.billingGuest = { ...action.payload };
      localStorage.setItem(
        "BillingGuestDetails",
        JSON.stringify({ ...action.payload })
      );
    },
    setCompany(state, action) {
      state.company = action.payload;
    },
    resetProfile(state, action) {
      state = profileIntialState;
    },
  },
});

export default Profile.reducer;

export const {
  setBillingGuestDetails,
  setPathHistory,
  setUserName,
  setDevloperMode,
  setfirebaseSaveTokan,
  setAllowedPathList,
  setWidth,
  setFirebaseToken,
  setfirebaseStatusField,
  setHeight,
  setToolTip,
  setdevloper,
  setFinancialYearDetails,
  setShopUserType,
  setUserCode,
  setShopUserName,
  setshopUserMobile,
  setemail,
  setCurrency,
  setreportsFromDate,
  setreportsTodate,
  setFullScreen,
  resetProfile,
  setTodayIssueStatus,
  setCompany,
  setlatitudeRedux,
  setlongitudeRedux,
  setPrefix,
  setUserType,
  setdefaultProfileSetting,
  setCompanyAddress,
  setBillingAccountDetails,
} = Profile.actions;
//test
export const selectUserName = (state) => state.profile.userName;
export const selectCompany = (state) => state.profile.company;
export const selectPrefix = (state) => state.profile.prefix;
export const selectGuestDetails = (state) => state.profile.billingGuest;
export const selectAccountDetails = (state) => state.profile.billIngAccount;
export const selectCompanyAddress = (state) => state.profile.companyAddress;
export const selectUserType = (state) => state.profile.userType;
export const selectLatitude = (state) => state.profile.latitude;
export const selectLongitude = (state) => state.profile.longitude;
export const selectDefultProfileSetting = (state) =>
  state.profile.profileDefaultSetting;
export const selectTodayIssueStatus = (state) => state.profile.todayIssueStatus;
export const selectEmail = (state) => state.profile.email;
export const selectShopUserType = (state) => state.profile.shopUserType;
export const selectShopUsername = (state) => state.profile.shopUserName;
export const selectShopUserMobile = (state) => state.profile.shopUserMobile;
export const selectCurrency = (state) => state.profile.currency;
export const seledctAlowedpathlist = (state) => state.profile.pathList;
export const selectSaveFirebaseTokan = (state) =>
  state.profile.firesbaseSaveToken;
export const selectDevloperMode = (state) => state.profile.isDevloper;
export const selectFullScreen = (state) => state.profile.isFullScreen;
export const selectWidth = (state) => state.profile.width;
export const selectHeight = (state) => state.profile.height;
export const selectreportFromDate = (state) => state.profile.fromDate;
export const selectreportToDate = (state) => state.profile.toDate;
export const selectIssueSID = (state) => state.profile.issueSID;
export const selectUserCode = (state) => state.profile.userCode;
export const selectToolTip = (state) => state.profile.tooltip;
export const selectFirebaseStatusField = (state) =>
  state.profile.firebaseStatusField;
export const selectISFirebaseSocketOn = (state) =>
  state.profile.isFirebaseSocketOn;
export const selectFinancialYearDetails = (state) =>
  state.profile.financialYearDetails;

export const selectPathHistory = (state) => state.profile.pathHistory;
