import { useDispatch, useSelector } from "react-redux";
import {
  setItemMasterHelpStatus,
  setSessionExpired,
  setSpinnerLoading,
} from "../utility/Redux/modal";
import { useState } from "react";
import { selectToken } from "../utility/Redux/security";
import { useEffect } from "react";
import { selectPrefix, selectWidth } from "../utility/Redux/profile";
import MasterHelp from "../utility/component/modals/masterHelp";
import { FormatNumber2 } from "../utility/functions/formatNumber";
import { CallAPIPromise } from "../billing/common";

const OfferHelp = ({ onRowClick, status, onClose }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "80%",
    width: "auto",
    padding: "2px",
    borderRadius: "8px",
    // Change the shadow color
    overflow: "hidden",
    backgroundColor: "white",
  };

  const [rows, setRows] = useState([]);
  const token = useSelector(selectToken);

  const prefix = useSelector(selectPrefix);

  const width = useSelector(selectWidth);
  const columns = [
    {
      width: 50,
      headerAlign: "center",
      align: "left",
      field: "SERIAL_NO",
      renderHeader: (params) => <div>Serial</div>,
    },
    {
      width: width < 500 ? 100 : 250,
      headerAlign: "center",
      align: "left",
      field: "ITEMNAME",
      renderHeader: (params) => <div>Item</div>,
    },

    {
      width: width < 500 ? 100 : 110,
      headerAlign: "center",
      align: "left",
      field: "OFFER_TYPE",
      renderHeader: (params) => <div>Type</div>,
    },
    {
      width: width < 500 ? 50 : 80,
      headerAlign: "center",
      align: "right",
      renderCell: (p) => {
        return <div>{p.value > 0 ? FormatNumber2(p.value) : ""}</div>;
      },
      field: "OFFER_SALE_PRICE",
      renderHeader: (params) => <div>Offer Price</div>,
    },
  ];

  const [searchQuery, setSearchQuery] = useState("");
  const [fullList, setFullList] = useState([]);

  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.CUSTOMER_MOBILE.toString().includes(searchQuery);
        } else {
          return item.CUSTOMER_NAME.toLowerCase().includes(
            searchQuery.toLowerCase()
          );
        }
      });

      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);

  const dispatch = useDispatch();

  const getData = async () => {
    dispatch(setSpinnerLoading("Loading"));
    const data = {
      table_prefix: prefix,
      FDATE: "",
      TDATE: "",
    };
    CallAPIPromise("/api/offerMaster/listOffer", data, token, dispatch)
      .then((p) => {
        if (!p.data.error) {
          let list = [];
          const data = p.data.response;

          for (let i = 0; i < data.length; i++) {
            list.push({
              ...data[i],
              id: i + 1,
            });
          }
          setFullList(list);
          setRows(list);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    if (status) {
      getData();
    }
  }, [status]);

  const handleClose = () => {
    dispatch(setItemMasterHelpStatus(false));
    setSearchQuery("");
  };

  return (
    <>
      <div className="bg-white">
        <MasterHelp
          status={status}
          style={style}
          onClose={() => {
            onClose();
            setRows([]);
            setSearchQuery("");
          }}
          title={"Offer | Help"}
          columns={columns}
          list={rows}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onRowClick={(p) => {
            onRowClick(p);
            setRows([]);
            setSearchQuery("");
          }}
        />
      </div>
    </>
  );
};

export default OfferHelp;
