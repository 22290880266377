import React, { useState } from "react";
import api from "./utility/functions/axios"; // Axios instance with interceptors
import Cookies from "js-cookie";

const inputClasses =
  "w-full mt-1 p-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 bg-white dark:bg-gray-800 text-gray-900 dark:text-white";
const labelClasses =
  "block text-sm font-semibold text-gray-700 dark:text-gray-300 mb-1";
const buttonClasses =
  "w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 px-4 rounded-lg transition-colors duration-200 flex items-center justify-center gap-2";

const LoginForm = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [protectedData, setProtectedData] = useState("");

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission (Login)
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setIsLoading(true);

    try {
      const response = await api.post("/login", {}, formData);
      const { accessToken } = response.data;
      localStorage.setItem("accessToken", accessToken);
      console.log("Login successful!");
    } catch (error) {
      console.error("Login failed:", error.response?.data || error.message);
      setErrorMessage(
        error.response?.data?.message || "Login failed. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Test Protected API
  const testProtectedApi = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await api.get("/protected", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setProtectedData(response.data.message || "Protected API success!");
    } catch (error) {
      console.error(
        "Protected API failed:",
        error.response?.data || error.message
      );
      setProtectedData(
        error.response?.data?.message || "Protected API failed. Check logs."
      );
    }
  };

  // Refresh Access Token
  const refreshAccessToken = async () => {
    try {
      // To get a cookie
      const refreshToken = Cookies.get("refreshToken");
      console.log("Refresh Token:", refreshToken);
      const response = await api.post("/api/users/refresh-token", {
        refreshToken: refreshToken,
      });
      console.log(response);
      const { accessToken } = response.data;
      localStorage.setItem("accessToken", accessToken);
      console.log("Token refreshed successfully!");
    } catch (error) {
      console.error(
        "Token refresh failed:",
        error.response?.data || error.message
      );
      setErrorMessage(
        error.response?.data?.message || "Token refresh failed. Check logs."
      );
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50 dark:bg-gray-900 px-4">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-xl shadow-2xl w-full max-w-md space-y-8">
        <div>
          <h2 className="text-3xl font-bold text-center text-gray-900 dark:text-white mb-2">
            Welcome Back
          </h2>
          <p className="text-center text-gray-600 dark:text-gray-400">
            Please sign in to your account
          </p>
        </div>

        {errorMessage && (
          <div className="p-4 bg-red-50 border border-red-200 rounded-lg">
            <p className="text-red-600 text-sm">{errorMessage}</p>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="username" className={labelClasses}>
              Username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              placeholder="Enter your username"
              className={inputClasses}
              value={formData.username}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="password" className={labelClasses}>
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Enter your password"
              className={inputClasses}
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className={buttonClasses} disabled={isLoading}>
            {true ? (
              <>
                <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                    fill="none"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
                Signing in...
              </>
            ) : (
              "Sign in"
            )}
          </button>
        </form>
      </div>

      <div className="mt-8 w-full max-w-md space-y-4">
        <button
          onClick={testProtectedApi}
          className={`${buttonClasses} bg-green-600 hover:bg-green-700`}
        >
          Test Protected API
        </button>
        <button
          onClick={refreshAccessToken}
          className={`${buttonClasses} bg-purple-600 hover:bg-purple-700`}
        >
          Refresh Access Token
        </button>
        {protectedData && (
          <div className="mt-4 p-4 bg-gray-100 dark:bg-gray-700 rounded-lg border border-gray-200 dark:border-gray-600">
            <p className="text-gray-800 dark:text-gray-200">{protectedData}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginForm;
