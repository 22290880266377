import CallAPI from "../../utility/functions/getData";
import {
  setAlertWithTitle,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import dataToLocal from "../../utility/component/dataToLocal";
import deleteWholeDatabase from "../../utility/functions/deleteWholeDatabase";

export const GetPurchaseSummary = (data, token, dispatch, setPurchaseList) => {
  dispatch(setSpinnerLoading("Getting purchase summary"));
  deleteWholeDatabase("partyItemsSale");
  CallAPI(
    "/api/purchaseReports/partyItemsSale",
    data,
    token,
    (e) => {
      if (!e.data.error) {
        {
          const res = e.data.response;
          if (res[0]) {
            setPurchaseList(res);
            dataToLocal("partyItemsSale", res, [
              {
                ...data,
                ICODE: 1,
              },
            ]);
          } else {
            dispatch(
              setAlertWithTitle({
                title: "Alert",
                msg: "No Records found",
              })
            );
          }
        }
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Something Went Wrong.",
          })
        );
      }
    },
    dispatch,
    () => {
      dispatch(setSpinnerLoading(false));
    }
  );
};
