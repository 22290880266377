import React, { useEffect, useState } from "react";
import { DownloadFile } from "../utility/functions/firebase";
import { useSelector } from "react-redux";
import { selectPrefix, selectWidth } from "../utility/Redux/profile";
import { toProperCase } from "../utility/component/format";
import { FormatNumber2 } from "../utility/functions/formatNumber";
import HandleIndexedDB from "../Test/handleIndexDb";
import "./animat.css";
import {
  fastMovingMovieItem,
  FontSizeWidth,
  openItemTitle,
} from "../utility/constant";

const ItemBox = ({ box, onClick, index, imgCard, mobileWidth }) => {
  const prefix = useSelector(selectPrefix);
  const icode = box.ICODE;
  const path = `retailx/items/${prefix + icode}`;
  const [img, setImg] = useState("");
  const width = useSelector(selectWidth);

  const fireToken = localStorage.getItem("fire");

  useEffect(() => {
    if (!fireToken) {
      if (width > 600) {
        const isImgAvailval = imgCard.filter((o) => o.ICODE === box.ICODE);
        if (!img && navigator.onLine && !isImgAvailval[0]) {
          DownloadFile(path)
            .then((e) => {
              if (!e.includes("FirebaseError")) {
                const urlParams = new URLSearchParams(new URL(e).search);
                const token = urlParams.get("token");
                localStorage.setItem("fire", token);

                const databaseName = "ItemsImages";
                const storeName = "images";
                HandleIndexedDB({
                  databaseName,
                  storeName,
                  callback: function (data, col) {
                    if (!data.find((o) => o.ICODE === box.ICODE)) {
                      const dataToSave = [
                        ...data,
                        {
                          ICODE: box.ICODE,
                          image: e,
                        },
                      ];

                      HandleIndexedDB({ databaseName, storeName, dataToSave });
                    }
                  },
                });

                setImg(e);
              } else {
                setImg("ERROR");
              }
            })
            .catch((e) => {
              setImg("ERROR");
            });
        } else {
          if (imgCard[0]) {
            const i = imgCard.filter((o) => o.ICODE === box.ICODE);
            if (i[0]) {
              setImg(i[0]?.image);
            }
          }
        }
      }
    } else {
      const i = `https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fitems%2F${prefix}${box.ICODE}?alt=media&token=${fireToken}`;
      setImg(i);
    }
  }, [box]);

  return (
    <button
      onClick={() => onClick(box)}
      className={`
        w-full p-1 rounded-lg transition-all duration-200 
        flex items-start 
        ${
          box.ITEMNAME === openItemTitle
            ? "bg-[#dae74a] shadow-md"
            : box.SECTION_NAME === fastMovingMovieItem
            ? "bg-[#f7b03e] shadow-md"
            : "bg-white shadow-md hover:shadow-lg"
        }
      `}
    >
      {width > 600 && (
        <div
          className={`w-16 h-16 rounded-lg overflow-hidden  ${
            box.ITEMNAME === openItemTitle
              ? "bg-[#c8d444] "
              : box.SECTION_NAME === fastMovingMovieItem
              ? "bg-[#ecad47] "
              : "bg-gray-100"
          } flex-shrink-0`}
        >
          {img && img !== "ERROR" && (
            <img
              alt=""
              src={img}
              className="w-full h-full object-cover"
              onError={() => setImg("")}
            />
          )}
        </div>
      )}

      <div className="flex-1 min-w-0 ml-1 ">
        <div className="flex justify-between items-start">
          <div className="font-medium text-gray-900  text-start">
            {toProperCase(box.ITEMNAME)}
          </div>
          <span
            className={`text-sm   ${
              box.ITEMNAME === openItemTitle
                ? "text-gray-800"
                : box.SECTION_NAME === fastMovingMovieItem
                ? "text-gray-800"
                : "text-gray-500"
            } font-mono`}
          >
            {box.ICODE}
          </span>
        </div>

        <div className="mt-1 flex items-center ">
          <span
            className={`
              w-2 h-2 rounded-full mr-1
              ${
                box.SALE_PRICE > 0
                  ? box.BAL_STOCK > 0
                    ? "bg-green"
                    : "bg-red-400"
                  : "bg-gray-300"
              }
            `}
          />

          {box.SALE_PRICE > 0 && (
            <span className="font-medium text-gray-900">
              ₹{FormatNumber2(box.SALE_PRICE)}
            </span>
          )}
        </div>
      </div>
    </button>
  );
};

export default ItemBox;
