import { Modal, Skeleton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { BackButton } from "../buttons";
import { useSelector } from "react-redux";
import { selectWidth } from "../../Redux/profile";
import Fade from "@mui/material/Fade";
import GeniusAlphatecKeyboard from "../geniusAlphatecKeyboard";
import { selectSpinnerLoading } from "../../Redux/modal";
import "./grd.css";
import { FontSizeWidth } from "../../constant";
const MasterHelp = ({
  status,
  style,
  filterTag,
  onFilterClear,
  title,
  onClose,
  rowId,
  list,
  columns,
  onRowClick,
  searchQuery,
  setSearchQuery,
  loading,
  CreateNew,
  extraFilter,
  goToMaster,
}) => {
  const headerRef = useRef(null);
  const windowWidth = useSelector(selectWidth);
  const spinerLoading = useSelector(selectSpinnerLoading);
  const [selectedRow, setSelectedRow] = useState(1);

  const handleKeyDown = (event) => {
    // its work using datgrid id
    if (event.key === "ArrowUp") {
      // Handle moving selection up
      event.preventDefault();
      setSelectedRow((prevSelectedRow) => {
        const index = prevSelectedRow ? prevSelectedRow - 1 : list.length - 1;
        return index >= 0 ? index : null;
      });
    } else if (event.key === "ArrowDown") {
      // Handle moving selection down
      event.preventDefault();
      setSelectedRow((prevSelectedRow) => {
        const index = prevSelectedRow !== null ? prevSelectedRow + 1 : 1;

        return index - 1 < list.length ? index : 1;
      });
    } else if (event.key === "Enter") {
      // Handle Enter key press
      if (selectedRow !== null && list[0]) {
        // Call your function here with the selected row
        onRowClick({
          row: {
            ...list[selectedRow - 1],
          },
        });
        setSelectedRow(1);
      }
    }
  };

  useEffect(() => {
    if (
      status &&
      (!title.includes("Item") ||
        !window.location.pathname.includes("purchase"))
    ) {
      document.addEventListener("keydown", handleKeyDown);

      // Cleanup function to remove event listener
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    } else {
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [selectedRow, status, list]); // R

  const width = useSelector(selectWidth);
  return (
    <Modal
      open={status}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade
        in={status}
        style={{
          transitionDelay: status ? "100ms" : "0ms",
        }}
      >
        <div style={style}>
          <div className="w-full flex flex-col bg-white h-full ">
            <div ref={headerRef}>
              <div
                style={{
                  border: "1px solid black",
                  borderTopRightRadius: "8px",
                  borderTopLeftRadius: "8px",
                  overflow: "hidden",
                  borderBottom: "0px",
                }}
                className="flex flex-col bg-white Fade"
              >
                <div
                  style={{
                    fontSize: FontSizeWidth(width),
                  }}
                  className="font-semibold text-[#4a4a4a] bg-[#f5f0e8] font-arial p-3"
                >
                  {title}
                </div>
              </div>
            </div>
            {filterTag && (
              <div className="bg-[#f5f0e8] flex border-l p-1  border-r  border-black">
                <div className="  py-[6px] px-3  flex bg-[#f8d9c9] w-fit rounded-sm">
                  <div className="text-[14px]">{filterTag}</div>
                  <button onClick={onFilterClear}>
                    <i className="bi ml-1  bi-x-lg text-[13px] flex items-center text-red-500 "></i>
                  </button>
                </div>
              </div>
            )}

            <div
              style={{
                height: headerRef.current
                  ? `calc(100% - ${headerRef.current.clientHeight}px)`
                  : "80%",
                border: "1px solid black",
                borderBottom: "0px",
              }}
              className="t:flex "
            >
              <div className="h-full ">
                <div
                  style={{
                    height: `calc(100% - ${38}px)`,
                    width:
                      columns.reduce((acc, row) => acc + Number(row.width), 0) +
                      20,
                  }}
                  onKeyDown={handleKeyDown}
                  tabIndex={0}
                >
                  {!spinerLoading ? (
                    <DataGrid
                      onRowClick={onRowClick}
                      hideFooter={list.length < 100}
                      headerHeight={0}
                      rowHeight={30}
                      showCellRightBorder={true}
                      showColumnRightBorder={true}
                      disableColumnMenu={true}
                      getRowId={rowId}
                      loading={loading}
                      selectionModel={selectedRow !== null ? [selectedRow] : []}
                      components={{
                        Header: () => {
                          const headerDetails = columns;

                          return (
                            <div
                              style={{
                                borderBottom: "1px solid black",
                              }}
                              className="bg-[#f5f0e8] flex"
                            >
                              {headerDetails?.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="text-#4a4a4a flex justify-center items-center py-2"
                                    style={
                                      item.flex
                                        ? index < headerDetails.length - 1
                                          ? {
                                              flex: item.flex,
                                              borderRight: "1px solid black",
                                              fontSize: FontSizeWidth(width),
                                            }
                                          : {
                                              flex: item.flex,
                                              fontSize: FontSizeWidth(width),
                                            }
                                        : index < headerDetails.length - 1
                                        ? {
                                            width: item.width,
                                            borderRight:
                                              item.width > 0
                                                ? "1px solid black"
                                                : "0px solid black",
                                            fontSize: FontSizeWidth(width),
                                          }
                                        : {
                                            width: item.width,
                                            fontSize: FontSizeWidth(width),
                                          }
                                    }
                                  >
                                    {item.renderHeader()}
                                  </div>
                                );
                              })}
                            </div>
                          );
                        },

                        NoRowsOverlay: () => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                width: "100%",
                              }}
                            >
                              No Records Found
                            </div>
                          );
                        },
                      }}
                      sx={
                        window.innerWidth < 770
                          ? {
                              backgroundColor: "white",
                              fontSize: FontSizeWidth(width),

                              "& .super-app-theme--header": {
                                backgroundColor: "#f5f0e8",
                                display: "none",
                              },
                              borderRadius: 0,
                              "& .Mui-selected-row": {
                                // Override selected row styles
                                backgroundColor: "blue", // Change background color to blue
                                color: "white", // Change text color to white
                              },
                            }
                          : {
                              backgroundColor: "white",
                              fontSize: FontSizeWidth(width),

                              borderBottom: "0px solid black",
                              borderRadius: 0,
                              "& .super-app-theme--header": {
                                backgroundColor: "#f5f0e8",
                                display: "none",
                              },
                            }
                      }
                      getRowClassName={(params) =>
                        params.isSelected ? "Mui-selected-row" : ""
                      }
                      rowClassName={(params) =>
                        params.isSelected ? "Mui-selected-row" : ""
                      }
                      columns={columns}
                      rows={list}
                      pageSize={100}
                      rowsPerPageOptions={[100]}
                      experimentalFeatures={{ newEditingApi: true }}
                      disableColumnSelector
                      disableColumnReorder
                    />
                  ) : (
                    <div className="w-full ">
                      <div className="flex w-full ">
                        {columns.map((e, i) => {
                          return (
                            <Skeleton
                              key={i}
                              width={e.width}
                              height={60}
                              style={{
                                marginLeft: 3,
                              }}
                            />
                          );
                        })}
                      </div>
                      <div>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e, key) => {
                          return (
                            <div key={key} className="flex ">
                              {columns.map((e, i) => {
                                return (
                                  <Skeleton
                                    key={i}
                                    width={e.width}
                                    height={50}
                                    style={{
                                      marginLeft: 3,
                                    }}
                                  >
                                    {" "}
                                  </Skeleton>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex flex-1 items-center">
                  <div
                    style={{
                      borderBottom: "0px solid black",

                      fontSize: "14px",
                      backgroundColor: "#f5f0e8",
                    }}
                    className="flex px-2 flex-1 py-2 items-center "
                  >
                    <i className="bi bi-search pr-2 text-orange"></i>
                    <input
                      type={"text"}
                      placeholder="Search"
                      value={searchQuery}
                      {...(window.innerWidth > 1280 && { autoFocus: true })}
                      onChange={(t) => {
                        setSearchQuery(t.target.value);
                      }}
                      style={{
                        width: extraFilter
                          ? extraFilter[0].firstWidth - 150
                          : "100%",
                      }}
                      className=" outline-0 h-full bg-[#f5f0e8] text-[#4a4a4a]"
                    />
                  </div>

                  <div className=" flex  ">
                    {extraFilter
                      ? extraFilter.map((o, key) => {
                          return (
                            <div key={key}>
                              <input
                                value={o.value}
                                type={"text"}
                                placeholder={o.placeHolder}
                                onChange={(t) => {
                                  o.onChange(t.target.value);
                                }}
                                style={{
                                  width: o.width,
                                }}
                                className="pl-2 border-l p-2 border-l-[#c7c6c5] bg-[#F5F0E8] outline-0  text-[#4a4a4a]"
                              />
                            </div>
                          );
                        })
                      : null}
                  </div>
                  {CreateNew && (
                    <button
                      onClick={CreateNew}
                      className=" text-sm text-gray-900 shadow-sm  ml-1 mr-1 bg-green p-2 rounded-r-sm flex items-center "
                    >
                      Create New
                    </button>
                  )}
                </div>
              </div>
              {windowWidth <= 1280 && windowWidth >= 1024 && (
                <div className=" h-full border   flex flex-col ">
                  <div className=" w-full flex justify-center  flex-col grow items-center  ">
                    {/* <img src={tailoringLogo} className="w-44 flex" /> */}
                    {/* <RetailxLogo /> */}
                  </div>

                  <div className="  justify-end">
                    <GeniusAlphatecKeyboard
                      text={searchQuery}
                      setText={setSearchQuery}
                      onEnter={() => {
                        setSearchQuery("");

                        onRowClick({ row: list[0] });
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div
              style={{
                borderTop: "1px dotted black",
                borderLeft: "1px dotted black",
                borderRight: "1px dotted black",
                borderBottom: "1px dotted black",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
              className="w-full flex justify-end p-2 bg-white  "
            >
              {
                <BackButton
                  onClick={() => {
                    onClose();
                    setSearchQuery("");
                  }}
                />
              }
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default MasterHelp;
