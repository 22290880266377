import CallAPI from "../../utility/functions/getData";
import {
  setAlertWithTitle,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import deleteWholeDatabase from "../../utility/functions/deleteWholeDatabase";
import dataToLocal from "../../utility/component/dataToLocal";

export const GetSaleSummary = (data, token, dispatch, setSummaryList) => {
  dispatch(setSpinnerLoading("Loading..."));
  deleteWholeDatabase("voucher_summary");

  CallAPI(
    "/api/voucher/voucherSummary",
    data,
    token,
    (e) => {
      if (!e.data.error) {
        if (e.data.response[0]) {
          setSummaryList(e.data.response);
          dataToLocal("voucher_summary", e.data.response, [
            {
              ...data,
              ICODE: 1,
            },
          ]);
        } else {
          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: "No Records Found",
            })
          );
        }
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Something Went Wrong.",
          })
        );
      }
    },
    dispatch,
    () => {
      dispatch(setSpinnerLoading(false));
    }
  );
};
