import { setSpinnerLoading } from "../utility/Redux/modal";
import { CallAPIPromise } from "../billing/common";

const checkLastPurchasePrice = (
  prefix,
  partyName,
  icode,
  price,
  token,
  dispatch
) => {
  dispatch(setSpinnerLoading("Last Purchase Price"));
  const data = {
    table_prefix: prefix,
    NAME: partyName,
    ICODE: icode,
  };
  return new Promise((resolve, reject) => {
    CallAPIPromise(`/api/purchase/lastPurchasePrice`, data, token, dispatch)
      .then((res) => {
        if (!res.data.error) {
          const data = res.data.response;
          resolve(data);
        } else {
          reject(new Error("Error in response data"));
        }
      })
      .catch((e) => {
        reject(e);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  });
};

export default checkLastPurchasePrice;
