import { toProperCase } from "./format";

const NumberToWords3 = (numb) => {
  const num = Math.round(numb);

  const units = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];

  const tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  const scales = ["", "thousand", "million", "billion", "trillion"];

  if (!Number.isInteger(num)) return "invalid input";
  if (num === 0) return "zero";

  let numStr = String(num);
  if (numStr.length > 15) return "overflow";
  numStr = numStr.padStart(15, "0");

  const chunks = numStr.match(/.{1,3}/g).map(Number);

  let words = [];
  for (let i = 0; i < chunks.length; i++) {
    let chunkWords = [];
    let chunk = chunks[i];

    if (chunk !== 0) {
      let hundreds = Math.floor(chunk / 100);
      let tensUnits = chunk % 100;

      if (hundreds !== 0) {
        chunkWords.push(units[hundreds], "hundred");
      }

      if (tensUnits !== 0) {
        if (tensUnits < 20) {
          chunkWords.push(units[tensUnits]);
        } else {
          let tensDigit = Math.floor(tensUnits / 10);
          let unitsDigit = tensUnits % 10;
          chunkWords.push(tens[tensDigit]);
          if (unitsDigit !== 0) {
            chunkWords.push(units[unitsDigit]);
          }
        }
      }
    }

    if (chunk !== 0 || i === chunks.length - 1) {
      chunkWords.push(scales[chunks.length - 1 - i]);
      words.push(chunkWords.filter((w) => w !== "").join(" "));
    }
  }

  return toProperCase(words.filter((w) => w !== "").join(" "));
};

export default NumberToWords3;
