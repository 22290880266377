import React, { useEffect, useState } from "react";
import { FormatNumber2 } from "../functions/formatNumber";
import moment from "moment";

const Comp = ({ TotalFilterData, finalFilterData, prepareRow, e, i }) => {
  const [filterdRow, setFilterRow] = useState([]);
  const [TOTALROW, setTotalRow] = useState([]);
  const [finalData, setFinalData] = useState([]);
  useEffect(() => {
    const f = TotalFilterData.filter(
      (r) =>
        r.values.ITEMNAME !== "Total..." &&
        r.values.SECTION_NAME === e.SECTION_NAME
    );
    setFilterRow(f);
    setTotalRow(
      TotalFilterData.filter((r) => r.values.ITEMNAME === "Total...")
    );
    const data = f[0]
      ? [
          {
            ...f[0],
            values: {
              ...f[0].values,
              filterName: e.SECTION_NAME,
            },
          },
          ...f,
          {
            ...f[0],
            values: {
              ...f[0].values,
              QNTY: (
                <div className="font-semibold text-[11px] text-right">
                  {" "}
                  {f.reduce((acc, row) => acc + Number(row.values.QNTY), 0)}
                </div>
              ),
              AMOUNT: (
                <div className="font-semibold text-[11px] text-right">
                  {" "}
                  {f
                    .reduce((acc, row) => acc + Number(row.values.AMOUNT), 0)
                    .toFixed(2)}
                </div>
              ),
              TAX2_AMT: (
                <div className="font-semibold text-[11px] text-right">
                  {" "}
                  {f
                    .reduce((acc, row) => acc + Number(row.values.TAX2_AMT), 0)
                    .toFixed(2)}
                </div>
              ),

              TAX1_AMT: (
                <div className="font-semibold text-[11px] text-right">
                  {" "}
                  {f
                    .reduce((acc, row) => acc + Number(row.values.TAX1_AMT), 0)
                    .toFixed(2)}
                </div>
              ),

              TAX3_AMT: (
                <div className="font-semibold text-[11px] text-right">
                  {" "}
                  {f
                    .reduce((acc, row) => acc + Number(row.values.TAX3_AMT), 0)
                    .toFixed(2)}
                </div>
              ),
              GTOTAL: (
                <div className="font-semibold text-[11px] text-right">
                  {" "}
                  {f
                    .reduce((acc, row) => acc + Number(row.values.GTOTAL), 0)
                    .toFixed(2)}
                </div>
              ),
              ICODE: "",
              ITEMNAME: (
                <div className="font-semibold text-[11px] text-right">
                  {" "}
                  Sub Total...
                </div>
              ),
              PUR_PRICE: "",

              DISQ_AMT: FormatNumber2(
                f.reduce((acc, row) => acc + Number(row.values.DISQ_AMT), 0)
              ),
              TAX1_RATE: "",
              TAX2_RATE: "",
              TAX3_RATE: "",
              SECTION_NAME: "",
              SUBTOTAL: true,
            },
          },
        ]
      : [];
    const uniqueData = data.filter(
      (item, index, self) => index === self.findIndex((i) => i.id === item.id)
    );

    setFinalData(
      i === finalFilterData.length - 1
        ? [
            ...uniqueData,
            {
              ...TOTALROW[0],
              values: {
                ...TOTALROW[0]?.values,
                ITEMNAME: (
                  <div className="font-semibold text-[11px] text-right">
                    {" "}
                    Total...
                  </div>
                ),
                PRINTTOTAL: true,
              },
            },
          ]
        : uniqueData
    );
  }, [TotalFilterData, finalFilterData]);

  return finalData.map((row, i) => {
    prepareRow(row);
    return (
      <tr key={i} {...row.getRowProps()}>
        {row.values.filterName
          ? row.cells.map((cell, ci) => (
              <td
                {...cell.getCellProps()}
                style={{
                  padding: "8px",
                  borderTop: "1px solid black",
                  fontSize: "11px",
                }}
                key={ci}
              >
                <div className="font-semibold text-[14px]">
                  {ci === 0 && row.values.filterName}
                </div>
              </td>
            ))
          : row.values.SUBTOTAL
          ? row.cells.map((cell, ci) => (
              <td
                {...cell.getCellProps()}
                style={{
                  padding: "8px",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  fontSize: "11px",
                }}
                key={ci}
              >
                <div
                  style={{
                    textAlign: cell.render("Cell").props.cell.column.align
                      ? cell.render("Cell").props.cell.column.align
                      : "left",
                  }}
                  className="whitespace-nowrap"
                >
                  {cell.render("Cell").props.cell.column.type === "date"
                    ? moment(cell.render("Cell").props.cell.value).format(
                        "YYYY-MM-DD"
                      )
                    : cell.render("Cell").props.cell.value === "0.00"
                    ? null
                    : cell.render("Cell")}
                </div>
              </td>
            ))
          : row.cells.map((cell, ci) => (
              <td
                {...cell.getCellProps()}
                style={{
                  padding: "8px",
                  borderRight: row.values.PRINTTOTAL
                    ? "1px solid black"
                    : "1px dashed black",
                  borderTop: row.values.PRINTTOTAL
                    ? "1px solid black"
                    : "1px dashed black",
                  fontSize: "11px",
                }}
                key={ci}
              >
                <div
                  style={{
                    textAlign: cell.render("Cell").props.cell.column.align
                      ? cell.render("Cell").props.cell.column.align
                      : "left",
                  }}
                  className="whitespace-nowrap"
                >
                  {cell.render("Cell").props.cell.column.type === "date"
                    ? moment(cell.render("Cell").props.cell.value).format(
                        "YYYY-MM-DD"
                      )
                    : cell.render("Cell").props.cell.value === "0.00"
                    ? null
                    : cell.render("Cell")}
                </div>
              </td>
            ))}
      </tr>
    );
  });
};

const Printcomp = ({ finalFilterData, TotalFilterData, prepareRow }) => {
  return finalFilterData.map((e, i) => {
    return (
      <Comp
        key={i}
        finalFilterData={finalFilterData}
        TotalFilterData={TotalFilterData}
        prepareRow={prepareRow}
        e={e}
        i={i}
      />
    );
  });
};

export default Printcomp;
