import React from "react";
import { Modal } from "@mui/material";

const CustomeModal = ({ data, status }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    height: "auto",
  };

  return (
    <div>
      <Modal open={status}>
        <div style={style} className={`rounded-sm overflow-hidden `}>
          <div>{data}</div>
        </div>
      </Modal>
    </div>
  );
};

export default CustomeModal;
