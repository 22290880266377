import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  selectPrefix,
  selectWidth,
  selectreportFromDate,
  selectreportToDate,
} from "../../utility/Redux/profile";
import { FromToDate } from "../../utility/component/fromToDate";
import { selectToken } from "../../utility/Redux/security";
import { SummaryColumns } from "./columns";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import GeniusTableReports from "../../utility/component/geniusComponents/geniusTableData";
import { ExitButton, PrintButton } from "../../utility/component/buttons";
import PrintWithFooter from "../../utility/component/datagrid/printReportWithFooter";
import { GetOfferList } from "./func";
import FromToDateMobile from "../../utility/component/fromToDateMobile";

const OfferList = () => {
  const prefix = useSelector(selectPrefix);
  const [summaryList, setSummaryList] = useState([]);
  const fromDate = useSelector(selectreportFromDate);
  const ToDate = useSelector(selectreportToDate);
  const width = useSelector(selectWidth);
  const printRef = useRef(null);

  const columns = SummaryColumns(width);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const okButton = () => {
    GetOfferList(
      {
        table_prefix: prefix,
        FDATE: fromDate,
        TDATE: ToDate,
      },
      token,
      dispatch,
      setSummaryList
    );
  };
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
  @page {
    size: portrait;
  }
`,
  });

  return (
    <div className=" bg-white flex flex-col h-full w-full overflow-y-auto   ">
      <div className=" flex flex-col h-full p-3 w-full ">
        <div>
          {window.innerWidth <= 680 ? (
            <FromToDateMobile
              hideReset={true}
              submit={okButton}
              reset={() => {
                setSummaryList([]);
              }}
            />
          ) : (
            <FromToDate
              hideReset={true}
              submit={okButton}
              reset={() => {
                setSummaryList([]);
              }}
            />
          )}
        </div>

        {summaryList[0] ? (
          <div
            style={{
              height: `calc(100% - 160px)`,
              width: "100%",
              marginTop: 10,
            }}
            className=" mb-2  w-full "
          >
            <GeniusTableReports data={summaryList} columns={columns} />

            <div className="w-full mt-3 ">
              <div
                style={{
                  display: "none",
                }}
              >
                <div ref={printRef}>
                  <PrintWithFooter
                    customeHeader={
                      <div className="items-center  ">
                        <div className="flex items-center">
                          <div className="mr-1 text-sm font-semibold flex justify-center ">
                            Offer List
                          </div>
                          <div className="text-sm font-semibold">From :</div>
                          <div className="ml-1 text-sm">
                            {moment(fromDate).format("DD-MM-YYYY")}{" "}
                          </div>
                          <div className="text-sm font-semibold ml-2">To :</div>
                          <div className="ml-1 text-sm">
                            {moment(ToDate).format("DD-MM-YYYY")}{" "}
                          </div>
                        </div>
                      </div>
                    }
                    data={summaryList}
                    columns={columns}
                    marginTop={"120px"}
                    headerWidth={true}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-full flex justify-center items-center text-sm text-gray-400 border mt-3 rounded-sm">
            No Records Found
          </div>
        )}

        {summaryList[0] && (
          <div className="flex justify-end  mt-3">
            <PrintButton onClick={handlePrint} />
            <ExitButton
              onClick={() => {
                navigate("/reports");
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default OfferList;
