import { useSelector } from "react-redux";
import { selectWidth } from "./Redux/profile";
import { setSpinnerLoading } from "./Redux/modal";
import { FormatNumber2 } from "./functions/formatNumber";
import { CallAPIPromise } from "../billing/common";

export const isPosMod = false;
export const isFirebaseUserLoginCheckEnabeld = true;
export const isStateCheckEnabeld = false;
export const licenceExpired_alert =
  "Your license has expired. Please contact support@geniusoffice.com, +91 85589 51962 for renewal. Visit https://www.geniusoffice.com.";
export const fastMovingMovieItem = "Fast Moving Items";
export const openItemTitle = "Open Item";
export function convertToAmPm(timeRange) {
  return timeRange
    .split("-")
    .map((time) => {
      const [hour, minute] = time.split(":").map(Number);
      const suffix = hour >= 12 ? "PM" : "AM";
      const adjustedHour = hour % 12 || 12; // Convert hour to 12-hour format
      return `${adjustedHour}:${minute.toString().padStart(2, "0")} ${suffix}`;
    })
    .join(" - ");
}
export const FontSizeWidth = (width) => {
  if (width < 500) {
    return 11;
  }
  if (width < 1281) {
    return 12;
  } else {
    return 14;
  }
};

export const IslocalNotificationSoundAlllow = () => {
  const data = localStorage.getItem("notificationSound");
  if (data === "false") {
    return false;
  } else {
    return true;
  }
};
export const HeaderWidth = () => {
  const width = useSelector(selectWidth);
  if (width > 450) {
    return 60;
  } else {
    return 0;
  }
};

//  const bgColor = '#0d0d0d'

// export const TabBarBgColor ='#e6e4df'
export const TabBarBgColor = "#0d0d0d";
export const getMessageWallet = (companyEmail, token, sms, dispatch) => {
  return new Promise((resolve, reject) => {
    CallAPIPromise(
      `/api/userSettings/loadMessageBalance`,
      {
        company_email: companyEmail,
      },
      token,
      dispatch
    )
      .then((res) => {
        if (!res.data.error) {
          if (res.data.response[0]) {
            if (sms) {
              resolve(
                Number(res.data.response[0].sms_balance),
                res.data.response[0]
              );
            } else {
              resolve(
                Number(res.data.response[0].whatsapp_balance),
                res.data.response[0]
              );
            }
          } else {
            resolve(0);
          }
        } else {
          resolve(0);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(0);
      });
  });
};

export function balanceType(value) {
  // Check if the value is less than 0
  if (value < 0) {
    return FormatNumber2(Math.abs(value)) + " Cr"; // Return the absolute value with "Cr"
  } else {
    return FormatNumber2(value) + " Dr"; // Return the value with "Dr"
  }
}

export const getItemByCode = (
  prefix,
  icode,
  token,
  dispatch,
  isIgnoreStopingLoading
) => {
  dispatch(setSpinnerLoading("Loading item"));
  return new Promise((resolve, reject) => {
    CallAPIPromise(
      `/api/master/listItemMaster`,
      {
        table_prefix: prefix,
        SECTION_NAME: "",
        ICODE: icode,
        ALIAS_CODE: "",
        ITEMNAME: "",
      },
      token,
      dispatch
    )
      .then((res) => {
        if (!res.data.error) {
          if (res.data.response[0]) {
            resolve(res.data.response);
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      })
      .catch((err) => {
        console.log(err);
        reject([]);
      })
      .finally(() => {
        if (!isIgnoreStopingLoading) {
          dispatch(setSpinnerLoading(false));
        }
      });
  });
};

export const scops = {
  manager:
    "read:order,write:order,read:master,write:master,read:purchase,write:purchase,read:billing,write:billing",
};

export const getItemByCodeFromPurchase = (
  prefix,
  icode,
  token,
  dispatch,
  isIgnoreStopingLoading
) => {
  return new Promise((resolve, reject) => {
    // dispatch(setSpinnerLoading("Loading"));
    CallAPIPromise(
      `/api/master/itemMasterHelp`,
      {
        table_prefix: prefix,
        SECTION_NAME: "",
        ICODE: icode,
        ALIAS_CODE: "",
        ITEMNAME: "",
      },
      token,
      dispatch
    )
      .then((res) => {
        if (!res.data.error) {
          if (res.data.response[0]) {
            resolve(res.data.response);
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      })
      .catch((err) => {
        console.log(err);
        reject([]);
      })
      .finally(() => {
        if (!isIgnoreStopingLoading) {
          dispatch(setSpinnerLoading(false));
        }
      });
  });
};

export const getItemByAlios = (
  prefix,
  alios,
  token,
  dispatch,
  isIgnoreStopingLoading
) => {
  dispatch(setSpinnerLoading("Loading item"));

  return new Promise((resolve, reject) => {
    CallAPIPromise(
      `/api/master/listItemMaster`,
      {
        table_prefix: prefix,
        SECTION_NAME: "",
        ICODE: "",
        ALIAS_CODE: alios,
        ITEMNAME: "",
      },
      token,
      dispatch
    )
      .then((res) => {
        if (!res.data.error) {
          if (res.data.response[0]) {
            resolve(res.data.response);
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      })
      .catch((err) => {
        console.log(err);
        reject([]);
      })
      .finally(() => {
        if (!isIgnoreStopingLoading) {
          dispatch(setSpinnerLoading(false));
        }
      });
  });
};

export const getItemByName = (prefix, name, token, dispatch) => {
  dispatch(setSpinnerLoading("Loading item"));

  return new Promise((resolve, reject) => {
    CallAPIPromise(
      `/api/master/listItemMaster`,
      {
        table_prefix: prefix,
        SECTION_NAME: "",
        ICODE: "",
        ALIAS_CODE: "",
        ITEMNAME: name,
      },
      token,
      dispatch
    )
      .then((res) => {
        if (!res.data.error) {
          if (res.data.response[0]) {
            resolve(res.data.response);
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      })
      .catch((err) => {
        console.log(err);
        reject([]);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  });
};
