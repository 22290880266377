import CallAPI from "../../utility/functions/getData";
import {
  setAlertWithTitle,
  setSpinnerLoading,
} from "../../utility/Redux/modal";

export const GetItemWiseSaleReport = (
  data,
  token,
  dispatch,
  setSummaryList,
  setModalStatus
) => {
  dispatch(setSpinnerLoading("Loading..."));

  CallAPI(
    "/api/purchaseReports/itemStatus",
    data,
    token,
    (e) => {
      if (!e.data.error) {
        if (e.data.response[0]) {
          setModalStatus(true);

          setSummaryList(e.data.response);
        } else {
          setSummaryList([]);

          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: "No Records Found",
            })
          );
        }
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Something Went Wrong.",
          })
        );
      }
    },
    dispatch,
    () => {
      dispatch(setSpinnerLoading(false));
    }
  );
};
