import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { get, onValue, ref } from "firebase/database";
import { database } from "../../utility/functions/firebase";

// Define the container style for the Google Map
const containerStyle = {
  width: "100%",
  height: "75vh",
};

const LiveMap = () => {
  const [deviceIdList, setDeviceIdList] = useState([]);
  const [deviceId, setDeviceId] = useState("");
  const [lastActive, setLastActive] = useState("");
  const [markerPosition, setMarkerPosition] = useState({
    lat: 31.2853228, // Default latitude
    lng: 75.5874643, // Default longitude
  });
  const [googleLoaded, setGoogleLoaded] = useState(false); //
  // Fetch device list from Firebase on component mount
  useEffect(() => {
    const connectedRef = ref(database, "loginAuth/fieldway/");
    get(connectedRef)
      .then((snapshot) => {
        if (snapshot.val()) {
          const dataList = Object.entries(snapshot.val()).map(
            ([key, value]) => ({
              id: key,
              latitude: value.latitude,
              longitude: value.longitude,
            })
          );
          setDeviceIdList(dataList);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  // Fetch latitude and longitude when a device ID is selected
  useEffect(() => {
    if (deviceId) {
      const latitudeRef = ref(
        database,
        `loginAuth/fieldway/${deviceId}/latitude`
      );
      const longitudeRef = ref(
        database,
        `loginAuth/fieldway/${deviceId}/longitude`
      );
      const timeRef = ref(database, `loginAuth/fieldway/${deviceId}/time`);
      onValue(latitudeRef, (sn) => {
        if (sn.exists()) {
          const data = sn.val();
          setMarkerPosition((prev) => ({ ...prev, lat: data }));
        }
      });

      onValue(longitudeRef, (sn) => {
        if (sn.exists()) {
          const data = sn.val();
          setMarkerPosition((prev) => ({ ...prev, lng: data }));
        }
      });
      onValue(timeRef, (sn) => {
        if (sn.exists()) {
          const data = sn.val();
          setLastActive(data);
        }
      });
    }
  }, [deviceId]); // Runs when deviceId changes
  const CustomMarker = () => (
    <div style={{ position: "relative" }}>
      <i
        className="bi bi-geo-alt-fill text-primary"
        style={{ fontSize: "24px" }}
      ></i>
      <div
        style={{
          position: "absolute",
          top: "-10px",
          left: "-10px",
          background: "white",
          borderRadius: "50%",
          padding: "5px",
          boxShadow: "0 0 5px rgba(0,0,0,0.3)",
        }}
      />
    </div>
  );
  const onLoad = () => {
    setGoogleLoaded(true);
  };
  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_KEY}>
      <div className="p-3">
        <div className="flex">
          <div className="max-w-[200px] pb-3">
            <label
              htmlFor="user"
              className="block text-sm font-medium text-primary dark:text-primary-foreground"
            >
              Select User
            </label>
            <div className="relative mt-2 min-w-[200px]">
              <select
                onChange={(e) => setDeviceId(e.target.value)}
                id="user"
                className="block w-full px-4 py-2 pr-8 leading-tight bg-input text-primary dark:bg-primary dark:text-primary-foreground border border-primary dark:border-primary rounded appearance-none focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
              >
                <option value={""}>{"Select User"}</option>
                {deviceIdList.map((o) => (
                  <option key={o.id} value={o.id}>
                    {o.id}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className=" ml-3 min-w-[200px]">
            <div className="block text-sm font-medium text-black dark:text-primary-foreground">
              Last Active
            </div>
            <div className="block w-full mt-2 px-4 py-2 pr-8 leading-tight bg-input text-black border border-black dark:border-primary rounded appearance-none focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary">
              {lastActive ? lastActive : "Time"}
            </div>
          </div>
        </div>

        <GoogleMap
          mapContainerStyle={containerStyle}
          center={markerPosition}
          zoom={13}
          onLoad={onLoad} // Call onLoad when Google Map is loaded
        >
          {googleLoaded && (
            <Marker position={markerPosition} title="Current Location" />
          )}
        </GoogleMap>
      </div>
    </LoadScript>
  );
};

export default LiveMap;
