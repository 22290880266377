import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectEmail } from "../../utility/Redux/profile";
import { isTokenExpired } from "../../utility/functions/checkToken";
import { getToken } from "../../utility/Redux/security";

const ProtectedRoute = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const email = useSelector(selectEmail);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const expires = localStorage.getItem("expiresAt");
  const date = new Date().getTime();

  const checkUserToken = () => {
    const userToken = getToken();
    if (userToken) {
      isTokenExpired(userToken).then((result) => {
        if (result) {
          setIsLoggedIn(false);
          navigate("/auth/login");
        } else {
          setIsLoggedIn(true);
        }
      });
    } else {
      setIsLoggedIn(false);
      return navigate("/auth/login");
    }
  };

  useEffect(() => {
    checkUserToken();
  }, [email]);
  return <React.Fragment>{isLoggedIn ? props.children : null}</React.Fragment>;
};
export default ProtectedRoute;
