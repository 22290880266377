import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";

import { selectPrefix, selectWidth } from "../../utility/Redux/profile";
import { selectToken } from "../../utility/Redux/security";
import {
  setALertStatus,
  setAlertMsg,
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import GeniusDatagrid from "../../utility/component/datagrid/geniusDatagrid";
import { toProperCase } from "../../utility/component/format";
import {
  ExitButton,
  ModifyButton,
  SaveButton,
  UndoButton,
} from "../../utility/component/buttons";
import GeniustextInput from "../../utility/component/mastertext";
import ModalHeaderStyle from "../../utility/component/styles/modalHeader";
import { useNavigate } from "react-router-dom";
import { useFocus } from "../../utility/hooks/hooks";
import { CallAPIPromise } from "../../billing/common";

const ExpenseMaster = () => {
  const [expenseName, setExpenseName] = useState("");
  const [expenseRef, setExpenseFocus] = useFocus();
  const [list, setList] = useState([]);
  const [editCode, setEditCode] = useState("");
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const width = useSelector(selectWidth);
  const columns = React.useMemo(
    () => [
      {
        field: "CODE",
        type: "string",
        width: 80,
        headerAlign: "center",
        headerClassName: "super-app-theme--header",
        renderHeader: (params) => (
          <strong style={{ fontSize: 13 }}>{"Code"}</strong>
        ),
      },

      {
        field: "EXPENSE_NAME",
        type: "string",
        width: width < 764 ? 150 : 300,
        headerAlign: "center",
        headerClassName: "super-app-theme--header",
        renderHeader: (params) => (
          <strong style={{ fontSize: 13 }}>{"Expense Name"}</strong>
        ),
      },
    ],
    []
  );

  const SaveExpense = () => {
    if (expenseName !== "") {
      const isDuplicate = list.filter((e) => e.EXPENSE_NAME === expenseName);
      if (!isDuplicate[0]) {
        dispatch(setSpinnerLoading("Saving Expense"));
        CallAPIPromise(
          "/api/master/saveExpenseMaster",
          {
            table_prefix: prefix,
            EXPENSE_NAME: expenseName,
            CODE: "",
          },
          token,
          dispatch
        )
          .then((res) => {
            if (!res.data.error) {
              setExpenseName("");

              dispatch(
                setAlertWithTitle({
                  title: "Success",
                  msg: "Data saved successfully",
                  lottie: "success",
                })
              );
            }
            getListExpense();
          })
          .catch((e) => {
            if (e.response.data.name === "TokenExpiredError") {
              dispatch(setSessionExpired(true));
            } else {
              dispatch(setAlertMsg(e.message));
              dispatch(setALertStatus(true));
            }
            console.log(e);
          })
          .finally(() => {
            dispatch(setSpinnerLoading(false));
          });
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Already have same Expense ",
          })
        );
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: "Alert",
          msg: "Please Fill Expense Name",
        })
      );
      setExpenseFocus();
    }
  };

  const getListExpense = () => {
    dispatch(setSpinnerLoading("Loading"));
    CallAPIPromise(
      "/api/master/listExpenseMaster",
      {
        table_prefix: prefix,
      },
      token,
      dispatch
    )
      .then((res) => {
        if (!res.data.error) {
          setList(res.data.response);
          undo();
        }
      })
      .catch((e) => {
        if (e.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        } else {
          dispatch(setAlertMsg(e.message));
          dispatch(setALertStatus(true));
        }
        console.log(e);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  const undo = () => {
    setExpenseName("");
    setEditCode("");
  };

  useEffect(() => {
    getListExpense();
  }, []);

  useEffect(() => {
    function handleKeyPress(event) {
      // Check if Ctrl or Alt key is pressed along with 'S'
      if ((event.ctrlKey || event.altKey) && event.key === "s") {
        // Call your function here
        SaveExpense();
      }
    }

    // Add event listener when component mounts
    window.addEventListener("keydown", handleKeyPress);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [expenseName]);

  const handleModify = () => {
    dispatch(setSpinnerLoading("Modifying"));
    CallAPIPromise(
      "/api/master/saveExpenseMaster",
      {
        table_prefix: prefix,
        EXPENSE_NAME: expenseName,

        CODE: editCode,
      },
      token,
      dispatch
    )
      .then((res) => {
        if (!res.data.error) {
          undo();
          getListExpense();
          dispatch(
            setAlertWithTitle({
              title: "Succes",
              msg: "Expense is Modified",
              lottie: "success",
            })
          );
        }
      })
      .catch((e) => {
        if (e.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
          console.log(e.response);
        } else {
          dispatch(setAlertMsg(e.message));
          dispatch(setALertStatus(true));
        }
        console.log(e);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  const navigate = useNavigate();
  return (
    <div className="w-full  flex justify-center bg-[#f5f5f7] text-black h-full ">
      <div className="text-sm  rounded-sm   flex flex-col  py-4 h-full ">
        <div>
          <ModalHeaderStyle
            header={"Expense Master"}
            onClose={() => {
              navigate("/masters");
            }}
          />
        </div>
        <div
          style={{
            height: "calc(100% - 150px)",
          }}
          className="  rounded-b-sm    border-brown "
        >
          <div className="  bg-white border-brown rounded-b-sm px-3 max-sm:px-2">
            <div className=" flex pt-4">
              <GeniustextInput
                title={"Expense Name"}
                width={width < 764 ? 280 : 400}
                type={"text"}
                autofocus={true}
                value={expenseName}
                onChange={(t) => setExpenseName(toProperCase(t.target.value))}
                refrance={expenseRef}
              />
            </div>

            <div
              style={{
                width: width < 764 ? 280 : 400,
              }}
              className=" ll:h-[400px] h-[300px] mt-1 flex"
            >
              <GeniusDatagrid
                rowID={(t) => t.CODE}
                onRowClick={(params) => {
                  setEditCode(params.row.CODE);
                  setExpenseName(params.row.EXPENSE_NAME);
                }}
                columns={columns}
                list={list}
              />
            </div>

            <div className="flex w-full justify-end items-center py-4">
              {editCode ? <ModifyButton onClick={handleModify} /> : null}
              {!editCode ? <SaveButton onClick={SaveExpense} /> : null}
              <UndoButton onClick={undo} />
              <ExitButton
                onClick={() => {
                  navigate("/masters");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpenseMaster;
