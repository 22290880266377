import axios from "axios";

const api = axios.create({
  baseURL: "https://retailxapp.com/", // Replace with your backend URL
  withCredentials: true, // Allow sending cookies with requests
  headers: {
    "Content-Type": "application/json",
  },
});

// Add the response interceptor
api.interceptors.response.use(
  (response) => response, // Pass successful responses unchanged
  async (error) => {
    console.log(error);
    if (error.response?.status === 403 && !error.config._retry) {
      error.config._retry = true; // Mark the request as retried
      try {
        const response = await api.post("/refresh-token"); // Get a new access token
        const { accessToken } = response.data;
        localStorage.setItem("accessToken", accessToken); // Store new token
        error.config.headers["Authorization"] = `Bearer ${accessToken}`; // Update headers
        return api(error.config); // Retry the original request
      } catch (refreshError) {
        console.error("Refresh failed", refreshError);
        return Promise.reject(refreshError); // Handle refresh failure
      }
    }
    return Promise.reject(error); // Handle other errors
  }
);

export default api;
