// sagas.js

import { all, call, put, takeLatest } from "redux-saga/effects";
import { getItemData } from "./api";
import { apiCallSuccess, apiCallFailure } from "./action";
import { API_CALL_REQUEST } from "./actionType";

function* fetchData(action) {
  try {
    const data = yield call(getItemData, action.payload); // Call your API function with payload

    yield put(apiCallSuccess(data)); // Dispatch success action with received data
  } catch (error) {
    yield put(apiCallFailure(error)); // Dispatch failure action with error
  }
}

// Watcher Saga: Watches for API_CALL_REQUEST action
function* watchFetchData() {
  yield takeLatest(API_CALL_REQUEST, fetchData);
}

// Export the root saga
export default function* rootSaga() {
  yield all([
    watchFetchData(),
    // Add more watcher sagas if needed
  ]);
}
